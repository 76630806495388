import React from "react";
import { Grid, Box } from "@mui/material";
import Screen7 from "../../assets/images/mobile-screenshorts/Screen7.png";
import Screen5 from "../../assets/images/mobile-screenshorts/Screen5.png";
import Screen12 from "../../assets/images/mobile-screenshorts/Screen12.png";

import mobileFrame from "../../assets/images/mobile-screenshorts/mobileFrame.png";

const FramedScreen = ({ positionStyles, imageSrc, imageOpacity = 1 }) => (
  <Box
    sx={{
      position: "absolute",
      ...positionStyles,
      width: "200px",
      opacity: imageOpacity,
      zIndex: 1,
    }}
  >
    <Box
      component="img"
      src={imageSrc}
      alt="Mobile Screen"
      sx={{
        width: "100%",
        borderRadius: 10,
      }}
    />
    <Box
      component="img"
      src={mobileFrame}
      alt="Mobile Frame"
      sx={{
        position: "absolute",
        top: -5,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "103%",
        pointerEvents: "none",
        objectFit: "cover",
      }}
    />
  </Box>
);

const MobileImages = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ position: "relative", overflow: "hidden" }}
    >
      <FramedScreen
        positionStyles={{
          left: "25%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        imageSrc={Screen5}
        imageOpacity={0.8}
      />
      <Box
        sx={{
          position: "relative",
          width: "350px",
          height: "auto",
          margin: "auto",
          zIndex: "1000",
        }}
      >
        <Box
          component="img"
          src={Screen7}
          alt="Mobile Screen"
          sx={{
            width: "100%",
            borderRadius: 18,
          }}
        />
        <Box
          component="img"
          src={mobileFrame}
          alt="Mobile Frame"
          sx={{
            position: "absolute",
            top: -5,
            bottom: 0,
            left: 0,
            width: "100%",
            height: "102%",
            pointerEvents: "none",
            objectFit: "cover",
          }}
        />
      </Box>
      <FramedScreen
        positionStyles={{
          right: "25%",
          top: "50%",
          transform: "translate(50%, -50%)",
        }}
        imageSrc={Screen12} 
        imageOpacity={0.8}
      />
    </Grid>
  );
};

export default MobileImages;
