/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { CircularProgress } from "@mui/material";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpg";
import { LoginDetail } from "LoginDetail";
import axios from "axios";
import { addUser } from "store/UserData";
import { useDispatch, useSelector } from "react-redux";
import styledCompo from "../style.module.css";
import { createApiAction } from "store/ApiServices";
import apiData from "store/apislice"

import { InputAdornment, IconButton, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axiosInstance from "axiosInstance";
import { setAdmin } from "store/AdminSlice";

function Basic() {
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const [error, setError] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { loading, success } = useSelector((state) => state.apiData)
  const [buttonLoading, setButtonLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);

  const handleClickShowPassword = () => {
    const cursorPosition = inputRef.current.selectionStart;
    setShowPassword(!showPassword);

    // Wait for the state to be updated
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  const navigate = useNavigate()
  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.id]: e.target.value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true)
    let formValid = true;
    setError("");

    if (!user.email) {
      setError("Email Required");
      formValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)) {
      setError("Invalid email address");
      formValid = false;
    } else if (!user.password) {
      setError("Password Required");
      formValid = false;
    }

    if (formValid) {
      try {
        const response = await axiosInstance.post("/api/dev/admin/auth/adminLogin", user);

        if (response.status === 200) {
          const {token,refreshToken}= response?.data?.data 
          localStorage.setItem("token",token)
          localStorage.setItem("refresh_token",refreshToken)
          navigate("/dashboard");
          const adminId = response?.data?.data?.result?.user_id;
         
          const adminResponse = await axiosInstance.post("/api/dev/admin/auth/getAdminDetails", {
            admin_id: adminId
          });
          await dispatch(setAdmin(adminResponse.data.data));
          
          
        } else {
          setError("Failed to login.");
        }
      } catch (error) {
        console.error("API call failed:", error);
        setError(error?.response?.data?.message || "Server Error");
      }
    }
    setButtonLoading(false);
    // setUser({ email: "", password: "" })
  };


  return (
    <div className={styledCompo.bg_style}>
      <BasicLayout image={""}>
        <Card >
          <MDBox
            variant="contained"
            bgColor="#0E4C75"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}

            textAlign="center"

          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              BEATZ
            </MDTypography>   
             <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
              Admin Panel
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <div>
              {error &&
                <div className="alert alert-danger" role="alert" style={{ fontSize: '0.6em', padding: '0.5em', borderRadius: '0.25em', textAlign: "center" }}>
                  {error}
                </div>
              }
            </div>
            <MDBox component="form" role="form" >
              <MDBox mb={2}>
                <MDInput
                  onChange={handleChange}
                  type="email"
                  label="Email"
                  id="email"
                  value={user.email}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  id="password"
                  value={user.password}
                  fullWidth
                  inputRef={inputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>

              <MDBox mt={4} mb={1} >
                <MDButton
                  variant="contained"
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    color: 'white',
                    '&:hover': {
                      backgroundImage: `linear-gradient(90deg, #4d5cc9 0%, #d156a8 100%);
                    `,
                    },

                  }}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {buttonLoading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                <CircularProgress sx={{color:"#ffffff"}} size={24} />
              </MDBox>
              ) : (
                <MDTypography
                  style={{ fontSize: "13.5px", fontWeight: "bold" }}
                  color="white"
                >
                   Sign in
                </MDTypography>
              )}
                  {/* {buttonLoading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                <CircularProgress sx={{color:"#ffffff"}} size={24} />
              </MDBox>
              ) : (
                <MDTypography variant="h6" color="white"  >
                   Sign in
                </MDTypography>
              )} */}

                </MDButton>

              </MDBox>
              {/* <MDBox mt={1.5} textAlign="center">
               
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in/forget-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient

                >
                  Forget Password?
                
                </MDTypography>
              </MDBox> */}
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    </div>

  );
}

export default Basic;
