import { useState, useEffect, useMemo } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

//bootstrap
import "bootstrap/dist/css/bootstrap.css";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
// import routes from "routes";
import routes from "routes/routes";
// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import ProtectedRoute from "routes/ProtectedRoute";
// import CPprofile from "layouts/cpprofile/CPprofile";
import Profile from "layouts/profile";
import SignUp from "layouts/authentication/forgetPassword";
import Cpprofile from "layouts/CPprofile/index";
import SignIn from "layouts/authentication/sign-in";
import "./App.css";
import CategoryProfile from "layouts/categoryProfile";
import ChangePassword from "layouts/authentication/changePassword";
import AdminProfile from "layouts/adminProfile";
import ArtistSongs from "layouts/artistSongs/ArtistSongs";
import CpArtists from "layouts/cpArtists/CpArtists";
import { useSelector } from "react-redux";
import { baseUrl } from "base";
import MDAvatar from "components/MDAvatar";
import { Tooltip } from "@mui/material";
import profileDefault from "assets/images/Profile-default.png";
import ErrorPage from "layouts/errorPage";
import Dashboard from "layouts/dashboard";
import ShareComp from "layouts/Share";
import PrivacyPage from "layouts/PrivacyPage";
import MobileAppDownload from "layouts/MobileAppDownload";


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { AdminData } = useSelector((state) => state.adminData);
  const navigate = useNavigate();
  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route element={<ProtectedRoute />}>
            <Route
              exact
              path={route.route}
              element={route.component}
              key={route.key}
            />
          </Route>
        );
      }

      return null;
    });
  // setting circle
  const configsButton = (
    <></>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="BEATZ MUSIC"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />

            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            path="/authentication/sign-in"
            element={<SignIn />}
            key="signIn"
          />
          <Route
            path="/authentication/forgetPassword"
            element={<SignUp />}
            key="forgetPassword"
          />
          <Route
            path="/authentication/ChangePassword"
            element={<ChangePassword />}
            key="ChangePassword"
          />
        <Route path="/BeatzMusicGhana/PrivacyPolicy" element={<PrivacyPage/>}  key="PrivacyPage"/>
        <Route element={<ProtectedRoute />}>
            {getRoutes(routes)}
            <Route path="/CP/:id" element={<Cpprofile />} key="Cpprofile" />
            <Route path="/profile/:id" element={<Profile />} key="profile" />
            <Route path="/admin" element={<AdminProfile />} key="admin" />
            <Route
              path="/artistSongs"
              element={<ArtistSongs />}
              key="artistSongs"
            />
            <Route
              path="/category/:id"
              element={<CategoryProfile />}
              key="category"
            />
            <Route path="*" element={<Navigate to="/dashboard" />} />
           
          </Route>
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="BEATZ MUSIC"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />

          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}

      <Routes>
        <Route
          path="/authentication/sign-in"
          element={<SignIn />}
          key="signIn"
        />
        <Route
          path="/authentication/forgetPassword"
          element={<SignUp />}
          key="forgetPassword"
        />
        <Route
          path="/authentication/ChangePassword"
          element={<ChangePassword />}
          key="ChangePassword"
        />
        <Route path="/share" element={<ShareComp />} key="share" />
        <Route path="/BeatzMusicGhana/PrivacyPolicy" element={<PrivacyPage/>}  key="PrivacyPage"/>
        <Route path='/MobileAppDownload' element={<MobileAppDownload/>} key ="LandingPage"/>
        {getRoutes(routes)}
        <Route element={<ProtectedRoute />}  key="protected">
          <Route path="/admin" element={<AdminProfile />} key="admin" />
          <Route path="/CP/:id" element={<Cpprofile />} key="Cpprofile" />
          <Route path="/profile/:id" element={<Profile />} key="profile" />

          <Route
            path="/category/:id"
            element={<CategoryProfile />}
            key="category"
          />
          <Route
            path="/artistSongs"
            element={<ArtistSongs />}
            key="artistSongs"
          />
          <Route
            path="/cpArtists/:id"
            element={<CpArtists />}
            key="cpArtists"
          />
           <Route
            path="/"
            element={<Dashboard/>}
            key=""
          />
        </Route>

          <Route path="*" element={<ErrorPage/>} key="error"/>
      </Routes>
    </ThemeProvider>
  );
}
