import { createSlice } from "@reduxjs/toolkit";

const initialState={
    success:false,
    loading:false,
    data:[],
    error:false,
    errordata:[],
}

const apiSlice=createSlice({    
    name:"apiSlice",
    initialState,
    reducers:{
        fetchStart:(state)=>{
            state.loading=true;
            state.error=false;
            state.success=false;
            state.errordata=[];
        },
        fetchSuccess:(state,action)=>{
            state.loading=false;
            state.success=true;
            state.error=false;
            state.data=action.payload; 
            state.errordata=[];
        },
        fetchError:(state,action)=>{
            state.error=true;
            state.loading=false;
            state.errordata=action.payload;
        }
    },

})
export const{fetchStart,fetchSuccess,fetchError}=apiSlice.actions;
export default apiSlice.reducer;