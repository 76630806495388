import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    SongData: [],
}

const songSlice = createSlice({
    name: "songSlice",
    initialState,
    reducers: {
        setSong: (state, action) => {
            state.SongData = action.payload;
        },

    },

})
export const { setSong, setImageShow } = songSlice.actions;
export default songSlice.reducer;