/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { createApiAction } from "store/ApiServices";
// Data
// Dashboard components
import { useDispatch } from "react-redux";
import { useEffect, useCallback, useState } from "react";

// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";
import { CircularProgress } from "@mui/material";
import profileDefault from "assets/images/Profile-default.png";

//Tables
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import CardContent from "@mui/material/CardContent";
//grid
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CardMedia,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { setSong } from "store/SongSlice";
import noCoverImage from "assets/images/noCoverImage.png"

function Dashboard() {

  const [bannerData, setBannerData] = useState([]);
  const [rowsCp, setRowsCp] = useState([]);
  const [playlistData, setPlaylistData] = useState([]);
  const [songData, setSongData] = useState([]);
  const [rowsArtist, setRowsArtist] = useState([]);
  const [rowsFeedback, setRowsFeedback] = useState([]);
  const [BarChartData, setBarChartData] = useState({});
  const [CardData, setCardData] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBannerApiCall = async () => {
    try {
      const Bdata = await axiosInstance.get("/api/dev/banner/getBanners");
      setBannerData(Bdata.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleNavigate = (user_id) => {
    navigate(`/CP/${user_id}`);
  };
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={baseUrl + image}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));
  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return `${title.substring(0, maxLength)}..`;
    }
    return title;
  };

  const columnsCp = [
    { Header: "Image", accessor: "Image", align: "center" },
    {
      Header: "Content Providers",
      accessor: "ContentProviders",
      align: "center",
    },
    { Header: "Email", accessor: "Email", align: "center" },
    { Header: "Action", accessor: "Action", align: "left" },
  ];

  const handlePlaylistCall = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/dev/playlist/getSelectedPlaylist"
      );
      setPlaylistData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSongsCall = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/dev/songs/topSongs?limit=10&page=1"
      );
      setSongData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePopularCP = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/dev/admin/dashboard/getPopularCPs"
      );
      const filteredData = response.data.data.filter(
        (item) => item?.user_detail
      );

      const names = filteredData.map((item) => item.user_detail.name);
      const cpId = filteredData.map((item) => item.user_detail.user_id);
      const listenCounts = filteredData.map((item) =>
        parseInt(item.total_listen_count, 10)
      );

      const newBarChartData = {
        labels: names,
        datasets: {
          label: "Listen Counts",
          data: listenCounts,
          id:cpId,
        },
      };

      setBarChartData(newBarChartData);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCardData = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/dev/admin/dashboard/getCardDetails"
      );
      // setCardData(response.data.data)

      setCardData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const apiCalls = [
      handleBannerApiCall(),
      handlePopularCP(),
      handlePlaylistCall(),
      handleSongsCall(),
      handleCardData(),
    ];
    Promise.all(apiCalls)
      .then(() => {
        setInitialLoading(false);
      })
      .catch((error) => {
        console.error("Error occurred in one of the API calls:", error);
        setInitialLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!initialLoading ? (
        <MDBox>
          <MDBox mt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="group"
                    title="CONTENT PROVIDERS"
                    count={CardData?.cpDetail?.totalCp}
                    percentage={{
                      color:
                        CardData?.cpDetail?.joinLastMonth >= 0
                          ? "success"
                          : "error",
                      amount: CardData?.cpDetail?.joinLastMonth,
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="music_note"
                    title="TOTAL SONGS"
                    count={CardData?.songsDetail?.totalSongs}
                    percentage={{
                      color:
                        CardData?.songsDetail?.addedSongslastMonth >= 0
                          ? "success"
                          : "error",
                      amount: CardData?.songsDetail?.addedSongslastMonth,
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="how_to_reg"
                    title="SUBSCRIBERS"
                    count={CardData?.allSubscribedUsersDetail?.totalSubscriber}
                    percentage={{
                      color:
                        CardData?.allSubscribedUsersDetail
                          ?.lastMonthSubscription >= 0
                          ? "success"
                          : "error",
                      amount:
                        CardData?.allSubscribedUsersDetail
                          ?.lastMonthSubscription,
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="supervised_user_circle"
                    title="TOTAL USERS"
                    count={CardData?.allUserDetail?.totalUsers}
                    percentage={{
                      color:
                        CardData?.allUserDetail?.joinLastMonthUsers >= 0
                          ? "success"
                          : "error",
                      amount: CardData?.allUserDetail?.joinLastMonthUsers,
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox py={3}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={5} lg={5} mb={3} sx={{ height: "380px" }}>
                {bannerData.data?.length > 0 && (
                  <MDBox
                    sx={{
                      position: "relative",
                      height: "100%",
                      borderRadius: "16px",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        position: "absolute",
                        top: 16,
                        left: 16,
                        color: "black",
                        zIndex: 2,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        padding: "8px 16px",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        navigate("/banners");
                      }}
                    >
                      Active Banners
                    </Typography>
                    <Swiper
                      spaceBetween={30}
                      centeredSlides={true}
                      autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                      }}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      style={{ height: "100%" }}
                      className="mySwiper"
                    >
                      {bannerData?.data.map((slide, index) => (
                        <SwiperSlide key={slide.banner_id} virtualIndex={index}>
                          <img
                            src={slide.image_url?baseUrl+slide.image_url:noCoverImage}
                            alt={`Slide ${index + 1}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            onClick={() => {
                              navigate("/banners");
                            }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={12} md={7} lg={7} mb={2}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="Popular Content Providers"
                    // description="................"
                    // date="just updated"
                    chart={BarChartData}
                  />
                </MDBox>
              </Grid>
            </Grid>

            <MDBox>
              <Grid
                container
                spacing={3}
                mb={3}
                sx={{ paddingRight: "0px", paddingLeft: "28px" }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  pb={1}
                  sx={{ boxShadow: 3, overflow: "hidden", borderRadius: 2 }}
                >
                  <MDBox display="flex" justifyContent="space-between">
                    <MDTypography
                      variant="h5"
                      color="dark"
                    >
                      Top Songs
                    </MDTypography>
                    <MDTypography
                      variant="h5"
                      sx={{fontSize:"15px", cursor:"pointer"}}
                      color="dark"
                      mr={5}
                      onClick={() => {
                        navigate(`/songs`);
                      }}
                    >
                      View All
                    </MDTypography>
                  </MDBox>
                  
                  <Grid item mr={3}>
                    <ImageList cols={10}>
                      {songData?.map((song) => (
                        <ImageListItem key={song.song_id}>
                          <ImageListItem
                            key={song.img}
                            sx={{ flexDirection: "column" }}
                          >
                            <img
                              src={song.cover_image_url ?baseUrl + song.cover_image_url:profileDefault}
                              alt={song.title}
                              loading="lazy"
                              style={{
                                borderRadius: "10px",
                                width: "140px",
                                height: "140px",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                dispatch(setSong(song));
                              }}
                            />

                            <MDBox
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "140px",
                                justifyContent: "space-between",
                              }}
                            >
                              <ImageListItemBar
                                title={truncateTitle(song.song_title, 10)}
                                subtitle={truncateTitle(song.artist_name, 12)}
                                position="below"
                                sx={{ flexDirection: "column" }}
                              />
                              <IconButton
                                sx={{
                                  margin: "unset",
                                  height: 30,
                                  width: 30,
                                  borderRadius: "50%",
                                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                                  },
                                }}
                                aria-label="play/pause"
                                onClick={() => {
                                  dispatch(setSong(song));
                                }}
                              >
                                <PlayArrowIcon sx={{ height: 28, width: 28 }} />
                              </IconButton>
                            </MDBox>
                          </ImageListItem>
                        </ImageListItem>
                      ))}
                    </ImageList>
                    <MDBox display="flex" justifyContent="space-between">
                      <MDTypography variant="h5"  color="dark">
                        Top Playlists
                      </MDTypography>
                      <MDTypography
                      variant="h5"
                      sx={{fontSize:"15px", cursor:"pointer"}}
                      color="dark"
                      mr={2}
                      onClick={() => {
                        navigate(`/category`);
                      }}
                    >
                      View All
                    </MDTypography>
                    </MDBox>
                    <ImageList cols={10} gap={5}>
                      {playlistData
                        ?.filter((item) => item.status === true)
                        .slice(0, 10)
                        .map((item) => (
                          <ImageListItem
                            key={item.playlist_id}
                            sx={{ flexDirection: "column" }}
                          >
                            <img
                              src={item.playlist_image?baseUrl + item.playlist_image:profileDefault}
                              alt={item.title}
                              loading="lazy"
                              style={{
                                borderRadius: "10px",
                                width: "140px",
                                height: "140px",
                                objectFit: "cover",
                                cursor:"pointer"
                              }}
                              onClick={() => {
                                navigate(`/category/${item.playlist_id}`);
                              }}
                            />
                            <ImageListItemBar
                              title={truncateTitle(item.title, 16)}
                              subtitle={truncateTitle(item.playlist_type, 12)}
                              position="below"
                              sx={{ flexDirection: "column" }}
                            />
                          </ImageListItem>
                        ))}
                    </ImageList>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
        >
          <CircularProgress sx={{ color: "purple" }} size={54} />
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
