import {  createSlice } from "@reduxjs/toolkit";
import { getData } from "./cpThunk";

const cpSlice=createSlice({
    name:"cpSlice",
    initialState:{Cp:[],
    loading: false,
    isSuccess: false,
    message: "",
},

    extraReducers:(builder)=> {
        builder
        .addCase(getData.pending,(state)=>{
            state.loading=true;
        })
        .addCase(getData.fulfilled,(state,action)=>{
            state.loading = false;
            state.Cp = action.payload.data;
            state.isSuccess = true;
          
        })
        .addCase(getData.rejected,(state,action)=>{
            state.loading=false;
            state.isSuccess=false;
            state.message= action.error.message
        })

    }
})


export default cpSlice.reducer;






