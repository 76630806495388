// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router";
import axiosInstance from "axiosInstance";
import { baseUrl } from "base";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Swal from "sweetalert2";
// Data

import apiData from "store/apislice";
import { useDispatch, useSelector } from "react-redux";
import { createApiAction } from "store/ApiServices";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { countries } from "assets/countries/Countries";
import { Autocomplete } from "@mui/material";
import { getData } from "layouts/CPprofile/cpThunk";
import { IconButton } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import { CircularProgress } from "@mui/material";
import profileDefault from "assets/images/Profile-default.png";
import { capitalizeFirstLetter } from "examples/CommonFunctions";
function Tables() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initailLoadin, setInitialLoading] = useState(true);
  const artistData = useSelector((state) =>
    state.apiData.data.data.allArtist
      ? state.apiData.data.data.allArtist
      : state.apiData.data.data.artistSearchResult
  );
  const totalCount = useSelector((state) =>
    state.apiData.data.data.totalCount
      ? state.apiData.data.data.totalCount
      : state.apiData.data.data.totalArtistSearch
  );
  const {error}=useSelector((state)=>state.apiData)
  const timeoutRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cpData = useSelector((state) => state.cpData.Cp);
  const handleNavigate = (artist_id) => {
    navigate(`/profile/${artist_id}`);
  };

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image? baseUrl + image : profileDefault}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchText(value);
    }, 300);
  };

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    image: "",
    dob: "",
    gender: "",
    image: "",
    country: "",
    cp_name: "",
    cp_id: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormErrors({});
    setImagePreview(null);
    setFormData({
      name: "",
      email: "",
      mobile: "",
      password: "",
      image: "",
      dob: "",
      gender: "",
      image: "",
      country: "",
      cp_name: "",
      cp_id: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";
    if (name === "mobile") {
      if (!/^[1-9]\d{9}$/.test(value)) {
        error = "Contact number must be exactly 10 digits";
      }
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Enter valid email address.";
      }
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCountryChange = (event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : "",
    });
  };

  const handleCPChange = (event, newValue) => {
    setFormData({
      ...formData,
      cp_name: newValue ? newValue.name : "",
      cp_id: newValue ? newValue.user_id : "",
    });
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
 if(file){
  setFormData({ ...formData, image: file });
  const previewURL = URL.createObjectURL(file);
  setImagePreview(previewURL);
 }
   
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    const newFormData = new FormData();
    newFormData.append("name", formData.name);
    newFormData.append("email", formData.email);
    newFormData.append("mobile", formData.mobile);
    newFormData.append("password", formData.password);
    newFormData.append("dob", formData.dob);
    newFormData.append("gender", formData.gender);
    newFormData.append("country", formData.country);
    newFormData.append("cp_id", formData.cp_id);
    newFormData.append("image", formData.image);

    try {
      const response = await axiosInstance.post(
        "/api/dev/artist/addArtist",
        newFormData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Artist Added",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch(
          createApiAction(
            `/api/dev/artist/getAllArtist?limit=${itemsPerPage}&page=${page}`,
            "GET"
          )
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
        html: `<span style="color: red;">${e?.response?.data?.message}</span>`,

      });
    }
    setButtonLoading(false);

    handleClose();
  };

  const handleRows = () => {
    const rowsAPI = artistData?.map((artist) => ({
      Image: (
        <MDBox display="flex" py={1}>
          {avatars([[artist.image_url, artist.name]])}
        </MDBox>
      ),
      Artist: capitalizeFirstLetter(artist.name),
      Email: (
       
          artist?.email?artist.email:"NA"
      
      ),
      Monthly_Lisetner: (
         artist.monthly_listners ? artist.monthly_listners : "0"
      ),
      Songs:(
        artist.song_count ? artist.song_count : "0"
      ),
      Action: (
        <MDBox >
          <MDButton
            onClick={() => {
              handleNavigate(artist.artist_id);
            }}
            sx={{
              backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
            }}
            size="small"
          >
            <MDTypography
              style={{ fontSize: "13.5px", fontWeight: "bold" }}
              color="white"
            >
              Visit Profile
            </MDTypography>
          </MDButton>
        </MDBox>
      ),
    }));

    setRows(rowsAPI);
    setLoading(true);
  };
  const handleApiCall = async () => {
    if (searchText.trim() !== "") {
      try {
        const SearchData = {
          search_keyword: searchText,
        };
        await dispatch(
          createApiAction(
            `/api/dev/artist/artistSearch?limit=${itemsPerPage}&page=${searchPage}`,
            "POST",
            SearchData
          )
        ).then(() => {
          setLoading(false);
          setInitialLoading(false);
        });
      } catch (error) { 
        setInitialLoading(false)
        console.log("Error fetching data:", error);
      }
    } else if (searchText.trim() === "") {
      try {
        dispatch(
          createApiAction(
            `/api/dev/artist/getAllArtist?limit=${itemsPerPage}&page=${page}`,
            "GET"
          )
        ).then(() => {
          setLoading(false);
          setInitialLoading(false);
          setSearchPage(1);
        });
      } catch (error) {
        console.log("Error fetching data:", error); 
        setInitialLoading(false)
      }
    }
  };

  const columns = [
    { Header: "Image", accessor: "Image", width: "10%", align: "center", disableSortBy: true},
    { Header: "Name", accessor: "Artist", align: "center" },
    { Header: "Email", accessor: "Email", align: "center" },
    {
      Header: "Monthly Listeners",
      accessor: "Monthly_Lisetner",
      align: "center",
    },
    {
      Header: "Songs",
      accessor: "Songs",
      align: "center",
    },
    { Header: "Action", accessor: "Action", align: "center" , disableSortBy: true},
  ];

  useEffect(() => {
    handleApiCall();
    dispatch(getData());
  }, []);

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, artistData, page, searchPage]);

  useEffect(() => {
    handleApiCall();
  }, [searchText, page, searchPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {!initailLoadin ? (
                <MDBox>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2, // Gap between items
                    }}
                  >
                    <MDBox
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: { xs: "center", sm: "flex-start" }, // Center on small screens
                        mb: { xs: 2, sm: 0 }, // Margin bottom on small screens
                      }}
                    >
                      <MDTypography variant="h5" color="white">
                        ARTISTS ({totalCount ? totalCount : "0"})
                      </MDTypography>
                    </MDBox>

                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }} // Stack vertically on small screens
                      alignItems="center"
                      sx={{
                        gap: { xs: 2, sm: 0 }, // Gap between elements on small screens
                        justifyContent: { xs: "center", sm: "flex-end" }, // Center on small screens
                        flex: 1,
                      }}
                    >
                      <TextField
                        onChange={handleSearchChange}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Artist"
                        sx={{
                          "& .MuiInputBase-input": {
                            color: "#ffffff",
                            width: { xs: "100%", sm: "240px"  }, // Full width on small screens
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <SearchOutlined color="white" />
                            </IconButton>
                          ),
                        }}
                      />
                      <MDButton
                        variant="contained"
                        size="small"
                        sx={{
                          color: "black",
                          marginLeft: { xs: 0, md: "12px" },
                          marginRight: { xs: 0, md: "12px" },
                          color: "black",
                          paddingBottom: "13px",
                          paddingTop: "13px",
                          width: { xs: "100%", md: "auto" },
                        }}
                        fullWidth
                        onClick={handleOpen}
                      >
                        Add Artist
                      </MDButton>
                    </MDBox>
                  </MDBox>

                  {/* add dialog  */}
                  <Dialog open={open} onClose={handleClose}  maxWidth="sm"
                fullWidth>
                    <DialogTitle style={{ position: "relative" }}>
                      Add Artist
                      <span
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <CancelIcon
                          sx={{ color: purple[500] }}
                          onClick={handleClose}
                        />
                      </span>
                      <MDBox display="flex" justifyContent="center">
                        {imagePreview && (
                          <MDAvatar
                            src={imagePreview}
                            alt="profile-image"
                            sx={{ height: "140px", width: "140px" }}
                            shadow="sm"
                          />
                        )}
                      </MDBox>
                    </DialogTitle>
                    <DialogContent style={{ overflow: "auto" }}>
                      {/* Form inputs */}
                      <form onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          name="name"
                          label="Name "
                          type="text"
                          required
                          fullWidth
                          value={formData.name}
                          onChange={handleChange}
                          inputProps={{ maxLength: 25 }}
                          helperText={
                            formData.name.length === 25
                              ? "25 character limit reached"
                              : ""
                          }
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />
                        <TextField
                          required
                          margin="dense"
                          id="mobile"
                          name="mobile"
                          label="Contact Number"
                          type="text"
                          fullWidth
                          value={formData.mobile}
                          onChange={handleChange}
                          inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                          error={!!formErrors.mobile}
                          helperText={formErrors.mobile}
                        />
                        <TextField
                          required
                          margin="dense"
                          id="email"
                          name="email"
                          label="Email "
                          type="email"
                          fullWidth
                          value={formData.email}
                          onChange={handleChange}
                          error={Boolean(formErrors.email)}
                          helperText={formErrors.email}
                        />

                        <TextField
                          required
                          margin="dense"
                          id="dob"
                          name="dob"
                          // label="Date of Birth"
                          type="date"
                          fullWidth
                          value={formData.dob}
                          onChange={handleChange}
                        />
                        <FormControl
                          fullWidth
                          sx={{ marginTop: "7px", marginBottom: "4px" }}
                        >
                          <InputLabel id="gender1">Gender</InputLabel>
                          <Select
                            labelId="gender1"
                            id="gender"
                            value={formData.gender}
                            label="Gender"
                            name="gender"
                            required
                            onChange={handleChange}
                            sx={{ height: "40px" }}
                          >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </Select>
                        </FormControl>

                        <Autocomplete
                          sx={{ marginTop: "6.5px", marginBottom: "2px" }}
                          id="country-select-demo"
                          options={countries}
                          fullWidth
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          onChange={handleCountryChange}
                          renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                              <MDBox
                                key={key}
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...optionProps}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  alt=""
                                />
                                {option.label} ({option.code}) +{option.phone}
                              </MDBox>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select a Country"
                              required
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />

                        <Autocomplete
                          sx={{ marginTop: "9.5px", marginBottom: "9.5px" }}
                          id="cp_id"
                          options={cpData}
                          fullWidth
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          onChange={handleCPChange}
                          renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                              <MDBox
                                key={key}
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...optionProps}
                              >
                                {option.name}
                              </MDBox>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select CP"
                              required
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                        <label
                          htmlFor="profileImage"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            border: "1px solid #ccc",
                            padding: "2px",
                            marginTop: "11px",

                            borderRadius: "5px",
                          }}
                        >
                          <input
                            type="file"
                            required={!formData.image}
                            id="profileImage"
                            accept="image/*"
                            onChange={handleFileInputChange}
                            style={{
                              position: "absolute",
                              width: "1px",
                              height: "1px",
                              opacity: 0,
                            }}
                          />
                          <Button component="span" color="primary" fullWidth>
                            Add Image
                          </Button>
                          <span
                            style={{
                              textAlign: "center",
                              width: "100%",
                              marginTop: "1px",
                            }}
                          >
                            {formData.image?.name}
                          </span>
                        </label>
                        <Button
                          type="submit"
                          sx={{
                            backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,

                            marginTop: "11px",
                          }}
                          fullWidth
                          disabled={Object.values(formErrors).some(
                            (error) => error !== ""
                          )}
                        >
                          {buttonLoading ? (
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                            >
                              <CircularProgress
                                sx={{ color: "#ffffff" }}
                                size={24}
                              />
                            </MDBox>
                          ) : (
                            <MDTypography
                              style={{ fontSize: "13.5px", fontWeight: "bold" }}
                              color="white"
                            >
                              Submit
                            </MDTypography>
                          )}
                        </Button>
                      </form>
                    </DialogContent>
                    {/* <DialogActions>
<MDButton
onClick={handleSubmit}
sx={{
backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
marginRight: "12px" 
}}
fullWidth
>
<MDTypography style={{ fontSize: '13.5px' , fontWeight: 'bold' }}  color="white">
Submit
</MDTypography>
</MDButton>
</DialogActions> */}
                  </Dialog>
                  {rows?.length > 0 ? (
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns, rows }}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                        isSorted={true}
                      />
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                        mb={3}
                      >
                        <Pagination
                          count={Math.ceil(totalCount / itemsPerPage)}
                          page={searchText === "" ? page : searchPage}
                          onChange={(event, value) => {
                            {
                              searchText === ""
                                ? setPage(value)
                                : setSearchPage(value);
                            }
                          }}
                          color="primary"
                          shape="rounded"
                          size="large"
                          sx={{
                            "& .MuiPaginationItem-root": {
                              borderRadius: "8px",
                              border: "1px solid #ddd",
                              margin: "0 4px",
                              "&.Mui-selected": {
                                backgroundColor: "#5870FD",
                                color: "white",
                                border: "1px solid #5870FD",
                              },
                              "&:hover": {
                                backgroundColor: "#bb48bd",
                              },
                            },
                            "& .MuiPaginationItem-previousNext": {
                              borderRadius: "8px",
                              border: "1px solid #ddd",
                            },
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  ) : (
                    <MDBox
                      sx={{
                        display: "flex",
                        padding: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <MDTypography variant="caption" sx={{ fontSize: "20px" }}>
                        {/* { !loading &&"There are currently no artists available." }  */}
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              ) : 
              !error?(
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100vw"
                  height="100vh"
                  position="fixed"
                  top={0}
                  left={0}
                >
                  <CircularProgress sx={{ color: "purple" }} size={54} />
                </MDBox>
              ):(
                <MDBox
                display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
              >
               <MDTypography>
                Error Occured
               </MDTypography>
              </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
