import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    AdminData: {},
    error:false
}

const adminSlice = createSlice({
    name: "adminSlice",
    initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.AdminData = action.payload;
            if(action.payload.success!=true){
                state.error=true
            }
        },

    },

})
export const { setAdmin } = adminSlice.actions;
export default adminSlice.reducer;