// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router";
import axiosInstance from "axiosInstance";
import { baseUrl } from "base";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Swal from "sweetalert2";
// Data

import apiData from "store/apislice";
import { useDispatch, useSelector } from "react-redux";
import { createApiAction } from "store/ApiServices";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { countries } from "assets/countries/Countries";
import { Autocomplete } from "@mui/material";
import { getData } from "layouts/CPprofile/cpThunk";
import { IconButton } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router";
import { setSearch } from "store/SearchSlice";
import { capitalizeFirstLetter } from "examples/CommonFunctions";

function CpArtists() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const { id } = useParams();
  const cpId = {
    cp_id: id,
  };
  const artistData = useSelector((state) =>
    state.apiData.data.data.artists
      ? state.apiData.data.data.artists
      : state.apiData.data.data.artistSearchResult
  );
  const { user_id: user, name: CpName } = useSelector(
    (state) => state.apiData.data.data
  );
  const totalCount = useSelector((state) =>
    state.apiData.data.data.totalCount
      ? state.apiData.data.data.totalCount
      : state.apiData.data.data.totalArtistSearch
  );
  const timeoutRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const itemsPerPage = 10;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cpData = useSelector((state) => state.cpData.Cp);
  const handleNavigate = (artist_id) => {
    navigate(`/profile/${artist_id}`);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase().trim();
    setSearchQuery(query);

    const filtered = artistData.filter((artist) =>
      artist.name.toLowerCase().includes(query)||
    artist.email.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredItems(filtered);
    setLoading(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    dispatch(setSearch(e.target.value));
  };

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={baseUrl + image}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    image: "",
    email: "",
    password: "",
    DOB: "",
    gender: "",
    country: "",
    cp_name: "",
    cp_id: "",
    CP: "",
  });

  const handleAddUserClick = () => {
    setIsAddUserDialogOpen(true);
    setFormData({
      name: "",
      mobile: "",
      image: "",
      email: "",
      password: "",
      DOB: "",
      gender: "",
      country: "",
      cp_name: "",
      cp_id: user,
      CP: user,
    });
  };

  console.log("artistdata", user);
  const handleCloseDialogs = () => {
    console.log(formData);
    setImagePreview(null);
    setFormErrors({});
    setIsAddUserDialogOpen(false);
    setFormData({
      name: "",
      email: "",
      mobile: "",
      image: "",
      password: "",
      DOB: "",
      gender: "",
      country: "",
      cp_name: "",
      cp_id: user,
      CP: user,
    });
  };

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;

    let error = "";

    if (id === "mobile") {
      if (!/^[1-9]\d{9}$/.test(value)) {
        error = "Contact number must be exactly 10 digits";
      }
      setFormErrors({
        ...formErrors,
        [id]: error,
      });
    } else if (id === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Invalid email address.";
      }
      setFormErrors({
        ...formErrors,
        [id]: error,
      });
    }

    if (name === "gender") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleCountryChange = (event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : "",
    });
  };

  const handleAutoComplete = (fieldName, lableName, valueName) => {
    return fieldName.find((item) => item[lableName] === valueName) || null;
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, image: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const closeDialog = () => {
    handleCloseDialogs();
  };

  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();
    newFormData.append("name", formData.name);
    newFormData.append("mobile", formData.mobile);
    newFormData.append("image", formData.image);
    newFormData.append("email", formData.email);
    newFormData.append("password", formData.password);
    newFormData.append("DOB", formData.DOB);
    newFormData.append("gender", formData.gender);
    newFormData.append("country", formData.country);
    newFormData.append("cp_id", formData.cp_id);

    try {
      await axiosInstance.post("/api/dev/artist/addArtist", newFormData);
      Swal.fire({
        icon: "success",
        title: "Artist Added",
        timer: 1500,
        showConfirmButton: false,
      });

      dispatch(createApiAction("/api/dev/admin/CP/getCpById", "POST", cpId)).then(() => {
        setLoading(false);
      });
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        icon: "error",
        html: `<span style="color: red;">${e?.response?.data?.message}</span>`,

      });
      console.log(e.message);
    }
    setButtonLoading(false);
    closeDialog();
  };

  const handleRows = () => {
    const Data = searchQuery.trim() !== "" ? filteredItems : artistData;
    const rowsAPI = Data?.map((artist) => ({
      Image: (
        <MDBox display="flex" py={1}>
          {avatars([[artist.image_url, artist.name]])}
        </MDBox>
      ),
      Artist: capitalizeFirstLetter(artist.name),
      Email: (
        artist.email
      ),
      Monthly_Lisetner: (
       artist.monthly_listners ? artist.monthly_listners : 0
      ),
      Action: (
        <MDBox width="8rem" >
          <MDButton
            onClick={() => {
              handleNavigate(artist.artist_id);
            }}
            sx={{
              backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
            }}
            size="small"
          >
            <MDTypography
              style={{ fontSize: "13.5px", fontWeight: "bold" }}
              color="white"
            >
              Visit Profile
            </MDTypography>
          </MDButton>
        </MDBox>
      ),
    }));
    setRows(rowsAPI)
   if( Data?.length>0)
    {  setLoading(true);}
   
  };

  const handleApiCall = async () => {
    try {
      await dispatch(
        createApiAction("/api/dev/admin/CP/getCpById", "POST", cpId)
      ).then(() => {
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const columns = [
    { Header: "Image", accessor: "Image", width: "10%", align: "center", disableSortBy: true },
    { Header: "Name", accessor: "Artist", align: "center" },
    { Header: "Email", accessor: "Email", align: "center" },
    {
      Header: "Monthly Listeners",
      accessor: "Monthly_Lisetner",
      align: "center",
    },
    { Header: "Action", accessor: "Action", align: "center" , disableSortBy: true},
  ];

  useEffect(() => {
    handleApiCall();
  }, []);

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, artistData, searchQuery]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  p: 3,
                }}
              >
                <MDBox
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-start" },
                    mb: { xs: 2, sm: 0 },
                  }}
                >
                  <MDTypography variant="h5" color="white" textTransform="capitalize">
                    {`${CpName}'s Artists(${rows?.length})`}
                  </MDTypography>
                </MDBox>

                <MDBox
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "center",
                    justifyContent: { xs: "center", sm: "flex-end" },
                    gap: { xs: 2, sm: 3 },
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <TextField
                    onChange={handleSearchChange}
                    id="standard-bare"
                    variant="outlined"
                    placeholder="Search Artist"
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "#ffffff",
                        width: { xs: "100%", sm: "240px" },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <SearchOutlined color="white" />
                        </IconButton>
                      ),
                    }}
                  />
                  <MDButton
                    variant="contained"
                    size="small"
                    sx={{ color: "black",
                      marginLeft: { xs: 0, md: "12px" },
                      marginRight: { xs: 0, md: "12px" },
                      color: "black",
                      paddingBottom: "13px",
                      paddingTop: "13px",
                      width: { xs: "100%", md: "auto" },}}
                    onClick={handleAddUserClick}
                  >
                    Add Artist
                  </MDButton>
                </MDBox>
              </MDBox>
              <Dialog open={isAddUserDialogOpen} onClose={handleCloseDialogs} maxWidth="sm"
                fullWidth>
                <DialogTitle>
                  Add Artist
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CancelIcon
                      sx={{ color: purple[500] }}
                      onClick={handleCloseDialogs}
                    />
                  </span>
                  {imagePreview && (
                    <MDBox display="flex" justifyContent="center">
                      <MDAvatar
                        src={imagePreview}
                        alt="profile-image"
                        sx={{ height: "140px", width: "140px" }}
                        shadow="sm"
                      />
                    </MDBox>
                  )}
                </DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                  <form onSubmit={handleAddFormSubmit}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Full Name"
                      type="text"
                      fullWidth
                      required
                      value={formData.name}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 25 }}
                      helperText={formData.name.length === 25 ? "25 character limit reached" : ""}
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />

                    <TextField
                      margin="dense"
                      id="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      required
                      value={formData.email}
                      onChange={handleInputChange}
                      error={Boolean(formErrors.email)}
                      helperText={formErrors.email}
                    />

                    <TextField
                      margin="dense"
                      id="mobile"
                      required
                      label="Mobile"
                      type="text"
                      fullWidth
                      value={formData.mobile}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                      error={!!formErrors.mobile}
                      helperText={formErrors.mobile}
                    />

                    <TextField
                      margin="dense"
                      id="DOB"
                      // label="Date of Birth"
                      type="date"
                      fullWidth
                      value={formData.DOB}
                      onChange={handleInputChange}
                    />
                    <FormControl
                      fullWidth
                      sx={{ marginTop: "7px", marginBottom: "4px" }}
                    >
                      <InputLabel id="gender1">Gender</InputLabel>
                      <Select
                        labelId="gender1"
                        id="gender"
                        value={formData.gender}
                        label="Gender"
                        name="gender"
                        required
                        onChange={handleInputChange}
                        sx={{ height: "40px" }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>

                    <Autocomplete
                      sx={{ marginTop: "6.5px", marginBottom: "2px" }}
                      id="country-select-demo"
                      options={countries}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      onChange={handleCountryChange}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a Country"
                          required
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />

                    <label
                      htmlFor="profileImage"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "11px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        required
                        id="profileImage"
                        accept="image/*"
                        onChange={handleFileInputChange}
                        style={{
                          position: "absolute",
                          width: "1px",
                          height: "1px",
                          opacity: 0,
                        }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Add Image
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "1px",
                        }}
                      >
                        {formData.image?.name}
                      </span>
                    </label>

                    {/* Submit button */}
                    {/* <Button type="submit" variant="contained" fullWidth sx={{color:"black",bgcolor:"white"}}>
            Submit
          </Button> */}
                    <Button
                      type="submit"
                      fullWidth
                      disabled={Object.values(formErrors).some(
                        (error) => error !== ""
                      )}
                      sx={{
                        backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                        marginTop: "11px",
                      }}
                      size="small"
                    >
                      {buttonLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={24}
                          />
                        </MDBox>
                      ) : (
                        <MDTypography
                          style={{ fontSize: "13.5px", fontWeight: "bold" }}
                          color="white"
                        >
                          Submit
                        </MDTypography>
                      )}
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>
              <MDBox pt={3}>
              {rows?.length>0?<DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  isPagination={true}
                />
                :(<MDBox sx={{display:'flex',padding:'20px',justifyContent:'center'}}>
                  <MDTypography variant="caption" sx={{fontSize:"20px"}}>
                  { "No Artist Found."}
               </MDTypography>
               </MDBox>)}
              </MDBox>
              
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CpArtists;
