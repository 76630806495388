/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { baseUrl } from "base";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { formatDate } from "examples/Date";
// Overview page components
import Header from "layouts/CPprofile/components/Header";
import PlatformSettings from "layouts/CPprofile/components/PlatformSettings";
// Data
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { createApiAction } from "store/ApiServices";
import axiosInstance from "axiosInstance";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Overview() {
  // const [user,setUserData]=useState([])
  const dispatch = useDispatch();
  const user = useSelector((state) => state.apiData.data.data);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const cpId = {
    cp_id: id,
  };

  const InfoRow = ({ label, value }) => {
    const isEmail = label === "Email";

    return (
      <MDBox>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform={isEmail ? "none" : "capitalize"}
          mr={1}
        >
          {label}:
        </MDTypography>
        {isEmail ? (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="info"
            component="a"
            href={`mailto:${value}`}
          >
            {value || "NA"}
          </MDTypography>
        ) : (
          <MDTypography variant="button" fontWeight="regular" color="text">
            {value || "NA"}
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const handleApiCall = async () => {
    try {
      await dispatch(
        createApiAction("/api/dev/admin/CP/getCpById", "POST", cpId)).then(() => setLoading(false));
     
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleApiCall();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {!loading && (
        <Header user={user}>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={4}>
                <InfoRow label="fullName" value={user?.name} />
                <InfoRow label="Email" value={user?.email} />
                <InfoRow label="mobile" value={user?.msisdn} />
                <MDBox>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                    mr={1}
                  >
                    DOB:
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {user?.DOB ? formatDate(user?.DOB) : "NA"}
                  </MDTypography>
                </MDBox>
                <InfoRow
                  textTransform="capitalize"
                  label="gender"
                  value={user?.gender}
                />
                <InfoRow label="country" value={user?.country} />
                <InfoRow
                  label="Joined On"
                  value={user?.createdAt ? formatDate(user.createdAt) : "NA"}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <PlatformSettings usesr={user} />
              </Grid>

              <Grid
                item
                xs={12}
                xl={4}
                sx={{ height: "400px", overflow: "auto" }}
              >
                <MDBox
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Artists
                  </MDTypography>
                  {user?.artists?.length > 0 && (
                    <MDTypography
                      variant="h6"
                      mr={13}
                      fontWeight="medium"
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate(`/cpArtists/${user.user_id}`)}
                    >
                      Manage Artists
                    </MDTypography>
                  )}
                </MDBox>
                {user?.artists?.length > 0 ? (
                  user.artists.map((artist) => (
                    <MDBox
                      key={artist.artist_id}
                      component="li"
                      display="flex"
                      alignItems="center"
                      py={1}
                      mb={1}
                    >
                      <MDBox mr={2}>
                        <MDAvatar
                          src={baseUrl + artist.image_url}
                          alt="Artist Image"
                          shadow="md"
                        />
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <MDTypography variant="button" fontWeight="medium">
                          {artist.name}
                        </MDTypography>
                        <MDTypography variant="caption" color="text">
                          {artist.email}
                        </MDTypography>
                      </MDBox>
                      <MDBox ml="auto" mr={12}>
                        <MDButton
                          variant="text"
                          color="info"
                          onClick={() =>
                            navigate(`/profile/${artist.artist_id}`)
                          }
                        >
                          Visit Profile
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  ))
                ) : (
                  <MDTypography variant="h6" color="text">
                    No Artists Yet
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      )}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
