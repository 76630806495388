// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router";
import axiosInstance from "axiosInstance";
import { baseUrl } from "base";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

// Data
import apiData from "store/apislice";
import { useDispatch, useSelector } from "react-redux";
import { createApiAction } from "store/ApiServices";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Cancel as CancelIcon, SearchOutlined } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { Autocomplete } from "@mui/material";
import { Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";
import InfoIcon from "@mui/icons-material/Info";
import { CircularProgress } from "@mui/material";
import profileDefault from "assets/images/Profile-default.png";
import { capitalizeFirstLetter } from "examples/CommonFunctions";

function SongCategory() {
  const [rows, setRows] = useState([]);
  const [artist, SetArtist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const itemsPerPage = 10;
  const timeoutRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [initalLoading, setInitialLoading] = useState(true);
  const categoryData = useSelector((state) => {
    const { allPlaylist, playlistSearchResult, categoryList } =
      state.apiData.data.data;
    return allPlaylist || playlistSearchResult || categoryList;
  });
  const { totalCount } = useSelector((state) => state.apiData.data.data);
 const {error}=useSelector((state)=>state.apiData)
  const [formData, setFormData] = useState({
    title: "",
    playlist_type: "",
    artist_name: "",
    artist_id: "",
    playlist_img: "",
  });

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();
    setInputValue(event.target.value);
    setFilter("");
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchText(value);
    }, 300);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setSearchText("");
    setInputValue("");
  };

  const handleNavigate = (artist_id) => {
    navigate(`/category/${artist_id}`);
  };
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image ? baseUrl + image : profileDefault}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const handleCategoryRemove = (categoryId) => {
    const CategoryId = {
      playlist_id: categoryId,
    };
    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this Category?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/playlist/deletePlaylist", CategoryId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Category Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(
              createApiAction(
                `/api/dev/playlist/getAllPlaylist?page=${page}&limit=${itemsPerPage}`,
                "GET"
              )
            ).then(() => setLoading(false));
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error Occurred!",
            });
          });
      }
    });
  };

  const handleStatus = async (songId) => {
    const PlaylistId = {
      playlist_id: songId,
    };

    try {
      await axiosInstance.post(
        "/api/dev/playlist/managePlaylistStatus",
        PlaylistId
      );
      dispatch(
        createApiAction(
          `/api/dev/playlist/getAllPlaylist?page=${page}&limit=${itemsPerPage}`,
          "GET"
        )
      ).then(() => setLoading(false));
    } catch (error) {
      console.error("Error changing banner status:", error);
      
    }
  };
  const handleRows = () => {
    if (categoryData?.length > 0) {
      const rowsAPI = categoryData
        .filter((playlist) => playlist.playlist_type !== "single")
        .map((playlist) => ({
          Image: (
            <MDBox display="flex" py={1}>
              {avatars([[playlist?.playlist_image, playlist?.title]])}
            </MDBox>
          ),
          Name: capitalizeFirstLetter(playlist.title),
          Type: (
           capitalizeFirstLetter(playlist.playlist_type)
           
          ),
          Profile: (
            <MDBox>
              <Tooltip title="More Info">
                <IconButton
                  onClick={() => handleNavigate(playlist.playlist_id)}
                  size="medium"
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
          ),
          Live: (
            <MDBox mt={0.5}>
              <Switch
                checked={playlist.status}
                onChange={() => {
                  handleStatus(playlist.playlist_id);
                }}
              />
            </MDBox>
          ),
          Delete: (
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => {
                handleCategoryRemove(playlist.playlist_id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          ),
          statusValue:playlist.status ? 1 : 0,

        }));

      setRows(rowsAPI);
      setLoading(true);
    } else {
      setRows([]);
      setLoading(true);
    }
  };

  const handleApiCall = async () => {
    if (searchText.trim() !== "") {
      try {
        const SearchData = {
          search_keyword: searchText,
        };
        await dispatch(
          createApiAction(
            `/api/dev/playlist/playlistSearch?page=${searchPage}&limit=${itemsPerPage}`,
            "POST",
            SearchData
          )
        ).then(() => {
          setLoading(false);
          setInitialLoading(false);
        });
      } catch (error) {
        console.log("Error fetching data:", error);
        setInitialLoading(false);
      }
    } else if (searchText.trim() === "") {
      if (filter !== "") {
        const CategoryType = {
          category_type: filter,
        };
        try {
          await dispatch(
            createApiAction(
              `/api/dev/playlist/getCategoryByType`,
              "POST",
              CategoryType
            )
          ).then(() => {
            setLoading(false);
            setInitialLoading(false);
            setSearchPage(1);
          });
        } catch (error) {
          console.log("Error fetching data:", error);
           setInitialLoading(false);
        }
      } else {
        try {
          await dispatch(
            createApiAction(
              `/api/dev/playlist/getAllPlaylist?page=${page}&limit=${itemsPerPage}`,
              "GET"
            )
          ).then(() => {
            setLoading(false);
            setInitialLoading(false);
            setSearchPage(1);
          });
        } catch (error) {
          console.log("Error fetching data:", error);
          setInitialLoading(false);
        }
      }
    }
  };

  const columns = [
    { Header: "Image", accessor: "Image", width: "10%", align: "center" , disableSortBy: true},
    { Header: "Name", accessor: "Name", align: "center" },
    { Header: "Type", accessor: "Type", align: "center" },
    // { Header: "Make it Live", accessor: "Live", align: "center", disableSortBy: true },
    {
      Header: "Make it Live",
      accessor: "Live",
      align: "center",
      sortType: (rowA, rowB) => {
        return rowA.original.statusValue - rowB.original.statusValue; 
      },
    },
    { Header: "Info ", accessor: "Profile", align: "center", disableSortBy: true },
    { Header: "Delete", accessor: "Delete", align: "center" , disableSortBy: true},
  ];

  const handleArtist = async () => {
    try {
      const response = await axiosInstance.get("/api/dev/artist/getAllArtistAdmin");
  
      const filteredArtists = response.data.data.filter(
        (artist) => !artist.is_blocked
      );
  
      SetArtist(filteredArtists);
    } catch (error) {
      console.error("Error fetching artists:", error.message);
      SetArtist([]);
    }
  };
  



  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, categoryData, page, searchPage, filter]);

  useEffect(() => {
    handleApiCall();
  }, [searchText, page, searchPage, filter]);

  useEffect(() => {
    handleArtist();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log("formData", formData);
    setImagePreview(null);
    setFormData({
      title: "",
      playlist_type: "",
      artist_name: "",
      artist_id: "",
      playlist_img: "",
    });

    setOpen(false);
  };

  const handleArtistDropdown = (event, newValue) => {
    setFormData({
      ...formData,
      artist_name: newValue ? newValue.name : "",
      artist_id: newValue ? newValue.artist_id : "",
    });
  };

  const handleChange = (e) => {
    // if(e.target.name==="type"){setFormData({...formData, playlist_type :value})

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileInputChange = (event) => {
    const { id } = event.target;
    const file = event.target.files[0];
    if(file){
      setFormData({ ...formData, [id]: file });
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
   
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();
    newFormData.append("title", formData.title);
    newFormData.append("playlist_type", formData.playlist_type);
    if (
      // formData.playlist_type === "single" ||
      formData.playlist_type === "album"
    ) {
      newFormData.append("artist_id", formData.artist_id);
    }
    newFormData.append("playlist_image", formData.playlist_img);

    try {
      const response = await axiosInstance.post(
        "/api/dev/playlist/addPlaylist",
        newFormData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Successful",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch(
          createApiAction(
            `/api/dev/playlist/getAllPlaylist?page=${page}&limit=${itemsPerPage}`,
            "GET"
          )
        ).then(() => setLoading(false));
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
      });
      console.log("error is", e.message);
    }
    setButtonLoading(false);
    handleClose();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {!initalLoading ? (
                <MDBox>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: "column", md: "row" }}
                    p={3}
                  >
                    <MDBox
                      mb={{ xs: 2, md: 0 }}
                      width={{ xs: "100%", md: "auto" }}
                    >
                      <MDTypography variant="h5" color="white">
                        {`SONG CATEGORIES (${totalCount ? totalCount : ""})`}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      justifyContent="right"
                      alignItems="center"
                      flexDirection={{ xs: "column", md: "row" }}
                      width={{ xs: "100%", md: "auto" }}
                    >
                      <MDBox
                        mr={{ xs: 0, md: 3 }}
                        mb={{ xs: 2, md: 0 }}
                        width={{ xs: "100%", md: "auto" }}
                      >
                        <TextField
                          value={inputValue}
                          onChange={handleSearchChange}
                          id="standard-bare"
                          variant="outlined"
                          placeholder="Search Category"
                          sx={{
                            "& .MuiInputBase-input": {
                              color: "#ffffff",
                              width: { xs: "100%", sm: "240px" },
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton>
                                <SearchOutlined color="white" />
                              </IconButton>
                            ),
                          }}
                          fullWidth
                        />
                      </MDBox>
                      <MDBox width={{ xs: "100%", md: "auto" }}>
                        <Select
                          value={filter}
                          onChange={handleFilterChange}
                          displayEmpty
                          sx={{
                            padding: "12px",
                            backgroundColor: "black",
                            borderRadius: "4px",
                            marginRight: { xs: 0, md: "18px" },
                            mb: { xs: 2, md: 0 },
                            width: { xs: "100%", md: "auto" },
                            "& .MuiSelect-select": {
                              color: "#ffffff",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "white",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "white",
                            },
                            "& .MuiList-root": {
                              backgroundColor: "black",
                            },
                          }}
                          fullWidth
                        >
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="album">Album</MenuItem>
                          <MenuItem value="playlist">Playlist</MenuItem>
                        </Select>
                      </MDBox>
                      <MDButton
                        variant="contained"
                        sx={{
                          marginRight: { xs: 0, md: "12px" },
                          color: "black",
                          paddingBottom: "13px",
                          paddingTop: "13px",
                          width: { xs: "100%", md: "auto" },
                        }}
                        onClick={handleOpen}
                        fullWidth
                      >
                        Add Category
                      </MDButton>
                    </MDBox>
                  </MDBox>

                  <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogTitle style={{ position: "relative" }}>
                      Add Category
                      <span
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <CancelIcon
                          sx={{ color: purple[500] }}
                          onClick={handleClose}
                        />
                      </span>
                      {imagePreview && (
                        <MDBox height="300px" width="100%">
                          <img
                            src={imagePreview}
                            alt="Category Image"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </MDBox>
                      )}
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "auto" }}>
                      <form onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="title"
                          name="title"
                          label="Add Title "
                          type="text"
                          fullWidth
                          required
                          value={formData.title}
                          onChange={handleChange}
                          inputProps={{ maxLength: 30 }}
                          helperText={
                            formData.title.length === 30
                              ? "30 character limit reached"
                              : ""
                          }
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />

                        <FormControl
                          fullWidth
                          sx={{ marginTop: "7px", marginBottom: "4px" }}
                        >
                          <InputLabel fullWidth id="playlist_type">
                            Type
                          </InputLabel>
                          <Select
                            labelId="playlist_type"
                            id="playlist_type"
                            name="playlist_type"
                            value={formData.playlist_type}
                            onChange={handleChange}
                            label="Type"
                            required
                            IconComponent={ArrowDropDown}
                            sx={{ height: "40px" }}
                          >
                            {/* <MenuItem value="single">Single</MenuItem> */}
                            <MenuItem value="playlist">Playlist</MenuItem>
                            <MenuItem value="album">Album</MenuItem>
                          </Select>
                        </FormControl>

                        {
                          // formData.playlist_type === "single" ||
                          formData.playlist_type === "album" && (
                            <Autocomplete
                              sx={{ marginTop: "7px", marginBottom: "7px" }}
                              id="artist_name"
                              options={artist}
                              fullWidth
                              autoHighlight
                              getOptionLabel={(option) => option.name}
                              onChange={handleArtistDropdown}
                              renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                  <MDBox
                                    key={option.artist_id}
                                    component="li"
                                    {...optionProps}
                                  >
                                    {option.name}
                                  </MDBox>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Artist"
                                  required
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                />
                              )}
                            />
                          )
                        }

                        <label
                          htmlFor="playlist_img"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            border: "1px solid #ccc",
                            padding: "2px",
                            marginTop: "7px",
                            marginBottom: "12px",
                            borderRadius: "5px",
                          }}
                        >
                          <input
                            type="file"
                            id="playlist_img"
                            accept="image/*"
                            required={!formData.playlist_img}
                            onChange={handleFileInputChange}
                            style={{
                              position: "absolute",
                              width: "1px",
                              height: "1px",
                              opacity: 0,
                            }}
                          />
                          <Button component="span" color="primary" fullWidth>
                            Add Image
                          </Button>
                          <span
                            style={{
                              textAlign: "center",
                              width: "100%",
                              marginTop: "1px",
                            }}
                          >
                            {formData.playlist_img?.name}
                          </span>
                        </label>

                        <MDButton
                          type="submit"
                          sx={{
                            backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                            marginRight: "12px",
                          }}
                          fullWidth
                        >
                          {buttonLoading ? (
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                            >
                              <CircularProgress
                                sx={{ color: "#ffffff" }}
                                size={24}
                              />
                            </MDBox>
                          ) : (
                            <MDTypography
                              style={{ fontSize: "13.5px", fontWeight: "bold" }}
                              color="white"
                            >
                              Submit
                            </MDTypography>
                          )}
                        </MDButton>
                      </form>
                    </DialogContent>
                  </Dialog>
                  {rows?.length > 0 ? (
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns, rows }}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                        isSorted={true}
                      />
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                        mb={3}
                      >
                        <Pagination
                          count={Math.ceil(totalCount / itemsPerPage)}
                          page={searchText === "" ? page : searchPage}
                          onChange={(event, value) => {
                            {
                              searchText === ""
                                ? setPage(value)
                                : setSearchPage(value);
                            }
                          }}
                          color="primary"
                          shape="rounded"
                          size="large"
                          sx={{
                            "& .MuiPaginationItem-root": {
                              borderRadius: "8px",
                              border: "1px solid #ddd",
                              margin: "0 4px",
                              "&.Mui-selected": {
                                backgroundColor: "#5870FD",
                                color: "white",
                                border: "1px solid #5870FD",
                              },
                              "&:hover": {
                                backgroundColor: "#bb48bd",
                              },
                            },
                            "& .MuiPaginationItem-previousNext": {
                              borderRadius: "8px",
                              border: "1px solid #ddd",
                            },
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  ) : (
                    <MDBox
                      sx={{
                        display: "flex",
                        padding: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <MDTypography variant="caption" sx={{ fontSize: "20px" }}>
                        {/* { !loading &&"There are currently no categories available." }  */}
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              ) :
              !error? (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100vw"
                  height="100vh"
                  position="fixed"
                  top={0}
                  left={0}
                >
                  <CircularProgress sx={{ color: "purple" }} size={54} />
                </MDBox>
              ):(<MDBox
                display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
              >
               <MDTypography>
                Error Occured
               </MDTypography>
              </MDBox>)
            }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SongCategory;
