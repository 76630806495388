/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { baseUrl } from "base";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import cpBgImage from "assets/images/Cp-bg-image.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { createApiAction } from "store/ApiServices";
import axiosInstance from "axiosInstance";
import { countries } from "assets/countries/Countries";
import Swal from "sweetalert2";
import profileDefault from "assets/images/Profile-default.png";
import { Cancel as CancelIcon, SearchOutlined } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const user = useSelector((state) => state.apiData.data.data);
  const { id } = useParams();
  const cpId = {
    cp_id: user.user_id,
  };
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const dispatch = useDispatch();
  // const user=useSelector((state)=>state.apiData.data.data)
  // const cpName = cpData.map((data) => data.name);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);

  const [editForm, setEditForm] = useState({
    name: "",
    msisdn: "",
    image_url: "",
    // email: "",
    DOB: "",
    gender: "",
    country: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    image: "",
    email: "",
    password: "",
    DOB: "",
    gender: "",
    country: "",
    cp_name: "",
    cp_id: "",
    CP: "",
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddUserClick = () => {
    setIsAddUserDialogOpen(true);
    setFormData({
      name: "",
      mobile: "",
      image: "",
      email: "",
      password: "",
      DOB: "",
      gender: "",
      country: "",
      cp_name: "",
      cp_id: user?.user_id,
      CP: user?.name,
    });
  };

  const handleCpDelete = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete this Content Provider?",
      html: '<p style="color: red;">Deleting this Content Provider will remove all related data. <strong>This action cannot be undone.</strong></p>',
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/admin/CP/deleteCP", cpId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "CP Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            navigate("/contentProviders");
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "There was an error deleting this CP.",
            });
          });
      }
    });
  };

  const handleEditUserClick = () => {
    setIsEditUserDialogOpen(true);
    const initialForm = {
      name: user?.name || "",
      // email: user?.email || "",
      msisdn: user?.msisdn || "",
      DOB: user?.DOB || "",
      gender: user?.gender || "",
      country: user?.country || "",
      image_url: null,
    };
    setEditForm(initialForm);
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();

    for (const key in editForm) {
      if (key in user) {
        if (
          editForm[key] !== "" &&
          editForm[key] !== user[key] &&
          editForm[key] !== null &&
          key !== "image_url"
        ) {
          console.log(key, editForm[key]);
          newFormData.append(key, editForm[key]);
        }
      }
    }
    if (editForm.image_url !== null) {
      newFormData.append("image", editForm.image_url);
    }
    newFormData.append("cp_id", user.user_id);

    try {
      await axiosInstance.post(
        "/api/dev/admin/CP/updateCPprofile",
        newFormData
      );
      Swal.fire({
        title: "Profile Updated!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });

      dispatch(createApiAction("/api/dev/admin/CP/getCpById", "POST", cpId));
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        icon: "error",
      });
      console.log(e.message);
    }
    setButtonLoading(false);
    handleEditClose();
  };

  const handleEditClose = () => {
    console.log(editForm);
    setFormErrors({});
    setIsEditUserDialogOpen(false);
    setImagePreview(null);
  };

  const handleCloseDialogs = () => {
    setImagePreview(null);
    setFormErrors({});
    setIsAddUserDialogOpen(false);
    setFormData({
      name: "",
      email: "",
      mobile: "",
      image: "",
      password: "",
      DOB: "",
      gender: "",
      country: "",
      cp_name: "",
      cp_id: user?.user_id,
      CP: user?.name,
    });
  };

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;

    let error = "";

    if (name === "mobile") {
      if (!/^[1-9]\d{9}$/.test(value)) {
        error = "Contact number must be exactly 10 digits";
      }
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Enter valid email address.";
      }
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }

    if (name === "gender") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleEditChange = (event) => {
    const { id, value, name } = event.target;
    let error = "";
    if (name === "msisdn") {
      if (!/^[1-9]\d{9}$/.test(value)) {
        error = "Contact number must be exactly 10 digits";
      }
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }
    //  else if (name === "email") {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   if (!emailRegex.test(value)) {
    //     error = "Enter valid email address.";
    //   }
    //   setFormErrors({
    //     ...formErrors,
    //     [name]: error,
    //   });
    // }
    if (name === "gender") {
      setEditForm({ ...editForm, [name]: value });
    } else {
      setEditForm({ ...editForm, [id]: value });
    }
  };

  const handleCountryChange = (event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : "",
    });
  };

  const handleCountryEdit = (event, newValue) => {
    setEditForm({
      ...editForm,
      country: newValue ? newValue.label : "",
    });
  };

  const handleAutoComplete = (fieldName, lableName, valueName) => {
    return fieldName.find((item) => item[lableName] === valueName) || null;
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, image: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileInputEdit = (event) => {
    const file = event.target.files[0];
    setEditForm({ ...editForm, image_url: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const closeDialog = () => {
    handleCloseDialogs();
  };
  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();
    newFormData.append("name", formData.name);
    newFormData.append("mobile", formData.mobile);
    newFormData.append("image", formData.image);
    newFormData.append("email", formData.email);
    newFormData.append("password", formData.password);
    newFormData.append("DOB", formData.DOB);
    newFormData.append("gender", formData.gender);
    newFormData.append("country", formData.country);
    newFormData.append("cp_id", formData.cp_id);

    try {
      await axiosInstance.post("/api/dev/artist/addArtist", newFormData);
      Swal.fire({
        icon: "success",
        title: "Artist Added",
        timer: 1500,
        showConfirmButton: false,
      });

      dispatch(createApiAction("/api/dev/admin/CP/getCpById", "POST", cpId));
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        icon: "error",
        html: `<span style="color: red;">${e?.response?.data?.message}</span>`,

      });
      console.log(e.message);
    }
    setButtonLoading(false);
    closeDialog();
  };

  return (
    <MDBox position="relative" mb={5}>
   <MDBox
  display="flex"
  alignItems="left"
  justifyContent="left" 
  position="relative"
  minHeight="18.75rem"
  borderRadius="xl"
  sx={{
    backgroundImage: ({
      functions: { rgba, linearGradient },
      palette: { gradients },
    }) =>
      `${linearGradient(
        rgba(gradients.primary.main, 0.8),
        rgba(gradients.info.state, 0.8)
      )}, url(${cpBgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "hidden",
  }}
>
  <MDBox
    position="absolute"
    top="3%"
    left="1.5%"
    transform="translate(-50%, -50%)"
    textAlign="left"
    sx={{
       color: "rgba(29, 28, 115, 0.4)",
      fontSize: "2.5rem", 
      // fontWeight: "bold",
      zIndex: 1, 
    }}
  >
    Content Provider
  </MDBox>
</MDBox>

      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={user?.image_url ? baseUrl + user?.image_url : profileDefault}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {user?.name}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {user?.email}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                  gap: 1,
                  marginRight: { xs: "0", sm: "40px" },
                  flexWrap: "wrap",
                }}
              >
                <MDButton
                  onClick={handleAddUserClick}
                  sx={{
                    
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    width: { xs: "100%", sm: "auto" },
                  }}
                  size="small"
                >
                  <MDTypography
                    sx={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Add Artist
                  </MDTypography>
                </MDButton>

                <MDButton
                  onClick={handleEditUserClick}
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    width: { xs: "100%", sm: "auto" },
                  }}
                  size="small"
                >
                  <MDTypography
                    sx={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Edit Profile
                  </MDTypography>
                </MDButton>

                <MDButton
                  onClick={handleCpDelete}
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #e01212 0%, #e01212 100%)`,
                    width: { xs: "100%", sm: "auto" },
                  }}
                  size="small"
                >
                  <MDTypography
                    sx={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Delete CP
                  </MDTypography>
                </MDButton>
              </MDBox>

              {/* </Tabs> */}

              {/* Add User Dialog */}
              <Dialog open={isAddUserDialogOpen} onClose={handleCloseDialogs}  maxWidth="sm"
                fullWidth>
                <DialogTitle>
                  Add Artist
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CancelIcon
                      sx={{ color: purple[500] }}
                      onClick={handleCloseDialogs}
                    />
                  </span>
                  {imagePreview && (
                    <MDBox display="flex" justifyContent="center">
                      <MDAvatar
                        src={imagePreview}
                        alt="profile-image"
                        sx={{ height: "140px", width: "140px" }}
                        shadow="sm"
                      />
                    </MDBox>
                  )}
                </DialogTitle>
                <DialogContent style={{ overflowX: "hidden" }}>
                  <form onSubmit={handleAddFormSubmit}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Full Name"
                      type="text"
                      fullWidth
                      required
                      value={formData.name}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 25 }}
                      helperText={formData.name.length === 25 ? "25 character limit reached" : ""}
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />

                    <TextField
                      margin="dense"
                      id="email"
                      name="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      required
                      value={formData.email}
                      onChange={handleInputChange}
                      error={Boolean(formErrors.email)}
                      helperText={formErrors.email}
                    />

                    <TextField
                      margin="dense"
                      id="mobile"
                      name="mobile"
                      required
                      label="Contact Number"
                      type="text"
                      fullWidth
                      value={formData.mobile}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                      error={!!formErrors.mobile}
                      helperText={formErrors.mobile}
                    />

                    <TextField
                      margin="dense"
                      id="DOB"
                      // label="Date of Birth"
                      type="date"
                      fullWidth
                      value={formData.DOB}
                      onChange={handleInputChange}
                     
                    />
                    <FormControl
                      fullWidth
                      sx={{ marginTop: "7px", marginBottom: "4px" }}
                    >
                      <InputLabel id="gender1">Gender</InputLabel>
                      <Select
                        labelId="gender1"
                        id="gender"
                        value={formData.gender}
                        label="Gender"
                        name="gender"
                        required
                        onChange={handleInputChange}
                        sx={{ height: "40px" }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>

                    <Autocomplete
                      sx={{ marginTop: "6.5px", marginBottom: "2px" }}
                      id="country-select-demo"
                      options={countries}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      onChange={handleCountryChange}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a Country"
                          required
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />

                    <label
                      htmlFor="profileImage"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "11px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        required
                        id="profileImage"
                        accept="image/*"
                        onChange={handleFileInputChange}
                        style={{
                          position: "absolute",
                          width: "1px",
                          height: "1px",
                          opacity: 0,
                        }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Add Image
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "1px",
                        }}
                      >
                        {formData.image?.name}
                      </span>
                    </label>

                    {/* Submit button */}
                    {/* <Button type="submit" variant="contained" fullWidth sx={{color:"black",bgcolor:"white"}}>
            Submit
          </Button> */}
                    <Button
                      type="submit"
                      fullWidth
                      disabled={Object.values(formErrors).some(
                        (error) => error !== ""
                      )}
                      sx={{
                        backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                        marginTop: "11px",
                      }}
                      size="small"
                    >
                      {buttonLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={24}
                          />
                        </MDBox>
                      ) : (
                        <MDTypography
                          style={{ fontSize: "13.5px", fontWeight: "bold" }}
                          color="white"
                        >
                          Submit
                        </MDTypography>
                      )}
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>

              {/* Edit User Dialog */}
              <Dialog open={isEditUserDialogOpen} onClose={handleEditClose}  maxWidth="sm"
                fullWidth>
                <DialogTitle>
                  Edit Profile
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CancelIcon
                      sx={{ color: purple[500] }}
                      onClick={handleEditClose}
                    />
                  </span>
                  <MDBox display="flex" justifyContent="center">
                    <MDAvatar
                      src={
                        imagePreview
                          ? imagePreview
                          : user?.image_url
                          ? baseUrl + user?.image_url
                          : profileDefault
                      }
                      alt="profile-image"
                      sx={{ height: "140px", width: "140px" }}
                      shadow="sm"
                    />
                  </MDBox>
                </DialogTitle>
                <DialogContent sx={{ overflowX: "hidden" }}>
                  <form onSubmit={handleEditFormSubmit}>
                  <label
                      htmlFor="image_url"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "10px",
                        marginBottom:"5px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        id="image_url"
                        accept="image/*"
                        onChange={handleFileInputEdit}
                        style={{ display: "none" }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Edit Profile Image
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "0.5px",
                        }}
                      >
                        {editForm.image_url?.name}
                      </span>
                    </label>

                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Full Name"
                      type="text"
                      fullWidth
                      value={editForm.name}
                      onChange={handleEditChange}
                      inputProps={{ maxLength: 25 }}
                      helperText={editForm.name.length === 25 ? "25 character limit reached" : ""}
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />
                    {/* <TextField
                      margin="dense"
                      id="email"
                      name="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      value={editForm.email}
                      onChange={handleEditChange}
                      error={Boolean(formErrors.email)}
                      helperText={formErrors.email}
                    /> */}
                    <TextField
                      margin="dense"
                      id="msisdn"
                      name="msisdn"
                      label="Mobile"
                      type="text"
                      fullWidth
                      value={editForm.msisdn}
                      onChange={handleEditChange}
                      inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                      error={!!formErrors.msisdn}
                      helperText={formErrors.msisdn}
                    />
                    <TextField
                      margin="dense"
                      id="DOB"
                      label="Date of Birth"
                      type="date"
                      fullWidth
                      value={editForm.DOB|| ""}
                      onChange={handleEditChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        placeholder: "Date of Birth",
                      }}
                    />
                    <FormControl
                      fullWidth
                      sx={{ marginTop: "7px", marginBottom: "4px" }}
                    >
                      <InputLabel id="gender1">Gender</InputLabel>
                      <Select
                        labelId="gender1"
                        id="gender"
                        value={editForm.gender}
                        label="Gender"
                        name="gender"
                        onChange={handleEditChange}
                        sx={{ height: "40px" }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                    <Autocomplete
                      sx={{ marginTop: "6.5px", marginBottom: "2px" }}
                      id="country-select-demo"
                      options={countries}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      onChange={handleCountryEdit}
                      value={handleAutoComplete(
                        countries,
                        "label",
                        editForm.country
                      )}
                      clearOnBlur
                      disableClearable
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a Country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    
                    <Button
                      type="submit"
                      fullWidth
                      disabled={Object.values(formErrors).some(
                        (error) => error !== ""
                      )}
                      sx={{
                        backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                        marginTop: "11px",
                      }}
                      size="small"
                    >
                      {buttonLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={24}
                          />
                        </MDBox>
                      ) : (
                        <MDTypography
                          style={{ fontSize: "13.5px", fontWeight: "bold" }}
                          color="white"
                        >
                          Submit
                        </MDTypography>
                      )}
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
