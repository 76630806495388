
import userSlice from "./UserData"
import apislice from "./apislice"
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import cpSlice from "layouts/CPprofile/cpSlice";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk';
import songSlice from "./SongSlice";
import AdminSlice from "./AdminSlice";
import SearchSlice from "./SearchSlice";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};

const rootReducer = combineReducers({  UserData:userSlice,
    apiData:apislice,
    songData:songSlice,
    adminData:AdminSlice,
    searchData:SearchSlice,
    cpData:cpSlice,});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(thunk),
});
    
export const persistor = persistStore(store);
