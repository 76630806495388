import React from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import AppStoreLogo from "../../assets/images/logos/AppStoreLogo.svg";
import GooglePlay from "../../assets/images/logos/GooglePlay.svg";

import MDBox from "components/MDBox";
import beatz from "../../assets/images/logos/beatz.png";
import MobileImages from "./MobileImages";
import Footer from "./Footer";

const MobileAppDownload = () => {
  return (
    <PageLayout>
      <Box
        sx={{
          background: "linear-gradient(90deg, #4d5cc9 0%, #d156a8 100%)",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            margin: "auto",
            padding: "2rem",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={5}>
            <MDBox
              mb={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
             
              <img
                src={beatz}
                alt="logo"
                style={{ height: "80px", width: "80px" }}
              /> 
              <Typography
                variant="h1"
                sx={{
                  fontWeight: "bold",
                  WebkitTextStroke: "1.5px white", 
                  color: "#000000",              
                }}
              >
                EATZ MUSIC
              </Typography>
            </MDBox>

            <Typography
              variant="body1"
              color="#ffffff"
              style={{ fontSize: "16px" }}
            >
              Welcome to Beatz Music, the premier music streaming app designed
              exclusively for music lovers in Ghana. Whether you're into
              afrobeats, hiplife, highlife, gospel, or trending global hits,
              Beatz Music offers a seamless, personalized listening experience
              that connects you to your favorite artists and songs.Beatz Music
              is your go-to app for a smooth, personalized music experience that
              brings you closer to the songs and artists you love.

              
            </Typography>
            {/*
              🎵 *Welcome to Beatz Music* – Ghana’s #1 music streaming app! 🇬🇭  
              From 🔥 afrobeats and 🎤 hiplife to 🎶 highlife, 🙏 gospel, and 🌍 global hits, we’ve got all your favorite tunes in one place. 🎧 Enjoy a smooth, personalized experience that keeps you connected to the music and artists you love. ❤️🎶  

              Mohit, 13:37
              Welcome to Beatz Music – Ghana’s #1 music streaming app! From afrobeats and hiplife to highlife, gospel, and global hits, we’ve got all your favorite tunes in one place. Enjoy a smooth, personalized listening experience that keeps you connected to the music and artists you love.

              Mohit, 13:37
              Welcome to Beatz Music – Ghana’s ultimate music streaming app! Dive into the vibrant world of afrobeats, hiplife, highlife, gospel, and global chart-toppers. Beatz Music is your go-to app for a smooth, personalized music experience that brings you closer to the songs and artists you love. */}
            <Footer/>
          </Grid>

          <Grid item xs={12} md={7}>
            <MobileImages />
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
};

export default MobileAppDownload;
