import { useState, useEffect } from "react";

import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";
import Checkbox from "@mui/material/Checkbox";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { CircularProgress } from "@mui/material";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { createApiAction } from "store/ApiServices";
// Data
// import data from "layouts/dashboard/genre/data";
import EditIcon from "@mui/icons-material/Edit";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { Cancel as CancelIcon, SearchOutlined } from "@mui/icons-material";
import { purple } from "@mui/material/colors";

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const FaqData = useSelector((state) => state.apiData.data.data);
  const {error}=useSelector((state)=>state.apiData)
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [formData, setFormData] = useState({
    pp_type: "",
    content: "",
  });
  const [editData, setEditData] = useState({
    pp_id: "",
    pp_type: "",
    content: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditOpen = (policy) => {
    setEditOpen(true);
    setEditData({
      pp_id: policy.pp_id,
      pp_type: policy.pp_type,
      content: policy.content,
    });
  };
  const handleClose = () => {
    console.log(formData);
    setFormData({
      pp_type: "",
      content: "",
    });
    setOpen(false);
  };

  const handleEditClose = () => {
    console.log("editform called", editData);

    setEditOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleEditChange = (e) => {
    setEditData({
      ...editData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
 
    setButtonLoading(true);

    console.log("submit called", formData);
    const newFormData = formData;

    try {
      const response = await axiosInstance.post(
        "/api/dev/admin/privacyPolicy/addPrivacyPolicy",
        newFormData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Policy Added",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch(
          createApiAction(
            `/api/dev/admin/privacyPolicy/getPrivacyPolicyAdmin`,
            "GET"
          )
        );
        setFormData({
          pp_type: "",
          content: "",
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
      });
      console.log("error is", e.message);
    }
    setButtonLoading(false);

    handleClose();
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    const newFormData = editData;
    try {
      const response = await axiosInstance.post(
        "/api/dev/admin/privacyPolicy/editPP",
        newFormData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Policy Updated",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch(
          createApiAction(
            `/api/dev/admin/privacyPolicy/getPrivacyPolicyAdmin`,
            "GET"
          )
        );
        setFormData({
          pp_id: "",
          pp_type: "",
          content: "",
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
      });
      console.log("error is", e.message);
    }
    setButtonLoading(false);

    handleEditClose();
  };

  const handleDelete = (policyId) => {
    const PolicyRemoveId = {
      pp_id: policyId,
    };
    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this Policy?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post(
            "/api/dev/admin/privacyPolicy/deletePrivacyPolicy",
            PolicyRemoveId
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Policy Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(
              createApiAction(
                `/api/dev/admin/privacyPolicy/getPrivacyPolicyAdmin`,
                "GET"
              )
            );
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "There was an error deleting this policy.",
            });
          });
      }
    });
  };

  const handleStatus = async (policyId) => {
    const PolicyStatusId = {
      pp_id: policyId,
    };

    try {
      await axiosInstance.post(
        "/api/dev/admin/privacyPolicy/managePPstatus",
        PolicyStatusId
      );
      await dispatch(
        createApiAction(
          `/api/dev/admin/privacyPolicy/getPrivacyPolicyAdmin`,
          "GET"
        )
      );
    } catch (error) {
      console.error("Error changing Genre status:", error);
    }
  };

  const handleRows = () => {
    return FaqData.map((policy, index) => (
      <MDBox key={index} mb={2} px={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <MDTypography
              variant="h6"
              fontWeight="bold"
              sx={{
                overflow: "auto",
                textTransform: "capitalize",
                wordWrap: "break-word",
                maxWidth: { xs: "100%", sm: "75%" },
              }}
            >
              {policy.pp_type}
            </MDTypography>
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
                <Tooltip key={policy.pp_id} title={"status"} placeholder="bottom">
              <Switch
                checked={policy?.status}
                onChange={(e) => {
                  e.stopPropagation();
                  handleStatus(policy.pp_id);
                }}
                onClick={(e) => e.stopPropagation()}
              />
               </Tooltip>
              <IconButton
                aria-label="edit"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditOpen(policy);
                }}
              >
                <EditIcon
                 
                />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(policy.pp_id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </MDBox>
          </MDBox>
        </AccordionSummary>
        <AccordionDetails>
          <MDTypography
            variant="body2"
            color="secondary"
            sx={{ overflow: "auto", wordWrap: "break-word" }}
          >
            {policy.content}
          </MDTypography>
        </AccordionDetails>
      </Accordion>
    </MDBox>
    
    ));
  };
  const handleApiCall = async () => {
    try {
      await dispatch(
        createApiAction(
          `/api/dev/admin/privacyPolicy/getPrivacyPolicyAdmin`,
          "GET"
        )
      ).then(() => setLoading(false));
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, FaqData]);

  useEffect(() => {
    handleApiCall();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {!loading?(
                 <MDBox>
                 <MDBox
                   mx={2}
                   // mt={-3}
                   // py={3}
                   px={2}
                   variant="gradient"
                   bgColor="info"
                   borderRadius="lg"
                   coloredShadow="info"
                   sx={{
                     backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                   }}
                   display="flex"
                   justifyContent="space-between"
                   alignItems="center"
                   p={3}
                 >
                   <MDBox>
                     <MDTypography variant="h5" color="white">
                       PRIVACY POLICY
                     </MDTypography>
                   </MDBox>
                   <MDTypography>
                     <MDButton
                        variant="contained"
                        size="small"
                        sx={{ color: "black",
                          marginLeft: { xs: 0, md: "12px" },
                          marginRight: { xs: 0, md: "12px" },
                          color: "black",
                          paddingBottom: "13px",
                          paddingTop: "13px",
                          width: { xs: "100%", md: "auto" },}}
                          fullWidth
                       onClick={handleOpen}
                     >
                       Add Policy
                     </MDButton>
                   </MDTypography>
                   {/* Add Policy */}
                   <Dialog open={open} onClose={handleClose} maxWidth="sm"  fullWidth>
                     <DialogTitle>
                       Add Policy
                       <span
                         style={{
                           position: "absolute",
                           top: 0,
                           right: 0,
                           padding: "10px",
                           cursor: "pointer",
                         }}
                       >
                         <IconButton  onClick={handleClose}>
                         <CancelIcon
                           sx={{ color: purple[500] }}
                         />
                         </IconButton>
                       </span>
                     </DialogTitle>
                     <DialogContent style={{ overflowX: "hidden"}}>
                       <form onSubmit={handleSubmit}>
                         <TextField
                           autoFocus
                           margin="dense"
                           id="pp_type"
                           name="pp_type"
                           label="Policy Type "
                           type="text"
                           required
                           fullWidth
                           value={formData.pp_type}
                           onChange={handleChange}
                         />
     
                         <TextField
                           placeholder="Add Policy"
                           margin="dense"
                           id="content"
                           name="content"
                           label="Add Policy"
                           type="text"
                           required
                           fullWidth
                           value={formData.content}
                           onChange={handleChange}
                           variant="outlined"
                           multiline
                           rows={6}
                         />
     
                         <Button
                           type="submit"
                           sx={{
                             backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                             marginRight: "12px",
                             marginTop: "10px",
                           }}
                           fullWidth
                         >
                           {buttonLoading ? (
                             <MDBox
                               display="flex"
                               justifyContent="center"
                               alignItems="center"
                               width="100%"
                             >
                               <CircularProgress
                                 sx={{ color: "#ffffff" }}
                                 size={24}
                               />
                             </MDBox>
                           ) : (
                             <MDTypography
                               style={{ fontSize: "13.5px", fontWeight: "bold" }}
                               color="white"
                             >
                               Submit
                             </MDTypography>
                           )}
                         </Button>
                       </form>
                     </DialogContent>
                   </Dialog>
                   {/* Edit Policy */}
                   <Dialog open={editOpen} onClose={handleEditClose} maxWidth="sm"  fullWidth>
                     <DialogTitle>
                       Edit Policy
                       <span
                         style={{
                           position: "absolute",
                           top: 0,
                           right: 0,
                           padding: "10px",
                           cursor: "pointer",
                         }}
                       >
                        <IconButton 
                           onClick={handleEditClose}>
                         <CancelIcon
                           sx={{ color: purple[500] }}
                         />
                         </IconButton>
                       </span>
                     </DialogTitle>
                     <DialogContent style={{ overflowX: "hidden"}}>
                       <form onSubmit={handleEditSubmit}>
                         <TextField
                           autoFocus
                           margin="dense"
                           id="pp_type"
                           name="pp_type"
                           label="Policy Type "
                           type="text"
                           required
                           fullWidth
                           value={editData.pp_type}
                           onChange={handleEditChange}
                         />
     
                         <TextField
                           placeholder="Add Policy"
                           margin="dense"
                           id="content"
                           name="content"
                           label="Add Policy"
                           type="text"
                           required
                           fullWidth
                           value={editData.content}
                           onChange={handleEditChange}
                           variant="outlined"
                           multiline
                           rows={6}
                         />
     
                         <Button
                           type="submit"
                           sx={{
                             backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                             marginRight: "12px",
                             marginTop: "10px",
                           }}
                           fullWidth
                         >
                           {buttonLoading ? (
                             <MDBox
                               display="flex"
                               justifyContent="center"
                               alignItems="center"
                               width="100%"
                             >
                               <CircularProgress
                                 sx={{ color: "#ffffff" }}
                                 size={24}
                               />
                             </MDBox>
                           ) : (
                             <MDTypography
                               style={{ fontSize: "13.5px", fontWeight: "bold" }}
                               color="white"
                             >
                               Submit
                             </MDTypography>
                           )}
                         </Button>
                       </form>
                     </DialogContent>
                   </Dialog>
                 </MDBox>
                 <MDBox pt={3}>
                 
                   {!loading && handleRows()}
                 </MDBox>
                 </MDBox>
            )
            :
            error?
      (<MDBox
        display="flex"
  justifyContent="center"
  alignItems="center"
  width="100vw"
  height="100vh"
  position="fixed"
  top={0}
  left={0}
      >
       <MDTypography>
        Error Occured
       </MDTypography>
      </MDBox>)
       : (<MDBox
        display="flex"
  justifyContent="center"
  alignItems="center"
  width="100vw"
  height="100vh"
  position="fixed"
  top={0}
  left={0}
      >
        <CircularProgress
          sx={{ color: "purple" }}
          size={54}
        />
      </MDBox>)
          }
         
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default PrivacyPolicy;
