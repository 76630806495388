import { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import axiosInstance from "axiosInstance";
import { createApiAction } from "store/ApiServices";
import { useSelector } from "react-redux";
import { switchTheme } from "examples/CommonFunctions";
import {  ThemeProvider } from "@mui/material/styles";

function PlatformSettings() {
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(false);
  const user = useSelector((state) => state.apiData.data.data);
  const dispatch = useDispatch();
  const artistID = {
    artist_id: user.artist_id,
  };
  const SwitchItem = ({ checked, onChange, label }) => {
    return (
      <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
        <MDBox mt={0.5}>
          <Switch checked={checked} onChange={onChange} />
        </MDBox>
        <MDBox width="80%" ml={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            {label}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  };

  const handleToggle = async (endPoint) => {
    try {
      await axiosInstance.post(endPoint, artistID);
      await dispatch(
        createApiAction("/api/dev/artist/getArtistById", "POST", artistID)
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Control Panel
        </MDTypography>
        <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
             <SwitchItem
              checked={user.is_blocked}
              onChange={() => {
                handleToggle("/api/dev/artist/blockOrUnblockArtist");
              }}
              label="Blocked"
            />
 
          <SwitchItem
            checked={user.is_verified}
            label="Verification status"
            onChange={() => {
              handleToggle("/api/dev/artist/verifyArtist");
            }}
          />
          <SwitchItem
            checked={user.popularArtist}
            onChange={() => {
              handleToggle("/api/dev/artist/managePopularArtist");
            }}
            label="Popular Artist"
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
