// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router";
import axiosInstance from "axiosInstance";
import { baseUrl } from "base";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { capitalizeFirstLetter } from "examples/CommonFunctions";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import MDPagination from "components/MDPagination";
import Pagination from "@mui/material/Pagination";
import { Divider, IconButton } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
// @mui material components
import Icon from "@mui/material/Icon";

// Data

import { useDispatch, useSelector } from "react-redux";
import { createApiAction } from "store/ApiServices";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Cancel as CancelIcon, Height } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { Autocomplete } from "@mui/material";
import { Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import profileDefault from "assets/images/Profile-default.png";
import InfoIcon from "@mui/icons-material/Info";
import { CircularProgress } from "@mui/material";
import { formatDate } from "examples/Date";

function Feedbacks() {
  const [rows, setRows] = useState([]);
  const [artist, SetArtist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initailLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const itemsPerPage = 10;
  const timeoutRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState("");
  const feedbackData = useSelector(
    (state) => state.apiData.data.data.allFeedback
  );
  const { totalCount } = useSelector((state) => state.apiData.data.data);
  const{error}=useSelector((state)=>state.apiData)
  const handleInfoClick = (item) => {
    setCurrentFeedback(item);
    setDialogOpen(true);
  };

  const handleClose = (item) => {
    setDialogOpen(false);
    if (!item.status) {
      handleStatus(item.feedback_id);
    }
  };

  const handleStatus = async (feedbackId) => {
    const FeedbackStatusId = {
      feedback_id: feedbackId,
    };

    try {
      await axiosInstance.post(
        "/api/dev/admin/feedback/manageMarkAsRead",
        FeedbackStatusId
      );
      dispatch(
        createApiAction(
          `/api/dev/admin/feedback/getAllFeedback?limit=${itemsPerPage}&page=${page}`,
          "GET"
        )
      ).then(() => setLoading(false));
    } catch (error) {
      console.error("Error changing Genre status:", error);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchText(value);
    }, 300);
  };
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          // src={baseUrl + image}
          src={image !== "" ? baseUrl + image : profileDefault}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const handleDelete = (feedbackID) => {
    const FeedbackRemoveID = {
      feedback_id: feedbackID,
    };
    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this Feedback?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/admin/feedback/removeFeedback", FeedbackRemoveID)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Feedback Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(
              createApiAction(
                `/api/dev/admin/feedback/getAllFeedback?limit=${itemsPerPage}&page=${page}`,
                "GET"
              )
            ).then(() => setLoading(false));
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "There was an error deleting this Feedback.",
            });
          });
      }
    });
  };

  const FeedbackDialog = ({ open, handleClose, content }) => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle style={{ position: "relative" }}>
          <span
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "10px",
              cursor: "pointer",
            }}
          >
            <CancelIcon sx={{ color: purple[500] }} onClick={handleClose} />
          </span>

          <MDTypography
            variant="body1"
            color="dark"
            sx={{
              fontSize: "19px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {avatars([[content.user_detail?.image_url, content?.name]])}

            <MDBox sx={{ display: "flex", flexDirection: "column" }}>
              <MDBox style={{ fontWeight: "bold" }}>
                {content?.user_detail?.name}
              </MDBox>

              <MDTypography
                variant="caption"
                color="dark"
                sx={{ fontSize: "14px", marginTop: "0px" }}
              >
                <a
                  href={`mailto:${content?.user_detail?.email}`}
                  style={{ textDecoration: "none" }}
                >
                  {content?.user_detail?.email}
                </a>
              </MDTypography>
              <MDTypography
                variant="caption"
                color="dark"
                sx={{ fontSize: "14px", marginTop: "0px" }}
              >
                {content?.msisdn ? content.msisdn : "NA"}
              </MDTypography>
            </MDBox>
          </MDTypography>
        </DialogTitle>
        <Divider sx={{ margin: "0px" }} />
        <DialogContent
          fullWidth
          sx={{ overflowX: "hidden", minHeight: "170px" }}
        >
          <MDTypography
            variant="body1"
            color="text"
            sx={{
              fontSize: "17px",
              lineHeight: "1.5",
              color: "#555",
            }}
          >
            {content?.content}
          </MDTypography>
        </DialogContent>
      </Dialog>
    );
  };

  const handleRows = () => {
    const rowsAPI = feedbackData?.map((feedback) => ({
      Image: (
        <MDBox display="flex" py={1}>
          {avatars([[feedback.user_detail?.image_url, feedback?.name]])}
        </MDBox>
      ),
      Name: feedback?.user_detail?.name
        ? capitalizeFirstLetter(feedback.user_detail.name)
        : "NA",

      Mobile: feedback?.msisdn ? feedback.msisdn : "NA",
      date: feedback?.createdAt ? formatDate(feedback.createdAt) : "NA",
      Feedback: (
        <MDBox
          sx={{
            overflow: "hidden",
            width: "420px",
            minHeight: "10px",
            maxHeight: "90px",
          }}
        >
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {feedback?.content}
          </MDTypography>
        </MDBox>
      ),
      Action: (
        <MDBox>
          <Tooltip
            key={feedback.feedback_id}
            title={"Mark as read"}
            placeholder="bottom"
          >
            <Switch
              checked={feedback?.status}
              onChange={(e) => {
                handleStatus(feedback.feedback_id);
              }}
            />
          </Tooltip>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(feedback.feedback_id);
            }}
          >
            <DeleteIcon />
          </IconButton>
          <Tooltip title="More Info">
            <IconButton onClick={() => handleInfoClick(feedback)} size="medium">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
      statusValue: feedback.status ? 1 : 0,

    }));

    setRows(rowsAPI);
    setLoading(true);
  };

  const handleApiCall = async () => {
    if (searchText.trim() !== "") {
      try {
        const SearchData = {
          search_keyword: searchText,
        };
        await dispatch(
          createApiAction(
            `/api/dev/admin/feedback/feedbackSearch?page=${searchPage}&limit=${itemsPerPage}`,
            "POST",
            SearchData
          )
        ).then(() => {
          setLoading(false);
          setInitialLoading(false);
        });
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    } else if (searchText.trim() === "") {
      try {
        await dispatch(
          createApiAction(
            `/api/dev/admin/feedback/getAllFeedback?limit=${itemsPerPage}&page=${page}`,
            "GET"
          )
        ).then(() => {
          setLoading(false);
          setInitialLoading(false);
          setSearchPage(1);
        });
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    }
  };

  const columns = [
    {
      Header: "Image",
      accessor: "Image",
      width: "10%",
      align: "left",
      disableSortBy: true,
    },
    { Header: "Name", accessor: "Name", align: "center" },
    {
      Header: "Feedback",
      accessor: "Feedback",
      align: "center",
      disableSortBy: true,
    },
    // { Header: "Email", accessor: "Email", align: "center" },
    {
      Header: "Contact Number",
      accessor: "Mobile",
      align: "center",
      disableSortBy: true,
    },
    {
      Header: "Date",
      accessor: "date",
      align: "center",
      sortType: (rowA, rowB) => {
        const dateA = new Date(rowA.original.date);
        const dateB = new Date(rowB.original.date);
        return dateA - dateB;
      },
    },
   
    {
      Header: "Action",
      accessor: "Action",
      align: "center",
      sortType: (rowA, rowB) => {
        return rowA.original.statusValue - rowB.original.statusValue; 
      },
    },
  ];

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, page, feedbackData, searchPage]);

  useEffect(() => {
    handleApiCall();
  }, []);

  useEffect(() => {
    handleApiCall();
  }, [searchText, page, searchPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {!initailLoading ? (
                <MDBox>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                      p: 3,
                    }}
                  >
                    <MDBox
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: { xs: "center", sm: "flex-start" },
                        mb: { xs: 2, sm: 0 },
                      }}
                    >
                      <MDTypography variant="h5" color="white">
                        FEEDBACKS ({totalCount})
                      </MDTypography>
                    </MDBox>

                    <MDBox
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                        gap: { xs: 2, sm: 0 },
                        justifyContent: { xs: "center", sm: "flex-end" },
                      }}
                    >
                      <MDBox
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: { xs: "100%", sm: "auto" },
                          mb: { xs: 2, sm: 0 },
                        }}
                      >
                        <TextField
                          onChange={handleSearchChange}
                          id="standard-bare"
                          variant="outlined"
                          placeholder="Search Feedbacks"
                          sx={{
                            "& .MuiInputBase-input": {
                              color: "#ffffff",
                              width: { xs: "100%", sm: "240px" },
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton>
                                <SearchOutlined color="white" />
                              </IconButton>
                            ),
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <FeedbackDialog
                    open={dialogOpen}
                    handleClose={() => {
                      handleClose(currentFeedback);
                    }}
                    content={currentFeedback}
                  />
                  {rows?.length > 0 ? (
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                        mb={3}
                      >
                        <Pagination
                          count={Math.ceil(totalCount / itemsPerPage)}
                          page={searchText === "" ? page : searchPage}
                          onChange={(event, value) => {
                            {
                              searchText === ""
                                ? setPage(value)
                                : setSearchPage(value);
                            }
                          }}
                          color="primary"
                          shape="rounded"
                          size="large"
                          sx={{
                            "& .MuiPaginationItem-root": {
                              borderRadius: "8px",
                              border: "1px solid #ddd",
                              margin: "0 4px",
                              "&.Mui-selected": {
                                backgroundColor: "#5870FD",
                                color: "white",
                                border: "1px solid #5870FD",
                              },
                              "&:hover": {
                                backgroundColor: "#bb48bd",
                              },
                            },
                            "& .MuiPaginationItem-previousNext": {
                              borderRadius: "8px",
                              border: "1px solid #ddd",
                            },
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  ) : (
                    <MDBox
                      sx={{
                        display: "flex",
                        padding: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <MDTypography variant="caption" sx={{ fontSize: "20px" }}>
                        {/* { !loading &&"There are currently no Feedbacks present." }  */}
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              ) :  error?
              (<MDBox
                display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
              >
               <MDTypography>
                Error Occured
               </MDTypography>
              </MDBox>)
               : (<MDBox
                display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
              >
                <CircularProgress
                  sx={{ color: "purple" }}
                  size={54}
                />
              </MDBox>)}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Feedbacks;
