import { useState, useEffect } from "react";

import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";
import Checkbox from "@mui/material/Checkbox";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { createApiAction } from "store/ApiServices";
// Data
// import data from "layouts/dashboard/genre/data";
import EditIcon from "@mui/icons-material/Edit";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CircularProgress } from "@mui/material";

import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { Cancel as CancelIcon, SearchOutlined } from "@mui/icons-material";
import { purple } from "@mui/material/colors";

function HelpCenter() {
  const [menu, setMenu] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [loading, setLoading] = useState(true);
  const FaqData = useSelector((state) => state.apiData.data.data);
  const{error}=useSelector((state)=>state.apiData)
  const [rows, setRows] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const navigate = useNavigate();

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={baseUrl + image}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const [editData, setEditData] = useState({
    question_id: "",
    question: "",
    answer: "",
  });

  const handleEditOpen = (faq) => {
    setEditOpen(true);
    setEditData({
      question_id: faq.question_id,
      question: faq.question,
      answer: faq.answer,
    });
  };
  const handleEditClose = () => {
    console.log("editform called", editData);

    setEditOpen(false);
  };
  const handleEditChange = (e) => {
    setEditData({
      ...editData,
      [e.target.id]: e.target.value,
    });
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    // console.log("editsubmit called",editData);
    const newFormData = editData;

    try {
      const response = await axiosInstance.post(
        "/api/dev/admin/FAQ/editFAQ",
        newFormData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "FAQ Updated",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch(createApiAction(`/api/dev/admin/FAQ/getFAQsAdmin`, "GET"));
        setEditData({
          question_id: "",
          question: "",
          answer: "",
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
      });
      console.log("error is", e.message);
    }
    setButtonLoading(false);

    handleEditClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log(formData);
    setFormData({
      question: "",
      answer: "",
    });
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    console.log("submit called", formData);
    const newFormData = formData;

    try {
      const response = await axiosInstance.post(
        "/api/dev/admin/FAQ/addNewFAQ",
        newFormData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "FAQ Added",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch(createApiAction(`/api/dev/admin/FAQ/getFAQsAdmin`, "GET"));
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
      });
      console.log("error is", e.message);
    }
    setButtonLoading(false);

    handleClose();
  };

  const handleDelete = (questionID) => {
    const QuestionRemoveId = {
      question_id: questionID,
    };
    // console.log(QuestionRemoveId)
    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this FAQ?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/admin/FAQ/deleteFAQ", QuestionRemoveId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "FAQ Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(createApiAction(`/api/dev/admin/FAQ/getFAQsAdmin`, "GET"));
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "There was an error deleting this FAQ.",
            });
          });
      }
    });
  };

  const handleStatus = async (faqId) => {
    const FaqStatusId = {
      question_id: faqId,
    };

    try {
      await axiosInstance.post(
        "/api/dev/admin/FAQ/manageFAQStatus",
        FaqStatusId
      );
      await dispatch(createApiAction(`/api/dev/admin/FAQ/getFAQsAdmin`, "GET"));
    } catch (error) {
      console.error("Error changing Genre status:", error);
    }
  };

  const handleRows = () => {
    return FaqData.map((faq, index) => (
      <MDBox
      key={index}
      mb={2}
      px={{ xs: 1, sm: 2, md: 3 }}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <MDTypography
              variant="h6"
              fontWeight="bold"
              sx={{
                overflow: "auto",
                textAlign: { xs: "center", sm: "left" },
                mb: { xs: 1, sm: 0 },
              }}
              
            >
              {faq.question}
            </MDTypography>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "flex-end" },
                mt: { xs: 1, sm: 0 },
              }}
            >
             <Tooltip key={faq.question_id} title={"status"} placeholder="bottom">
             <IconButton  onClick={(e) => e.stopPropagation()}>
              <Switch
                checked={faq?.status}
                onChange={(e) => {
                  e.stopPropagation();
                  handleStatus(faq.question_id);
                }}
               
              />
              </IconButton>
              </Tooltip>
              <IconButton
                aria-label="edit"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <EditIcon
                  onClick={() => {
                    handleEditOpen(faq);
                  }}
                />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(faq.question_id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </MDBox>
          </MDBox>
        </AccordionSummary>
        <AccordionDetails>
          <MDTypography
            variant="body2"
            color="secondary"
            sx={{ overflow: "auto", textAlign: { xs: "center", sm: "left" } }}
          >
            {faq.answer}
          </MDTypography>
        </AccordionDetails>
      </Accordion>
    </MDBox>
    
    ));
  };
  const handleApiCall = async () => {
    try {
      dispatch(createApiAction(`/api/dev/admin/FAQ/getFAQsAdmin`, "GET")).then(
        () => setLoading(false)
      );
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, FaqData]);

  useEffect(() => {
    handleApiCall();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {!loading?(
                    <MDBox>
                    <MDBox
                      mx={2}
                      // mt={-3}
                      // py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      sx={{
                        backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                    >
                      <MDBox>
                        <MDTypography variant="h5" color="white">
                          Help Center / FAQ
                        </MDTypography>
                      </MDBox>
                      <MDTypography>
                        <MDButton
                          variant="contained"
                          size="small"
                          sx={{ color: "black",
                            marginLeft: { xs: 0, md: "12px" },
                            marginRight: { xs: 0, md: "12px" },
                            color: "black",
                            paddingBottom: "13px",
                            paddingTop: "13px",
                            width: { xs: "100%", md: "auto" },}}
                            fullWidth
                          onClick={handleOpen}
                        >
                          Add FAQ
                        </MDButton>
                      </MDTypography>
                      {/* Add Faq */}
                      <Dialog open={open} onClose={handleClose}  maxWidth="sm"  fullWidth>
                        <DialogTitle>
                          Add FAQ
                          <span
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              padding: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <CancelIcon
                              sx={{ color: purple[500] }}
                              onClick={handleClose}
                            />
                          </span>
                        </DialogTitle>
                        <DialogContent style={{ overflowX: "hidden" }}>
                          <form onSubmit={handleSubmit}>
                            <TextField
                              autoFocus
                              margin="dense"
                              id="question"
                              name="question"
                              label="Add Question "
                              type="text"
                              required
                              fullWidth
                              value={formData.question}
                              onChange={handleChange}
                            />
        
                            <TextField
                              placeholder="Add Answer"
                              margin="dense"
                              id="answer"
                              name="answer"
                              label="Add Answer"
                              type="text"
                              required
                              fullWidth
                              value={formData.answer}
                              onChange={handleChange}
                              variant="outlined"
                              multiline
                              rows={6}
                            />
        
                            <Button
                              type="submit"
                              sx={{
                                backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                                marginRight: "12px",
                                marginTop: "10px",
                              }}
                              fullWidth
                            >
                              {buttonLoading ? (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  width="100%"
                                >
                                  <CircularProgress
                                    sx={{ color: "#ffffff" }}
                                    size={24}
                                  />
                                </MDBox>
                              ) : (
                                <MDTypography
                                  style={{ fontSize: "13.5px", fontWeight: "bold" }}
                                  color="white"
                                >
                                  Submit
                                </MDTypography>
                              )}
                            </Button>
                          </form>
                        </DialogContent>
                      </Dialog>
                      {/* Edit Policy */}
                      <Dialog open={editOpen} onClose={handleEditClose} maxWidth="sm"  fullWidth>
                        <DialogTitle>
                          Edit FAQ
                          <span
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              padding: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <CancelIcon
                              sx={{ color: purple[500] }}
                              onClick={handleEditClose}
                            />
                          </span>
                        </DialogTitle>
                        <DialogContent style={{ overflowX: "hidden" }}>
                          <form onSubmit={handleEditSubmit}>
                            <TextField
                              autoFocus
                              margin="dense"
                              id="question"
                              name="question"
                              label="Edit Question "
                              type="text"
                              required
                              fullWidth
                              value={editData.question}
                              onChange={handleEditChange}
                            />
        
                            <TextField
                              placeholder="Edit Answer"
                              margin="dense"
                              id="answer"
                              name="answer"
                              label="Add Answer"
                              type="text"
                              required
                              fullWidth
                              value={editData.answer}
                              onChange={handleEditChange}
                              variant="outlined"
                              multiline
                              rows={6}
                            />
        
                            <Button
                              type="submit"
                              sx={{
                                backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                                marginRight: "12px",
                                marginTop: "10px",
                              }}
                              fullWidth
                            >
                              {buttonLoading ? (
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  width="100%"
                                >
                                  <CircularProgress
                                    sx={{ color: "#ffffff" }}
                                    size={24}
                                  />
                                </MDBox>
                              ) : (
                                <MDTypography
                                  style={{ fontSize: "13.5px", fontWeight: "bold" }}
                                  color="white"
                                >
                                  Submit
                                </MDTypography>
                              )}
                            </Button>
                          </form>
                        </DialogContent>
                      </Dialog>
                    </MDBox>
                    <MDBox pt={3}>
                    
                      {!loading && handleRows()}
                    </MDBox>
                    </MDBox>
            )
            :
            error?
            (<MDBox
              display="flex"
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
        position="fixed"
        top={0}
        left={0}
            >
             <MDTypography>
              Error Occured
             </MDTypography>
            </MDBox>)
             : (<MDBox
              display="flex"
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
        position="fixed"
        top={0}
        left={0}
            >
              <CircularProgress
                sx={{ color: "purple" }}
                size={54}
              />
            </MDBox>)
            }
      
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default HelpCenter;
