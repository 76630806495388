
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { createApiAction } from "store/ApiServices";
// Data
import { CircularProgress } from "@mui/material";

// Dashboard components
import Projects from "./Projects";
import OrdersOverview from "./OrdersOverview";
import { useDispatch, useSelector } from "react-redux";
import { useEffect,useCallback, useState } from "react";

//swiper
import { Virtual } from 'swiper/modules';
import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import bannerImage from "assets/images/banner-image.jpg"
import MDTypography from "components/MDTypography";
import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";
import noCoverImage from "assets/images/noCoverImage.png"

function Banners() {

  const dispatch=useDispatch()
  // const[bannerData,setBannerData]=useState([])
  const bannerData=useSelector((state)=>state.apiData.data)
 const[loading,setLoading]=useState(true)
 const {error}=useSelector((state)=>state.apiData)
  const handleApiCall =  () => {
    try {
      dispatch(createApiAction("/api/dev/banner/getAllBanners", "GET")).then(() => setLoading(false));
     
    } catch (e) {
      console.log(e);
    }
  }

  useEffect( () => {
    handleApiCall();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
     
      { !loading ? 
     ( <MDBox>
       <MDBox py={3}>
       
         <Grid container spacing={4}>
      <Grid item xs={12} md={7} lg={7}>
      <MDBox mb={4.5} mt={1.3} sx={{ height: '392px', width: '100%' }}>
  {bannerData?.data && bannerData.data.length > 0 && bannerData.data.some(slide => slide.status) ? (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4500,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      style={{ borderRadius: '16px' }}
      className="mySwiper"
    >
      {bannerData.data
        .filter(slide => slide.status)
        .map((slide, index) => (
          <SwiperSlide key={slide.image_url} virtualIndex={index}>
            <img src={slide.image_url?baseUrl + slide.image_url:noCoverImage} alt={`Slide ${index + 1}`} style={{ width: '100%', borderRadius: '16px' }} />
          </SwiperSlide>
        ))}
    </Swiper>
  ) : (
    <MDBox sx={{ display: 'flex', padding: '20px', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <MDTypography variant="caption" sx={{ fontSize: "20px" }}>
        No active banners are available.
      </MDTypography>
    </MDBox>
  )}
</MDBox>

      </Grid>
      <Grid item xs={12} md={5} lg={5}>
        <MDBox sx={{ height: '400px', width: '100%' }} mt={1.3}>
          <OrdersOverview />
        </MDBox>
      </Grid>
    </Grid>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Projects />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      </MDBox>):
      error?
      (<MDBox
        display="flex"
  justifyContent="center"
  alignItems="center"
  width="100vw"
  height="100vh"
  position="fixed"
  top={0}
  left={0}
      >
       <MDTypography>
        Error Occured
       </MDTypography>
      </MDBox>)
       : (<MDBox
        display="flex"
  justifyContent="center"
  alignItems="center"
  width="100vw"
  height="100vh"
  position="fixed"
  top={0}
  left={0}
      >
        <CircularProgress
          sx={{ color: "purple" }}
          size={54}
        />
      </MDBox>)
      
      }
     </DashboardLayout>
  );
}

export default Banners;
