import { useState,useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import MDButton from "components/MDButton";
import { baseUrl } from "base";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { createApiAction } from "store/ApiServices";
import { useDispatch, useSelector } from "react-redux";
// Data
import axiosInstance from "axiosInstance";
import profileDefault from "assets/images/Profile-default.png"
import noCoverImage from "assets/images/noCoverImage.png"

function OrdersOverview() {

  const [menu, setMenu] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const dispatch=useDispatch()
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const AllBanner=useSelector((state)=>state.apiData.data)
  const [rows, setRows] = useState([]);

  const avatars = (members) =>
  members.map(([image, name]) => (
    <Tooltip key={name} title={name} placeholder="bottom" >
  <MDBox
    component="img"
    src={image ? baseUrl + image : noCoverImage}
    alt={name}
    sx={{
      width: "80px",  
      height: "40px",   
      objectFit: "cover",  
      borderRadius:"5px",
      border: ({ borders: { borderWidth }, palette: { white } }) =>
        `${borderWidth[2]} solid ${white.main}`,
      position: "relative",

      "&:not(:first-of-type)": {
        ml: -1.25,
      },

      "&:hover, &:focus": {
        zIndex: "10",
      },
    }}
  />
</Tooltip>
  ));


  const handleApiCall = async () => {
    try {
      const response = await axiosInstance.get("/api/dev/banner/getBanners")
      const rowsAPI = response.data.data?.map((banner) => ({
        Image: (
          <MDBox display="flex" py={1}>
          {avatars([[banner.image_url, banner.name]])}
        </MDBox>
        ),
        Title: banner.title,
     
        Action: (
          <MDBox width="8rem" textAlign="left" ml={7}>

          <MDButton
            onClick={()=>{}}
            sx={{
              backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
            }}
            size="small" 
          >
            <MDTypography style={{ fontSize: '13.5px' , fontWeight: 'bold' }}  color="white">
            Remove
            </MDTypography>
          </MDButton>
  
          </MDBox>
        ),
      }));

      setRows(rowsAPI);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleStatus=async(banner)=>{
    const BannerId={
      "banner_id":banner.banner_id
  }

  try {
    await axiosInstance.post("/api/dev/banner/changeStatus", BannerId);
    await dispatch(createApiAction("/api/dev/banner/getAllBanners", "GET"));
  } catch (error) {
    console.error("Error changing banner status:", error);
  }
  }
  const handleSetTable = () => {
    const filteredData = AllBanner?.data?.filter((data) => data.status === true);
    const rowsAPI = filteredData?.map((banner) => ({
      key: banner.banner_id,  // Add key here
      Image: (
        <MDBox display="flex" py={1} key={banner.banner_id}>
          {avatars([[banner.image_url, banner.name]])}
        </MDBox>
      ),
      Title: banner.title,
      Action: (
        <MDBox key={banner.banner_id}>
          <MDButton
            onClick={() => {
              handleStatus(banner);
            }}
            sx={{
              backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
            }}
            size="small"
          >
            <MDTypography
              style={{ fontSize: '13.5px', fontWeight: 'bold' }}
              color="white"
              key={banner.banner_id}
            >
              Remove
            </MDTypography>
          </MDButton>
        </MDBox>
      ),
    }));
  
    setRows(rowsAPI);
  };
  
  const columns = [
    { Header: "Image", accessor: "Image", width: "10%", align: "left" , disableSortBy:true},
      { Header: "Title", accessor: "Title", align: "center" },
      { Header: "Action", accessor: "Action", align: "center", disableSortBy:true },
  ];

  useEffect(() => {

    handleSetTable();
   }, [AllBanner]);


  return (
    <Card  >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} xs={12} md={6} lg={4} >
        <MDBox>
          <MDTypography variant="h5" gutterBottom>
          { `Active Banners (${rows?.length?rows.length:"0"})`}
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
          </MDBox>
        </MDBox>
     
      </MDBox>
      <MDBox sx={{overflow:"auto", height:"310px"}}>
       {rows?.length>0 ? <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={true}
          noEndBorder
          entriesPerPage={false}
        />
        :(<MDBox sx={{display:'flex',padding:'20px',justifyContent:'center'}}>
                  <MDTypography variant="caption" sx={{fontSize:"20px"}}>
                     No active banners are available.
               </MDTypography>
               </MDBox>)}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
