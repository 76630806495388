import { useState } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpg";
import { LoginDetail } from "LoginDetail";
import axios from "axios";
import { addUser } from "store/UserData";
import { useDispatch, useSelector } from "react-redux";
import styledCompo from "../style.module.css";
import { createApiAction } from "store/ApiServices";
import { TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Checkmark } from "react-checkmark";

import apiData from "store/apislice";
import axiosInstance from "axiosInstance";
function ForgotPassword() {
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [user, setUser] = useState({ email: "mita.si@cisinlabs.com" });
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { loading, success } = useSelector((state) => state.apiData);
  const [confirmation, setConfirmation] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    if (error) {
      setError(false);
    }
    const { id, value } = e.target;
    setUser({
      ...user,
      [id]: value,
    });
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
    if (id === "email") {
      setEmailError(!emailPattern.test(value));
    }
  };

  const CallApi = async () => {
    try {
      const response = await axiosInstance.post(
        "/api/dev/admin/CP/cpForgetPassword",
        user
      );

      if (response.code === 200) {
        setConfirmation(true);
      } else if (response?.data?.success) {
        setConfirmation(true);
      } else {
        setError(response.message);
      }
      setButtonLoading(false);
    } catch (e) {
      console.log(e?.response?.data?.message);
      setError(e?.response?.data?.message || "Error Occured");
      setButtonLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    let formValid = true;
    setError("");

    if (!user.email) {
      setError("Email Required");
      formValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)) {
      setError("Invalid email address");
      formValid = false;
      setButtonLoading(false);
    }

    if (formValid) {
      try {
        CallApi();
      } catch (error) {
        setError("Error occurred while logging in.");
        setButtonLoading(false);
      }
    }
  };

  return (
    <div className={styledCompo.bg_style}>
      <BasicLayout image={""}>
        <Card>
          <MDBox
            variant="contained"
            bgColor="#0E4C75"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              BEATZ
            </MDTypography>
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Forgot Password
            </MDTypography>
          </MDBox>
          {confirmation ? (
            <MDBox pt={4} pb={3} px={3}>
              <div>
                {error && (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{
                      fontSize: "0.6em",
                      padding: "0.5em",
                      borderRadius: "0.25em",
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </div>
                )}
              </div>
              <MDBox component="form" role="form">
                <MDBox>
                  <TextField
                    onChange={handleChange}
                    type="email"
                    label="Enter Your Email"
                    id="email"
                    value={user.id}
                    fullWidth
                    error={emailError}
                    helperText={emailError ? "Please enter a valid email" : ""}
                  />
                </MDBox>

                <MDBox mt={2} mb={1}>
                  <MDButton
                    variant="contained"
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      color: "white",
                      "&:hover": {
                        backgroundImage: `linear-gradient(90deg, #4d5cc9 0%, #d156a8 100%);
                    `,
                      },
                    }}
                    fullWidth
                    disabled={emailError || !user.email}
                    onClick={handleSubmit}
                  >
                    {buttonLoading ? (
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <CircularProgress sx={{ color: "#ffffff" }} size={24} />
                      </MDBox>
                    ) : (
                      <MDTypography variant="h6" color="white">
                        Submit
                      </MDTypography>
                    )}
                  </MDButton>
                </MDBox>
                <MDBox mt={1.5} textAlign="center">
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          ) : (
            <MDBox pt={4} pb={3} px={3}>
              <Checkmark size="xLarge" />
              <MDTypography
                variant="h5"
                color="success.main"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: 3,
                }}
              >
                Email sent successfully
              </MDTypography>
              <MDTypography
                color="success.main"
                sx={{
                  textAlign: "center",
                  mt: 2,
                  fontSize: "13px",
                }}
              >
                {`We've sent an email to `}
                <MDBox component="span" sx={{ fontWeight: "bold" }}>
                  {user.email.replace(/(.{2}).*(@.*)/, "$1***$2")}
                </MDBox>
                {` with a link to reset your password.`}
              </MDTypography>
              <MDTypography
              mt={1}
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                  color: "text.secondary",
                  opacity: 0.7,
                }}
              >
                You may now close this page.
              </MDTypography>
            </MDBox>
          )}
        </Card>
      </BasicLayout>
    </div>
  );
}

export default ForgotPassword;
