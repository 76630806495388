import { useState, useEffect } from "react";

import { useNavigate } from "react-router";
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";
import Checkbox from '@mui/material/Checkbox';
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import DeleteIcon from '@mui/icons-material/Delete';
import { capitalizeFirstLetter } from "examples/CommonFunctions";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CircularProgress } from "@mui/material";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { createApiAction } from "store/ApiServices";
// Data
import EditIcon from '@mui/icons-material/Edit';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import { IconButton } from "@mui/material";
import { Cancel as CancelIcon, SearchOutlined } from '@mui/icons-material';
import { purple } from '@mui/material/colors';
import profileDefault from "assets/images/Profile-default.png";

function Genre() {

  const [menu, setMenu] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch()
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [loading, setLoading] = useState(true)
  const GenreData = useSelector((state) => state.apiData.data.data)
  const {error}= useSelector((state)=>state.apiData)
  const [rows, setRows] = useState([]);
  const [editOpen, setEditOpen] = useState(false)
  const [imagePreview, setImagePreview] = useState(null);
  const [editGenreImage,setEditGenreImage]= useState(null)
  const [buttonLoading, setButtonLoading] = useState(false);
  
  const navigate = useNavigate()

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    genre_title: "",
    genre_image: ""
  });

  const [editData, setEditData] = useState({
    genre_id: "",
    genre_title: "",
    genre_image: ""
  })
  const handleEditOpen = (genre) => {
    setEditOpen(true)
    setEditData({
      genre_id: genre.genre_id,
      genre_title: genre.genre_title,
      genre_image: null
    })
    setEditGenreImage(baseUrl+genre.genre_image_url)
  }
  const handleEditClose = () => {
    console.log("editform called", editData);
    setEditGenreImage(null)
    setImagePreview(null)
    setEditOpen(false);
   
  };
  const handleEditChange = (e) => {
    setEditData({
      ...editData,
      [e.target.id]: e.target.value
    });
  }

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true)
    const newFormData = new FormData();
    newFormData.append('genre_title', editData.genre_title);
    if(editData.genre_image!==null){
      newFormData.append('genre_image', editData.genre_image);
    }
    newFormData.append('genre_id', editData.genre_id);

    try {
      const response = await axiosInstance.post("/api/dev/admin/genre/updateGenre", newFormData);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: "Genre Updated",
          timer: 1500,
          showConfirmButton: false
        });
        dispatch(createApiAction("/api/dev/admin/genre/getAllGenres", "GET"));
        setEditData({
          genre_id: "",
          genre_title: "",
          genre_image: ""
        })
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: "Error Occurred",
      });
      console.log("error is", e.message);
    }
    setButtonLoading(false)
    handleEditClose();
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // console.log(formData);
    setOpen(false);
    setFormData({
      genre_title: "",
      genre_image: ""
    })
    setImagePreview(null)
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true)
    const newFormData = new FormData();
    newFormData.append('genre_title', formData.genre_title);
    newFormData.append('genre_image', formData.genre_image);

    console.log("submit called", formData);

    try {
      const response = await axiosInstance.post("/api/dev/admin/genre/createGenre", newFormData);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: "Genre Created",
          timer: 1500,
          showConfirmButton: false
        });
        dispatch(createApiAction("/api/dev/admin/genre/getAllGenres", "GET"));
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: "Error Occurred",
      });
      console.log("error is", e.message);
    }
    setButtonLoading(false)
    handleClose();
  };
  const handleFileInputChange = (event) => {
    const { id } = event.target
    const file = event.target.files[0];
    if(file){
      setFormData({ ...formData, [id]: file });
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }

  };

  const handleEditFile = (event) => {
    const { id } = event.target
    const file = event.target.files[0];
    if(file){
      setEditData({ ...editData, [id]: file });
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }

  }

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image?baseUrl+image:profileDefault}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));


  const handleGenreRemove = (removeGenre) => {
    const GenreRemoveId = {
      "genre_id": removeGenre
    };
    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this Genre?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.post("/api/dev/admin/genre/deleteGenre", GenreRemoveId)
          .then(response => {
            Swal.fire({
              icon: 'success',
              title: "Genre Deleted",
              timer: 1500,
              showConfirmButton: false 
            });
            dispatch(createApiAction("/api/dev/admin/genre/getAllGenres", "GET"))
          })
          .catch(error => {
            console.error("error!", error.message);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'There was an error removing this genre.'
            });
          });
      }
    });
  };

  const handleStatus = async (genre) => {
    const genreID = {
      "genre_id": genre.genre_id
    }

    try {
      await axiosInstance.post("/api/dev/admin/genre/changeGenreStatus", genreID);
      await dispatch(createApiAction("/api/dev/admin/genre/getAllGenres", "GET"))
    } catch (error) {
      console.error("Error changing Genre status:", error);
    }
  }

  const handleRows = () => {
    if(GenreData?.length>0){
      const rowsAPI = GenreData?.map((genre) => ({
        Image: (
          <MDBox display="flex" py={1}>
            {avatars([[genre?.genre_image_url, genre.genre_title]])}
          </MDBox>
        ),
        Title: capitalizeFirstLetter(genre.genre_title),
  
        Status: (
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <Switch checked={genre.status} onChange={() => { handleStatus(genre) }} />
            </MDBox>
          </MDBox>
        ),
  
        Action: (
          <MDBox>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => { handleEditOpen(genre) }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => {
                handleGenreRemove(genre.genre_id)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </MDBox>
        ),
        statusValue:genre.status?1:0,
      }));
      setRows(rowsAPI);
    }
  
   
  }
  const handleApiCall = async () => {
    try {
      dispatch(createApiAction("/api/dev/admin/genre/getAllGenres", "GET")).then(() => setLoading(false))

    } catch (error) {
      setLoading(false)
      console.log("Error fetching data:", error);
    }
  };

  const columns = [
    { Header: "Image", accessor: "Image", width: "10%", align: "center" , disableSortBy: true},
    { Header: "Title", accessor: "Title", align: "center" },
    { Header: "Status", accessor: "Status", align: "center" , 
      sortType:(rowA,rowB)=>{
        const item1=rowA.original.statusValue;
        const item2= rowB.original.statusValue;
        return item1-item2;
      }
    },
    { Header: "Action", accessor: "Action", align: "center" , disableSortBy: true},

  ]

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, GenreData])

  useEffect(() => { handleApiCall() }, [])

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {!loading ?
              (<MDBox>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                  }}
                  display="flex" justifyContent="space-between" alignItems="center" p={3}
                >
                  <MDBox>
                    <MDTypography variant="h5" color="white">
                      GENRES ({rows?.length})
                    </MDTypography>
                  </MDBox>
            
                  <MDTypography>
                    <MDButton variant="contained"
                      size="small"
                      sx={{ color: "black",
                      marginRight: { xs: 0, md: "12px" },
                      color: "black",
                      paddingBottom: "13px",
                      paddingTop: "13px",
                      width: { xs: "100%", md: "auto" },}}
                      fullWidth
                      onClick={handleOpen} >
                      Add New Genre
                    </MDButton>
                  </MDTypography>
                  {/* Add  */}
                  <Dialog open={open} onClose={handleClose}  maxWidth="sm"  fullWidth >
                    <DialogTitle >
                      Add New Genre
                      <span
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: '10px',
                          cursor: 'pointer'
                        }}
                      >
                        <CancelIcon sx={{ color: purple[500] }} onClick={handleClose} />
                      </span>
                      {imagePreview && (
                <MDBox height="300px" width="100%">
                  <img
                    src={imagePreview}
                    alt="Genre Image"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MDBox>
              )}
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "auto"}}>
                      <form onSubmit={handleSubmit}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="genre_title"
                          name="genre_title"
                          label="Add Title "
                          type="text"
                          required
                          fullWidth
                          value={formData.genre_title}
                          onChange={handleChange}
                          inputProps={{ maxLength: 25 }}
                        helperText={formData.genre_title.length === 25 ? "25 character limit reached" : ""}
                        FormHelperTextProps={{
                          sx: { color: "red" },
                        }}
                        />
  
                        <label htmlFor="genre_image" style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          border: '1px solid #ccc',
                          padding: '2px',
                          marginTop: '7px',
  
                          borderRadius: '5px',
                        }}>
                          <input
                            required={!formData.genre_image}
                            type="file"
                            id="genre_image"
                            accept="image/*"
                            onChange={handleFileInputChange}
                            style={{
                              position: "absolute",
                              width: "1px",
                              height: "1px",
                              opacity: 0,
                            }}
                          />
                          <Button component="span" color="primary" fullWidth>
                            Add Image
                          </Button>
                          <span  style={{ textAlign: 'center', width: '100%', marginTop: '1px' }}>
                            {formData.genre_image?.name}
                          </span>
                        </label>
  
                        <Button
                          type="submit"
                          sx={{
                            backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                            marginRight: "12px",
                            marginTop: '10px'
                          }}
                          fullWidth
                        >
                         {buttonLoading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                  <CircularProgress sx={{color:"#ffffff"}} size={24} />
                </MDBox>
                ) : (
                  <MDTypography
                    style={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Submit
                  </MDTypography>
                )}
  
                        </Button>
                      </form>
                    </DialogContent>
  
  
                  </Dialog>
                  {/* Edit */}
                  <Dialog open={editOpen} onClose={handleEditClose} maxWidth="sm" fullWidth sx={{overflowX:"hidden"}} >
                    <DialogTitle >
                      Edit Genre
                      <span
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: '10px',
                          cursor: 'pointer'
                        }}
                      >
                        <CancelIcon sx={{ color: purple[500] }} onClick={handleEditClose} />
                      </span>
                      {editGenreImage && (
                <MDBox height="300px" width="100%">
                  <img
                    src={imagePreview?imagePreview:editGenreImage}
                    alt="Genre Image"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MDBox>
              )}
                    </DialogTitle>
                    <DialogContent sx={{overflowX:"hidden"}} >
                      <form onSubmit={handleEditSubmit}>
                      <label htmlFor="genre_image" style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          border: '1px solid #ccc',
                          padding: '2px',
                          marginTop: '2px',
                         marginBottom:"4px",
                          borderRadius: '5px',
                        }}>
                          <input
                            type="file"
                            id="genre_image"
                            accept="image/*"
                            onChange={handleEditFile}
                            style={{ display: 'none' }}
                          />
                          <Button component="span" color="primary" fullWidth>
                            Edit Image
                          </Button>
                          <span style={{ textAlign: 'center', width: '100%', marginTop: '1px' }}>
                            {editData.genre_image?.name}
                          </span>
                        </label>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="genre_title"
                          name="genre_title"
                          label="Edit Title "
                          type="text"
                          fullWidth
                          value={editData.genre_title}
                          onChange={handleEditChange}
                          inputProps={{ maxLength: 25 }}
                          helperText={editData.genre_title.length === 25 ? "25 character limit reached" : ""}
                          FormHelperTextProps={{
                            sx: { color: "red" },
                          }}
                        />
  
                    
                        <Button
                          type="submit"
                          sx={{
                            backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                            marginTop: '7px'
                          }}
                          fullWidth
                        >
                       {buttonLoading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                  <CircularProgress sx={{color:"#ffffff"}} size={24} />
                </MDBox>
                ) : (
                  <MDTypography
                    style={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Submit
                  </MDTypography>
                )}
  
                        </Button>
                      </form>
                    </DialogContent>
                  </Dialog>
                </MDBox>
                <MDBox pt={3}>
             
                {rows?.length > 0 ?<DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    isPagination={true}
                  />:
                  (<MDBox sx={{display:'flex',padding:'20px',justifyContent:'center'}}>
                    <MDTypography variant="caption" sx={{fontSize:"20px"}}>
                   {/* { !loading &&"There are currently no Genres present." }  */}
                    </MDTypography>
                 </MDBox>)}
                </MDBox>
                </MDBox>):
                !error?
              (
                <MDBox
                display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
              >
                <CircularProgress
                  sx={{ color: "purple" }}
                  size={54}
                />
              </MDBox>
              ):(
                <MDBox
        display="flex"
  justifyContent="center"
  alignItems="center"
  width="100vw"
  height="100vh"
  position="fixed"
  top={0}
  left={0}
      >
       <MDTypography>
        Error Occured
       </MDTypography>
      </MDBox>
              )
        }
              
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Genre;
