/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
// ReportsBarChart configurations
import Configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ReportsBarChart({ color, title, description, chart }) {
  const { data, options } = Configs(chart.labels || [], chart.datasets || {});
  const navigate = useNavigate();

  const handleClick = (event) => {
    const chartInstance = event.chart;
    const elements = chartInstance.getElementsAtEventForMode(
      event.native,
      "nearest",
      { intersect: true },
      true
    );

    if (elements && elements.length > 0) {
      const chartIndex = elements[0].index;
      const clickedCpId = chartInstance.data.datasets[0].cpId[chartIndex]; 
      navigate(`/CP/${clickedCpId}`);
    } else {
      console.log("No bar was clicked");
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} pb={1} px={1} ml={2}>
        <MDTypography variant="h5" textTransform="capitalize">
          {title}
        </MDTypography>
        <MDTypography
          component="div"
          variant="button"
          color="text"
          fontWeight="light"
        >
          {description}
        </MDTypography>
      </MDBox>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor="white" //to change the bg color
              borderRadius="lg"
              coloredShadow="secondary"
              py={2}
              pr={0.5}
              // mt={-5}
              height="16.6rem"
            >
              <Bar
                data={data}
                options={{
                  ...options,
                  onClick: handleClick,
                }}
                redraw
              />
            </MDBox>
          ),
          [color, chart]
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string,
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
};

export default ReportsBarChart;
