// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import { useContext, useEffect, useState } from "react";
// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { UserContext } from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { createApiAction } from "store/ApiServices";
import { baseUrl } from "base";
import Swal from "sweetalert2";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { formatDate } from "examples/Date";

function Overview() {
  const dispatch = useDispatch();
  const [user1, setUser] = useState([]);
  const user = useSelector((state) => state.apiData.data.data);
  const navigate = useNavigate();
  const { id } = useParams();
  const userId = {
    artist_id: id,
  };

 
  const handleApiCall = async () => {
    try {
      await dispatch(
        createApiAction("/api/dev/artist/getArtistById", "POST", userId)
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleApiCall();
  }, []);

  const handleStatus = (songId, isBan) => {
    const BanSongId = {
      song_id: songId,
    };

    const status = isBan ? "Enable" : "Disable";
    Swal.fire({
      icon: "warning",
      title: ` Do you want to ${status} this song?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/songs/songBaned", BanSongId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(
              createApiAction("/api/dev/artist/getArtistById", "POST", userId)
            );
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({ icon: "error", title: "Error!" });
          });
      }
    });
  };

  const InfoRow = ({ label, value }) => {
    const isEmail = label=== "Email";
  
    return (
      <MDBox>
        <MDTypography
          variant="button"
          fontWeight="bold"
          textTransform={isEmail ? "none" : "capitalize"}
          mr={1}
        >
          {label}:
        </MDTypography>
        {isEmail ? (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="info"
            component="a"
            href={`mailto:${value}`}
          >
            {value || "NA"}
          </MDTypography>
        ) : (
          <MDTypography variant="button" fontWeight="regular" color="text">
            {value || "NA"}
          </MDTypography>
        )}
      </MDBox>
    );
  };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header user={user} setUser={setUser}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <InfoRow label="fullName" value={user?.name} />
              <InfoRow label="Email" value={user?.email} />

              <InfoRow
                label="Monthly Listeners"
                value={user?.monthly_listners}
              />
              <InfoRow label="mobile" value={user?.mobile} />
              <MDBox>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mr={1}
                >
                  DOB:
                </MDTypography>
                <MDTypography
                  variant="button"
                  textTransform="capitalize"
                  fontWeight="regular"
                  color="text"
                >
                  {user.DOB ? formatDate(user.DOB) : "NA"}
                </MDTypography>
              </MDBox>
              <InfoRow label="gender" value={user?.gender} />
              <InfoRow label="country" value={user?.country} />

               <MDBox>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mr={1}
                >
                  Joined On:
                </MDTypography>
                <MDTypography
                  variant="button"
                  textTransform="capitalize"
                  fontWeight="regular"
                  color="text"
                >
                  {user.createdAt ? formatDate(user.createdAt) : "NA"}
                </MDTypography>
              </MDBox>

              <MDBox ml={2}>
                <MDBox></MDBox>

              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings user={user} />
            </Grid>

            <Grid
              item
              xs={12}
              xl={4}
              sx={{ height: "400px", overflow: "auto" }}
            >
              <MDBox
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" fontWeight="medium">
                  Songs
                </MDTypography>
                {user?.songs?.length > 0 && (
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/artistSongs")}
                    mr={14}
                  >
                    View All
                  </MDTypography>
                )}
              </MDBox>
              {user?.songs?.length > 0 ? (
                user?.songs.map((song) => (
                  <MDBox
                    key={song.song_id}
                    component="li"
                    display="flex"
                    alignItems="center"
                    py={1}
                    mb={1}
                  >
                    <MDBox mr={2}>
                      <MDAvatar
                        src={baseUrl + song.cover_image_url}
                        alt="something here"
                        shadow="md"
                      />
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="medium"
                        style={{ color: song.is_ban ? "red" : "inherit" }}
                      >
                        {song.song_title}
                      </MDTypography>
                      <MDTypography variant="caption" color="text">
                        {`Listen Count: ${song.listen_count}`}
                      </MDTypography>
                    </MDBox>
                    <MDBox ml="auto" mr={12}>
                      <MDButton
                        variant="text"
                        color="info"
                        onClick={() => {
                          handleStatus(song.song_id, song.is_ban);
                        }}
                        style={{ color: song.is_ban ? "red" : "inherit" }}
                      >
                        {song.is_ban ? "Enable" : "Disable"}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                ))
              ) : (
                <MDTypography variant="h6" color="text">
                  No Songs Yet
                </MDTypography>
              )}
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
        {/* <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="project #2"
                title="modern"
                description="As Uber works through a huge amount of internal management turmoil."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor2}
                label="project #1"
                title="scandinavian"
                description="Music is something that everyone has their own specific opinion about."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox> */}
      </Header>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
