import { useState, useEffect, createContext } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Box from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { baseUrl } from "base";
// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import pianoKeys from "assets/images/piano-keys.webp";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import apiData from "store/apislice";
import { createApiAction } from "store/ApiServices";
import axiosInstance from "axiosInstance";
import profileDefault from "assets/images/Profile-default.png";
import Swal from "sweetalert2";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { CircularProgress } from "@mui/material";
import { Chip } from '@mui/material';

function Header({ children }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const dispatch = useDispatch();
  const playlist = useSelector(
    (state) => state.apiData.data.data.playlistDetails
  );
  const { totalCount } = useSelector((state) => state.apiData.data.data);
  const { id } = useParams();
  const userId = {
    artist_id: id,
  };
  const [buttonLoading, setButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [songData, setSongData] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [artistName, setArtistName] = useState("");
  const [isAutocompleteError, setAutocompleteError] = useState(true);
  const [formData, setFormData] = useState({
    song_id:[]
    // song_id: "",
    // song_title: "",
  });
  const [editData, setEditData] = useState({
    title: "",
    playlist_type: "",
    image: "",
  });

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
    setEditData({
      title: playlist.title,
      playlist_type: playlist.playlist_type,
      image: "",
    });
  };
  const handleClose = () => {
    console.log(formData);
    setFormData({
      // song_id: "", 
      song_id:[]
    });  
    setAutocompleteError(true)
    setOpen(false);
  };

  const handleEditClose = () => {
    console.log("formData", editData);
    setImagePreview(null);
    setEditOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSongChange = (event, newValue) => {
    const songIds = newValue.map(song => song.song_id);
    console.log(songIds)
    if (newValue.length === 0) {
      setAutocompleteError(true);
    } else {
      setAutocompleteError(false);
    }
    setFormData({
      ...formData,
      // song_title: newValue ? newValue.song_title : "",
      // song_id: newValue ? newValue.song_id : "",
      song_id:songIds?songIds:[]
    });
  };

  const handleEditChange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setEditData({ ...editData, image: file });
    if(file){
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
    
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    console.log("formData",formData)
    const newFormData = {
      playlist_id: playlist.playlist_id,
      song_id: formData.song_id,
    };
    const checkSong = playlist?.playlist_songs?.find(
      (song) => song.song_id === formData.song_id
    );
    // if (checkSong) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Song Already Present",
    //     timer: 2500,
    //   });
    // } else {
      try {
        const playlistID = { playlist_id: playlist.playlist_id };
        const response = await axiosInstance.post(
          "/api/dev/playlist/addSongInPlaylist",
          newFormData
        );
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title:  formData.song_id.length>1?"Songs Added":"Song Added" ,
            timer: 1500,
            showConfirmButton: false,
          });

          dispatch(
            createApiAction(
              "/api/dev/playlist/getPlaylistWithSongsById",
              "POST",
              playlistID
            )
          );
          setFormData({
            song_id: [],
            // song_title: "",
          });
        }
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: "Error Occurred",
        });
      }
    // }
    setFormData({
              // song_id: "",
              song_id:[]
            });
    setButtonLoading(false);
    handleClose();
  };


  function getAvailableSongs(allSongs, playlistSongs) {
    const playlistSongIds = new Set(
      playlistSongs.flatMap((playlist) =>
        playlist.songs.map((song) => song.song_id)
      )
    );
    if (playlist.playlist_type === "album") {
      return allSongs.filter(
        (song) =>
          !playlistSongIds.has(song.song_id) &&
          song.artist_id === playlist.artist_id &&
          song.is_ban === false
      );
    }
    return allSongs.filter(
      (song) => !playlistSongIds.has(song.song_id) && song.is_ban === false
    );
  }

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const playlistID = { playlist_id: playlist.playlist_id };
    const newFormData = new FormData();
    newFormData.append("playlist_id", playlist.playlist_id);
    if (editData.title !== playlist.title) {
      newFormData.append("title", editData.title);
    }
    // if (editData.playlist_type !== playlist.playlist_type) {
    //   newFormData.append("playlist_type", editData.playlist_type);
    // }
    if (editData.image !== "") {
      newFormData.append("playlist_image", editData.image);
    }
    try {
      const response = await axiosInstance.post(
        "/api/dev/playlist/updatePlaylist",
        newFormData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Updated",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch(
          createApiAction(
            "/api/dev/playlist/getPlaylistWithSongsById",
            "POST",
            playlistID
          )
        );
        setEditData({
          title: "",
          playlist_type: "",
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
      });
    }
    setButtonLoading(false);

    handleEditClose();
  };

  const handleSongApi = async () => {
    try {
      const response = await axiosInstance.post("/api/dev/songs/getAllSongs");
      const availableSongs = getAvailableSongs(
        response.data.data,
        playlist.playlist_songs
      );

      setSongData(availableSongs);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    handleSongApi();
  }, [playlist]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="left"
        justifyContent="left"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.primary.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${pianoKeys})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <MDBox
          position="absolute"
          top="3%"
          left="1.5%"
          transform="translate(-50%, -50%)"
          textAlign="left"
          sx={{
            color: "rgba(255, 252, 255, 0.4)",
            fontSize: "2.5rem",
            // fontWeight: "bold",
            zIndex: 1,
          }}
          textTransform="capitalize"
        >
          {playlist?.playlist_type ? playlist.playlist_type : ""}
        </MDBox>
      </MDBox>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={
                playlist.playlist_image
                  ? baseUrl + playlist.playlist_image
                  : profileDefault
              }
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {`${playlist.title} `}
              </MDTypography>
              <MDTypography
                variant="button"
                color="dark"
                fontWeight="regular"
                textTransform="capitalize"
              >
                {playlist.playlist_type}:{" "}
                {playlist?.playlist_type !== "single"
                  ? playlist?.createdAt
                    ? playlist.createdAt.substring(0, 4)
                    : ""
                  : playlist.title}
              </MDTypography>
              <MDBox>
                {playlist?.playlist_type !== "playlist" &&
                  playlist?.playlist_songs?.[0]?.songs?.[0]?.artist_name && (
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                    >
                      <MDTypography
                        variant="button"
                        color="dark"
                        fontWeight="regular"
                      >
                        {`Artist: ${playlist.playlist_songs[0].songs[0].artist_name}`}
                      </MDTypography>
                    </MDBox>
                  )}
                <MDTypography
                  variant="button"
                  color="dark"
                  fontWeight="regular"
                >
                  {`Total Songs: ${totalCount ? totalCount : "0"}`}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>

          {/* Pop Starts */}
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "40px",
                }}
              >
                <MDButton
                  onClick={handleOpen}
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    marginRight: "10px",
                  }}
                  size="small"
                >
                  <MDTypography
                    style={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Add Songs
                  </MDTypography>
                </MDButton>
                <MDButton
                  onClick={() => {
                    handleEditOpen();
                  }}
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                  }}
                  size="small"
                >
                  <MDTypography
                    style={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Edit Category
                  </MDTypography>
                </MDButton>
              </div>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
      {/* Add Song */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth >
        <DialogTitle>
          Add Song
          <span
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "10px",
              cursor: "pointer",
            }}
          >
            <CancelIcon sx={{ color: purple[500] }} onClick={handleClose} />
          </span>
        </DialogTitle>
        <DialogContent  >
          <form onSubmit={handleSubmit}>
            {/* <Autocomplete
              sx={{ marginTop: "5px", marginBottom: "4px" }}
              id="song_title"
              options={songData}
              fullWidth
              autoHighlight
              getOptionLabel={(option) => option.song_title}
              noOptionsText={playlist?.playlist_type==="album"?"No songs available for this artist":"No songs available"}
              onChange={handleSongChange}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <MDBox
                    key={key}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...optionProps}
                  >
                    {`${option.song_title}  (${option.artist_name})`}
                  </MDBox>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Song"
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            /> */}

 <Autocomplete
  sx={{ marginTop: "5px", marginBottom: "4px" }}
  multiple
  id="song_id"
  options={songData}
  fullWidth
  autoHighlight
  disableCloseOnSelect
  getOptionLabel={(option) => option.song_title}
  noOptionsText={
    playlist?.playlist_type === "album"
      ? "No songs available for this artist"
      : "No songs available"
  }
  onChange={handleSongChange}
  value={songData.filter(song => formData.song_id.includes(song.song_id))}
  renderOption={(props, option, { selected }) => {
    const { key, ...optionProps } = props;
    return (
      <li key={option.song_id} {...optionProps}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {`${option.song_title}  (${option.artist_name})`}
      </li>
    );
  }}
  renderInput={(params) => (
    <TextField 
      {...params}
      placeholder="Select songs" 
      InputProps={{
        ...params.InputProps,
        style: { overflow: 'hidden' } 
      }}
    />
  )}
  renderTags={(selected, getTagProps) => (
    <MDBox 
      style={{ 
        maxHeight: 200, 
        overflowY: 'auto', 
        display: 'flex', 
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      {selected.map((option, index) => (
        <Chip
          key={option.song_id}
          label={option.song_title}
          {...getTagProps({ index })}
          style={{
            backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`, 
            color: '#ffffff' ,
            margin: '2px',
          }}
        />
      ))}
    </MDBox>
  )}
/>
 
            <Button
              type="submit"
              sx={{
                backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                marginRight: "12px",
                marginTop: "10px",
              }}
              fullWidth
              disabled={isAutocompleteError}
              // disabled={formData.song_id===""}
            >
              {buttonLoading ? (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <CircularProgress sx={{ color: "#ffffff" }} size={24} />
                </MDBox>
              ) : (
                <MDTypography
                  style={{ fontSize: "13.5px", fontWeight: "bold" }}
                  color="white"
                >
                  Submit
                </MDTypography>
              )}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      {/* Edit Profile */}
      <Dialog open={editOpen} onClose={handleEditClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Edit Category
          <span
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "10px",
              cursor: "pointer",
            }}
          >
            <CancelIcon sx={{ color: purple[500] }} onClick={handleEditClose} />
          </span>
          <MDBox display="flex" justifyContent="center">
            <MDAvatar
              src={
                imagePreview
                  ? imagePreview
                  : playlist?.playlist_image
                  ? baseUrl + playlist?.playlist_image
                  : profileDefault
              }
              alt="profile-image"
              sx={{ height: "140px", width: "140px" }}
              shadow="sm"
            />
          </MDBox>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleEditSubmit}>
            {/* <FormControl
              fullWidth
              sx={{ marginTop: "7px", marginBottom: "4px" }}
            >
              <InputLabel fullWidth id="playlist_type">
                Type
              </InputLabel>
              <Select
                labelId="playlist_type"
                id="playlist_type"
                name="playlist_type"
                value={editData.playlist_type}
                onChange={handleEditChange}
                label="Type"
                sx={{ height: "40px" }}
              >
                <MenuItem value="playlist">Playlist</MenuItem>
                <MenuItem value="album">Album</MenuItem>
              </Select>
            </FormControl> */}
            <label
              htmlFor="categoryImage"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #ccc",
                padding: "2px",
                marginTop: "8px",
                marginBottom: "4px",
                borderRadius: "5px",
              }}
            >
              <input
                type="file"
                id="categoryImage"
                accept="image/*"
                onChange={handleFileInputChange}
                style={{
                  position: "absolute",
                  width: "1px",
                  height: "1px",
                  opacity: 0,
                }}
              />
              <Button component="span" color="primary" fullWidth>
                Update Image
              </Button>
              <span
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: "1px",
                }}
              >
                {editData.image?.name}
              </span>
            </label>

            <TextField
              autoFocus
              margin="dense"
              id="title"
              name="title"
              label="Title"
              type="text"
              fullWidth
              value={editData.title}
              onChange={handleEditChange}
              inputProps={{ maxLength: 30 }}
              helperText={
                editData.title.length === 30 ? "30 character limit reached" : ""
              }
              FormHelperTextProps={{
                sx: { color: "red" },
              }}
            />

            <Button
              type="submit"
              sx={{
                backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                marginTop: "8px",
              }}
              fullWidth
            >
              {buttonLoading ? (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <CircularProgress sx={{ color: "#ffffff" }} size={24} />
                </MDBox>
              ) : (
                <MDTypography
                  style={{ fontSize: "13.5px", fontWeight: "bold" }}
                  color="white"
                >
                  Submit
                </MDTypography>
              )}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
