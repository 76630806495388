import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";

export const getData = createAsyncThunk(
  "getData",
  async (obj, { getState, rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${baseUrl}/api/dev/admin/CP/getAllCPsAdmin`,
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     "ngrok-skip-browser-warning": "69420",
        //   },
        // }
      );
      return res.data;
    } catch (e) {
      
      return rejectWithValue(e.response);
    }
  }
);
