// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import profileDefault from "assets/images/Profile-default.png";
import DataTable from "examples/Tables/DataTable";

// Overview page components
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import { useContext, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { switchTheme } from "examples/CommonFunctions";
import Switch from "@mui/material/Switch";
import { purple } from "@mui/material/colors";

import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
// Images
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { UserContext } from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { createApiAction } from "store/ApiServices";
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";
import { setSong } from "store/SongSlice";

function CategoryProfile() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const playlist = useSelector(
    (state) => state.apiData.data.data.playlistDetails
  );
  const SongPlaying = useSelector((state) => state.songData.SongData);
  const[currentSong,setCurrentSong]=useState([])
  const [rows, setRows] = useState([]);

  const { id } = useParams();
  const playlistId = {
    playlist_id: id,
  };

 
  const handleSong = (value) => { 
    dispatch(setSong(value));
  };


  const handleSongRemove = (originalId,songId) => {

    const RemoveSongId = {
      playlist_song_id: songId,
    };
    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this Song?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/playlist/removeSongFromPlaylist", RemoveSongId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Song Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            
            setCurrentSong((prevSong) => {
              if (prevSong.song_id === originalId) {
                dispatch(setSong([]));
              }
              return prevSong;
            });
            dispatch(
              createApiAction(
                "/api/dev/playlist/getPlaylistWithSongsById",
                "POST",
                playlistId
              )
            );
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error Occurred!",
            });
          });
      }
    });
  };

  const handleApiCall = async () => {
    try {
      await dispatch(
        createApiAction(
          "/api/dev/playlist/getPlaylistWithSongsById",
          "POST",
          playlistId
        )
      ).then(() => {
        setloading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleRows = () => {
    const rowsData = playlist.playlist_songs.flatMap((playlistSong) =>
      playlistSong.songs.map((song) => ({
        Image: (
          <MDAvatar
            src={
              song.cover_image_url
                ? baseUrl + song.cover_image_url
                : profileDefault
            }
            alt={song.song_title}
            shadow="md"
          />
        ),
        Title: (
          song.song_title
          // <MDTypography
          //   color="text"
          //   fontWeight="medium"
          //   style={{ color: song.is_ban ? "red" : "inherit", fontSize: "14px" }}
          // >
          //   {song.song_title}
          // </MDTypography>
        ),
        Artist: (
          song.artist_name
          // <MDTypography
          //   variant="caption"
          //   color="text"
          //   fontWeight="medium"
          //   style={{ color: song.is_ban ? "red" : "inherit" }}
          // >
          //   {song.artist_name}
          // </MDTypography>
        ),
  
        Action: (
          <MDBox>
             <PlayCircleFilledIcon
            onClick={() => {
              handleSong(song);
            }}
            sx={{ cursor: "pointer",color: song.is_ban ? "red" :  purple[500] }}
            fontSize="large"
          />
           <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => handleSongRemove(song.song_id,playlistSong.id)}
          >
            <DeleteIcon />
          </IconButton>
          </MDBox>
         
        ),
        DisableValue: song.is_ban ? 1 : 0,
      }))
    );

    setRows(rowsData);
  };

  const columns = [
    { Header: "Image", accessor: "Image", width: "10%", align: "center", disableSortBy: true, },
    { Header: "Title", accessor: "Title", align: "center" },
    ...(playlist?.playlist_type === "playlist"
      ? [{ Header: "Artist", accessor: "Artist", align: "center" }]
      : []),
   
    { Header: "Action", accessor: "Action", align: "center" , disableSortBy: true,},
   
  ];

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, playlist]);

  useEffect(() => {
    handleApiCall();
  }, []);

  useEffect(() => {
    setCurrentSong(SongPlaying)
  }, [SongPlaying]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {!loading && (
        <Header>
          <MDBox mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />

                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>

              <Grid item xs={12} xl={12}>
                 {rows?.length > 0 ? (
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                      isPagination={true}
                      canSearch={true}
                    />
                  </MDBox>
                ) : (
                  <MDTypography variant="h6" color="text"  mt={5}>
                    There are currently no songs available. Please add some.
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      )}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default CategoryProfile;
