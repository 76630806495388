/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { styled } from '@mui/material/styles';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import axiosInstance from "axiosInstance";
import { createApiAction } from "store/ApiServices";
import { useSelector } from "react-redux";
import { switchTheme } from "examples/CommonFunctions";
import {  ThemeProvider } from "@mui/material/styles";
function PlatformSettings() {
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(false);
  const user=useSelector((state)=>state.apiData.data.data)

//  console.log("user",user)
 const dispatch= useDispatch()
 const cpID={
  "cp_id":user.user_id
 }

 const handleToggle=async(endPoint)=>{
  try {
    await axiosInstance.post(endPoint, cpID);
    await dispatch(createApiAction("/api/dev/admin/CP/getCpById", "POST",cpID))
    } catch (error) {
    console.error("Error:", error);
  }
 }
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
         Control Panel
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
       
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
           <MDBox mt={0.5}>
            <Switch checked={user?.is_blocked}   onChange={()=>{handleToggle("/api/dev/admin/CP/blockOrUnblockCp")}} />
          </MDBox>
           <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
               Blocked
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch checked={user?.is_verified}   onChange={()=>{handleToggle("/api/dev/admin/CP/verifyCP")}}  />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
             Verification status
            </MDTypography>
          </MDBox>
        </MDBox>
       
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
