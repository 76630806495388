import React, { useEffect } from 'react';

const ShareComp = () => {
  const getMobileOS = () => {
    const ua = window.navigator.userAgent;

    if (/android/i.test(ua)) { // Android - Play Store
      window.location.href = 'https://play.google.com/store';
    } else if (/iPad|iPhone|iPod/.test(ua)) { // iOS - Apple App Store
      window.location.href = 'https://www.apple.com/app-store/';
    } else if (/windows/i.test(ua)) { // Windows - Play Store
      window.location.href = 'https://play.google.com/store';
    } else if (/macintosh/i.test(ua)) { // macOS - App Store
      window.location.href = 'https://www.apple.com/app-store/';
    } else {
      // Handle other devices
      console.log("Unknown device type.");
    }
  };
 
  useEffect(() => {
    getMobileOS();
  }, []);  

  return (
    <h1>...</h1>
  );
};

export default ShareComp;
