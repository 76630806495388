import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CircularProgress } from "@mui/material";

// Overview page components
import Header from "layouts/adminProfile/components/Header";

import { useSelector } from "react-redux";
import { formatDate } from "examples/Date";

function AdminProfile() {
  const { AdminData } = useSelector((state) => state.adminData);
 const {error}=useSelector((state)=>state.apiData)
  const InfoRow = ({ label, value }) => (
    <MDBox>
      <MDTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
        mr={1}
      >
        {label}:
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        {value || "NA"}
      </MDTypography>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {!error?(
  <Header>
        
  <MDBox mt={5} mb={3}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} xl={4}>
        <InfoRow label="fullName" value={AdminData?.name} />
        <InfoRow label="email" value={AdminData?.email} />
        <InfoRow label="mobile" value={AdminData?.msisdn} />
        {/* <InfoRow label="DOB" value={AdminData?.DOB} /> */}
        <MDBox>
          <MDTypography
            variant="button"
            fontWeight="bold"
            textTransform="capitalize"
            mr={1}
          >
            DOB:
          </MDTypography>
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
          >
            {AdminData?.DOB?formatDate(AdminData?.DOB):"NA"}
          </MDTypography>
        </MDBox>
        <InfoRow
          textTransform="capitalize"
          label="gender"
          value={AdminData?.gender}
        />
        <InfoRow label="country" value={AdminData?.country} />
        <InfoRow
          label="Joined On"
          value={
            AdminData.createdAt ? AdminData.createdAt.split("T")[0] : "NA"
          }
        />
        <Divider orientation="vertical" sx={{ mx: 0 }} />
      </Grid>
   
    </Grid>
  </MDBox>
</Header>
      ):  error?
      (<MDBox
        display="flex"
  justifyContent="center"
  alignItems="center"
  width="100vw"
  height="100vh"
  position="fixed"
  top={0}
  left={0}
      >
       <MDTypography>
        Error Occured
       </MDTypography>
      </MDBox>)
       : (<MDBox
        display="flex"
  justifyContent="center"
  alignItems="center"
  width="100vw"
  height="100vh"
  position="fixed"
  top={0}
  left={0}
      >
        <CircularProgress
          sx={{ color: "purple" }}
          size={54}
        />
      </MDBox>)}
    
    </DashboardLayout>
  );
}

export default AdminProfile;

{
  /* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */
}
{
  /* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */
}

{
  /* <ProfileInfoCard
                title="profile information"
                description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                info={{
                  fullName: "Alec M. Thompson",
                  mobile: "(44) 123 1234 123",
                  email: "alecthompson@mail.com",
                  location: "USA",
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              /> */
}
