import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as XLSX from 'xlsx';
import { useState, useEffect } from 'react';
import { baseUrl } from "base";
export const capitalizeFirstLetter = (str) => str?.charAt(0).toUpperCase() + str?.slice(1);
export const switchTheme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            "&.Mui-checked": {
              color: "red",
              "& + .MuiSwitch-track": {
                backgroundColor: "red", 
              },
            },
          },
        },
      },
    },
  });

  export const countRowsInXlsx = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event?.target?.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        
          const nonEmptyRows = json.filter(row => row.some(cell => cell !== null && cell !== undefined && cell !== ''));
          
          const rowCount = nonEmptyRows.length;
          resolve(rowCount);
        } catch (error) {
          reject(error);
        }
      };
      
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const checkIfImageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  };

export const checkUrl = async (imgURL) => {
  const exists = await checkIfImageExists(`${baseUrl}${imgURL}`);
  return exists;
};
