import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function MDCircular({ value, sx, size, ...other }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', ...sx }}>
      <CircularProgress 
        variant="determinate" 
        value={value} 
        sx={{ color: "#ffffff"  }} 
        
      />
    
      <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: '#ffffff', fontWeight: 'bold' }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

MDCircular.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
  /**
   * Additional styles to apply to the Box and CircularProgress components.
   */
  sx: PropTypes.object,
  /**
   * The size of the CircularProgress component.
   */
  size: PropTypes.number,
};

export default MDCircular;
