import { useState, useRef, useEffect } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpg";
import { LoginDetail } from "LoginDetail";
import axios from "axios";
import { addUser } from "store/UserData";
import { useDispatch, useSelector } from "react-redux";
import styledCompo from "../style.module.css";
import { createApiAction } from "store/ApiServices";
import apiData from "store/apislice";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axiosInstance from "axiosInstance";
import { useSearchParams } from "react-router-dom";
import { Checkmark } from 'react-checkmark'
import {CircularProgress} from "@mui/material";

function ChangePassword() {
  const dispatch = useDispatch();
  const [user, setUser] = useState({ password: "", confirmPassword: "" });
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const confirmPasswordRef = useRef(null);
  const [errorPwd, setErrorPwd] = useState({
    password: "",
    confirmPassword: "",
  });
  console.log("token is", token);
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (error) {
      setError(false);
    }
    setUser({
      ...user,
      [id]: value,
    });
    if (id === "password" || id === "confirmPassword") {
      const isValidPassword = (password) =>
        /[a-zA-Z]/.test(password) &&
        /\d/.test(password) &&
        password.length >= 8;

      setErrorPwd((prevErrors) => ({
        ...prevErrors,
        [id]: isValidPassword(value)
          ? ""
          : "Password must be at least 8 characters long, contain a letter, and a number.",
      }));
    }
  };

  const handleClickShowConfirmPassword = () => {
    if (confirmPasswordRef.current) {
      const { selectionStart, selectionEnd } = confirmPasswordRef.current;
      setShowConfirmPassword(!showConfirmPassword);
      requestAnimationFrame(() => {
        confirmPasswordRef.current.setSelectionRange(
          selectionStart,
          selectionEnd
        );
      });
    }
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true)
    setError("");
    if (user.password !== user.confirmPassword) {
      setError("Passwords must be same");
      setButtonLoading(false)
      return;
    }

    try {
      const response = await axiosInstance
        .post(
          "/api/dev/admin/CP/cpForgetPassword",
          { password: user.password },
          {
            headers: {
              Authorization: token ,
            },
          }
        )
        .then(() => {
          setConfirmation(true);
          setButtonLoading(false)
        });
    } catch (error) {
      console.error("API call failed:", error);
      setError(error?.response?.data?.message || "Error Occured");
      setButtonLoading(false)
    }
  };

  useEffect(() => {
    const tokenParam = searchParams.get("token");
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, [searchParams]);
  return (
    <div className={styledCompo.bg_style}>
      <BasicLayout image={""}>
        <Card>
          <MDBox
            variant="contained"
            bgColor="#0E4C75"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              BEATZ
            </MDTypography>
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Change Password
            </MDTypography>
          </MDBox>
          {!confirmation ? (
            <MDBox pt={4} pb={3} px={3}>
              {error && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    fontSize: "0.6em",
                    padding: "0.5em",
                    borderRadius: "0.25em",
                    textAlign: "center",
                  }}
                >
                  {error}
                </div>
              )}
              <MDBox component="form" role="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <TextField
                    margin="dense"
                    label="Password"
                    id="password"
                    type="password"
                    required
                    fullWidth
                    variant="outlined"
                    value={user.password}
                    onChange={handleChange}
                    error={!!errorPwd.password}
                    helperText={errorPwd.password}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <TextField
                    onChange={handleChange}
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm Password"
                    id="confirmPassword"
                    value={user.confirmPassword}
                    fullWidth
                    required
                    inputRef={confirmPasswordRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="contained"
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      color: "white",
                      "&:hover": {
                        backgroundImage: `linear-gradient(90deg, #4d5cc9 0%, #d156a8 100%)`,
                      },
                    }}
                    fullWidth
                    disabled={
                      errorPwd.confirmPassword
                        ? true
                        : false || errorPwd.password
                        ? true
                        : false
                    }
                    type="submit"
                  >
                    {buttonLoading ? (
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                    >
                      <CircularProgress sx={{ color: "#ffffff" }} size={24} />
                    </MDBox>
                  ) : (
                 
                      <MDTypography variant="h6" color="white">
                        Submit
                      </MDTypography>
                   
                  )}
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          ) : (
            <MDBox pt={4} pb={4} px={3}>
               <Checkmark  size='xLarge' />
              <MDTypography
                variant="h5"
                color="success.main"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: 2,
                }}
              >
                Password Changed successfully
              </MDTypography>
              <MDBox mt={1} textAlign="center">
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="h6"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Log In
                  </MDTypography>
                </MDBox>
            </MDBox>
          )}
        </Card>
      </BasicLayout>
    </div>
  );
}

export default ChangePassword;
