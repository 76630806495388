// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router";
import axiosInstance from "axiosInstance";
import { baseUrl } from "base";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

// Data

import { useDispatch, useSelector } from "react-redux";
import { createApiAction } from "store/ApiServices";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Cancel as CancelIcon, CollectionsOutlined } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { Autocomplete } from "@mui/material";
import { Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import profileDefault from "assets/images/Profile-default.png";

export default function ContactUs() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const ContactData = useSelector((state) => state.apiData.data.data);
  const [formData, setFormData] = useState([
    { contact_id: "1", type: "mail", contact_details: "" },
    { contact_id: "3", type: "webSite", contact_details: "" },
    { contact_id: "2", type: "mobile", contact_details: "" },
  ]);
 
  const handleInputChange = (event) => {
    const { name, value, id } = event.target;
    let error = "";
    const validators = {
      mobile: {
        regex: /^[1-9]\d{9}$/,
        message: "Contact number must be exactly 10 digits",
      },
      mail: {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Enter valid email address.",
      },
      webSite: {
        regex: /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/,
        message: "Enter valid website URL.",
      },
    };

    if (validators[id] && !validators[id].regex.test(value) && value !== "") {
      error = validators[id].message;
    }

    setFormErrors({
      ...formErrors,
      [id]: error,
    });

    setFormData((prevData) =>
      prevData.map((contact) =>
        contact?.contact_id === name
          ? { ...contact, contact_details: value, type: id }
          : contact
      )
    );
  };

  const handleForm = () => {
    const filledFormData = formData.map((item) => {
      const contact = ContactData.length? (ContactData.find(
        (contact) => contact?.contact_id?.toString() === item.contact_id
      )):"";
      return {
        ...item,
        contact_details: contact ? contact.contact_details : "",
      };
    });

    setFormData(filledFormData);
    setLoading(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    console.log(formData);
    try {
      // if contactData.length? is not true then loop through fromData and make all contact_id empty. 
      // if ContactData.length true then loop through formData and whichever contactData.contact_id is present just keep then as it is and make rest empty
      const response = await axiosInstance.post(
        "/api/dev/admin/contact/addContactDetails",  
        formData
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Contact Details Updated",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch(
          createApiAction(
            "/api/dev/admin/contact/getContactDetailsAdmin",
            "GET"
          )
        ).then(() => {
          setLoading(false);
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred",
      });
      console.log("error is", e.message);
    }
    setFormData([
      { contact_id: "1", type: "mail", contact_details: "" },
      { contact_id: "3", type: "webSite", contact_details: "" },
      { contact_id: "2", type: "mobile", contact_details: "" },
    ]);
    setButtonLoading(false);
  };

  const handleApiCall = async () => {
    try {
      await dispatch(
        createApiAction("/api/dev/admin/contact/getContactDetailsAdmin", "GET")
      ).then(() => {
        setLoading(false);
        setInitialLoading(false)
      });
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!loading) {
      handleForm();
    }
  }, [loading]);

  useEffect(() => {
    handleApiCall();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {!initialLoading ? (
                <MDBox>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                  >
                    <MDBox>
                      <MDTypography variant="h5" color="white">
                        CONTACT US
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={{
                      py: 5,
                      mx: 5,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <form
                      onSubmit={handleSubmit}
                      style={{ width: "100%", maxWidth: "500px" }}
                    >
                      <MDBox
                        sx={{
                          mb: 3,
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                        display="flex"
                      >
                        <MDTypography
                          align="left"
                          width={{ xs: "100%", sm: "240px" }}
                          mb={{ xs: 1, sm: 0 }}
                        >
                          Mail :
                        </MDTypography>
                        <TextField
                          id="mail"
                          name="1"
                          value={
                            formData.find(
                              (contact) => contact.contact_id === "1"
                            ).contact_details
                          }
                          onChange={handleInputChange}
                          type="email"
                          variant="outlined"
                          label="Email Address"
                          fullWidth
                          error={Boolean(formErrors.mail)}
                          helperText={formErrors.mail}
                        />
                      </MDBox>
                      <MDBox
                        sx={{
                          mb: 3,
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                        display="flex"
                      >
                        <MDTypography
                          align="left"
                          width={{ xs: "100%", sm: "240px" }}
                          mb={{ xs: 1, sm: 0 }}
                        >
                          Website :
                        </MDTypography>
                        <TextField
                          id="webSite"
                          name="3"
                          value={
                            formData.find(
                              (contact) => contact.contact_id === "3"
                            ).contact_details
                          }
                          onChange={handleInputChange}
                          type="text"
                          variant="outlined"
                          label="Website"
                          fullWidth
                          error={Boolean(formErrors.webSite)}
                          helperText={formErrors.webSite}
                        />
                      </MDBox>
                      <MDBox
                        sx={{
                          mb: 3,
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                        display="flex"
                      >
                        <MDTypography
                          align="left"
                          width={{ xs: "100%", sm: "240px" }}
                          mb={{ xs: 1, sm: 0 }}
                        >
                          Mobile :
                        </MDTypography>
                        <TextField
                          id="mobile"
                          name="2"
                          value={
                            formData.find(
                              (contact) => contact.contact_id === "2"
                            ).contact_details
                          }
                          onChange={handleInputChange}
                          type="text"
                          variant="outlined"
                          label="Contact Number"
                          fullWidth
                          inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                          error={!!formErrors.mobile}
                          helperText={formErrors.mobile}
                        />
                      </MDBox>
                      <MDBox sx={{ textAlign: "center" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{
                            backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                            width: { xs: "100%", sm: "150px" },
                          }}
                          disabled={Object.values(formErrors).some(
                            (error) => error !== ""
                          )}
                        >
                          {buttonLoading ? (
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                            >
                              <CircularProgress
                                sx={{ color: "#ffffff" }}
                                size={24}
                              />
                            </MDBox>
                          ) : (
                            <MDTypography
                              style={{ fontSize: "13.5px", fontWeight: "bold" }}
                              color="white"
                            >
                              Save
                            </MDTypography>
                          )}
                        </Button>
                      </MDBox>
                    </form>
                  </MDBox>
                </MDBox>
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100vw"
                  height="100vh"
                  position="fixed"
                  top={0}
                  left={0}
                >
                  <CircularProgress sx={{ color: "purple" }} size={54} />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
