
import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { purple } from '@mui/material/colors';// Material Dashboard 2 React components
import CancelIcon from '@mui/icons-material/Cancel';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Swal from 'sweetalert2';
// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import audio from "assets/audio/SampleAudio.mp3";
import IconButton from '@mui/material/IconButton';
// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

//Audio Player
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './styles.css'
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "base";
import { setSong } from "store/SongSlice";
import CloseIcon from '@mui/icons-material/Close';
import { setAdmin } from "store/AdminSlice";
import profileDefault from "assets/images/Profile-default.png"
import MDAvatar from "components/MDAvatar";
import { Tooltip } from "@mui/material";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const Song = useSelector((state) => state.songData.SongData);
  const ImageShow = useSelector((state) => state.songData.ImageShow);
  const MyDispatch = useDispatch();
  const {AdminData}= useSelector((state)=>state.adminData)

  const songSrc = `${baseUrl}${Song.song_url}`
  const collapseName = location.pathname.replace("/", "");
  const navigate = useNavigate();
  let textColor = "white";
  // Song.cover_image_url="ghjgh"
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }


  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            // active={key === collapseName}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />

        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  const handleLogout = async () => {
    const result = await  Swal.fire({
      icon: "warning",
      title: "Do you want to Log Out?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    })
  
    if (result.isConfirmed) {
      await MyDispatch(setSong([]));
      await MyDispatch(setAdmin({}));
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      navigate('/authentication/sign-in');
    }
  };


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center" >
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox  display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            
          >
            <MDTypography variant="button" fontWeight="medium" color={textColor} component={NavLink} to="/dashboard" sx={{ marginLeft: "9px", fontSize: "17px" }}>
              {brandName}
            </MDTypography>
           
          </MDBox>
        </MDBox>
      </MDBox>
      {/* <MDBox pt={3} pb={1} px={4} textAlign="center">
  <MDBox
    display={{ xs: "block", xl: "none" }}
    position="absolute"
    top={0}
    right={0}
    p={1.625}
    onClick={closeSidenav}
    sx={{ cursor: "pointer" }}
  >
    <MDTypography variant="h6" color="secondary">
      <Icon sx={{ fontWeight: "bold" }}>close</Icon>
    </MDTypography>
  </MDBox>

  <MDBox display="flex" alignItems="center" justifyContent="space-between">
    {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}

    <MDBox display="flex" alignItems="center" width="auto" sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
      <MDTypography
        variant="button"
        fontWeight="medium"
        color={textColor}
        component={NavLink}
        to="/dashboard"
        sx={{ fontSize: "18px" }}
      >
        {brandName}
      </MDTypography>

      <Tooltip key={AdminData.name} title="Profile" placement="bottom">
        <MDAvatar
          src={AdminData?.image_url ? baseUrl + AdminData?.image_url : profileDefault}
          name={AdminData.name}
          size="md"
          onClick={() => navigate("/admin")}
          sx={{ cursor: "pointer", marginLeft: "5px" }} 
        />
      </Tooltip>
    </MDBox>
  </MDBox>
</MDBox> */}

      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List sx={{overflow:"auto",scrollbarWidth:"thin",scrollbarColor:" #ffffff #353333" }}>{renderRoutes}</List>


      <MDBox p={2} mt="auto" >
        {Song?.song_url &&
          <MDBox mb={3}>
            <MDBox>
              <div style={{ position: 'relative', borderRadius: '10px', marginBottom: '5px', border: 'solid 1px white', display: 'inline-block', overflow: 'hidden' }}>
                <img
                  src={baseUrl + Song.cover_image_url}
                  style={{ width: '219px', height: '200px', borderRadius: '10px', display: 'block' }}
                  alt="Song Cover"
                />

                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: '5px', borderRadius: '0 0 11px 11px', color: 'white' }}>
                  <p style={{ margin: 0, fontSize: '14px' }}>{Song.song_title}</p>
                  <p style={{ margin: 0, fontSize: '12px' }}>Artist: {Song.artist_name}</p>
                </div>

                <IconButton
  onClick={() => {
    MyDispatch(setSong([]));
  }}
  sx={{
    position: 'absolute',
    top: '1px',
    right: '1px',
  }}
>
  <CancelIcon sx={{ color: '#000000',backgroundColor:"#ffffff",borderRadius:"50%" }} />
</IconButton>

              </div>
            </MDBox>


            <AudioPlayer
              color={sidenavColor}
              style={{
                borderRadius: '10px',
              }}
              autoPlay
              src={songSrc}
              onPlay={""} />
          </MDBox>
        }

        <MDButton
          onClick={() => { handleLogout() }}
          color={sidenavColor}
          sx={{
            backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`
          }}

          fullWidth
        >
          LogOut
        </MDButton>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
