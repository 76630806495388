import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const LoginDetail = createAsyncThunk(
    "LoginDetail",
    async(obj,{getState,rejectWithValue})=>{
        try{
           const res= await axios.post(
                "https://mako-evolving-optionally.ngrok-free.app/api/dev/admin/auth/adminLogin",
                obj
              );
             return res.data;
           
        }catch(e){
            return rejectWithValue(e.response)
        }
    }
)


