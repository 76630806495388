import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";
import Checkbox from "@mui/material/Checkbox";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete, DialogActions } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Button } from "@mui/material";
// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { pink } from "@mui/material/colors";
import { purple } from "@mui/material/colors";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { createApiAction } from "store/ApiServices";
import { CleaningServices, SearchOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import { countries } from "assets/countries/Countries";
import { languages } from "assets/languages/Languages";
import { getData } from "layouts/CPprofile/cpThunk";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import CancelIcon from "@mui/icons-material/Cancel";
import { setSong } from "store/SongSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import Pagination from "@mui/material/Pagination";
import { CircularProgress } from "@mui/material";
import profileDefault from "assets/images/Profile-default.png";
import folderSrtructure from "assets/images/folder_structure.png";

import { capitalizeFirstLetter } from "examples/CommonFunctions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { switchTheme } from "examples/CommonFunctions";
import MDCircular from "components/MDCircular";
import LinearProgress from "@mui/material/LinearProgress";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import Alert from "@mui/material/Alert";
import { countRowsInXlsx } from "examples/CommonFunctions";
import Instructions from "./instructions";
import { checkUrl } from "examples/CommonFunctions";
import songsMetadata from "assets/excelFile/songsMetadata.xlsx";
import DownloadIcon from "@mui/icons-material/Download";

function SongsList() {
  const [genreData, setGenreData] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [initalLoading, setInitialLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);
  const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [processingLoading, setProcessingLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const songData = useSelector((state) =>
    state.apiData.data.data.allSongs
      ? state.apiData.data.data.allSongs
      : state.apiData.data.data.songsSearchResult
  );
  const { totalCount } = useSelector((state) => state.apiData.data.data);
  const SongPlaying = useSelector((state) => state.songData.SongData);

  const { error } = useSelector((state) => state.apiData);
  const timeoutRef = useRef(null);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [typeData, setTypeData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const itemsPerPage = 10;
  const [editSongImage, setEditSongImage] = useState(null);
  const [currentSong, setCurrentSong] = useState([]);
  const [bulkError, setBulkError] = useState("");
  const [auditFiles, setAuditFiles] = useState({});
  const [FilesData, setFilesData] = useState({
    songs: "",
    cover_img: "",
    excel_rows: "",
  });
  const [controller, setController] = useState(null);
  const imagesExtension = ["jpg", "jpeg", "webp"];
  const audioExtension = ["mp3", "aac", "flac", "ogg", "wav"];
  const errorMessages = {
    ".cp": "CP not found.",
    readStream: "Error inside excel file.",
    NoFileUploaded: "No Songs added.",
    InvalidFileNames: "Invalid File Names.",
    ".excel": "Excel file name is not correct.",
    NoFileUploaded_InvalidFileNames: "Files already existed.",
    ".internal": "Internal error occurred.",
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchText(value);
    }, 300);
  };
  const callTypeData = async (value) => {
    const CategoryType = {
      category_type: value,
    };
    try {
      const response = await axiosInstance.post(
        "/api/dev/playlist/getCategoryByType",
        CategoryType
      );
      setTypeData(response.data.data.categoryList);
    } catch (e) {
      console.log(e.message);
    }
  };
  const [initialForm, setInitialForm] = useState({
    song_title: "",
    artist_id: "",
    artist_name: "",
    cp_id: "",
    country: "",
    language: "",
    genre_id: "",
    duration: "",
    size: "",
    playlist_type: "",
    playlist_id: "",
    record_label: "",
    song: "",
    cover_image: "",
  });
  const [formData, setFormData] = useState({
    song_id: "",
    song_title: "",
    artist_id: "",
    artist_name: "",
    cp_id: "",
    country: "",
    language: "",
    genre_id: "",
    duration: "",
    size: "",
    playlist_type: "",
    playlist_id: "",
    record_label: "",
    song: "null",
    cover_image: "",
  });

  const handleEditUserClick = (song) => {
    const initialFormState = {
      song_id: song.song_id,
      song_title: song.song_title,
      artist_id: song.artist_id,
      artist_name: song.artist_name,
      cp_id: song.cp_id,
      country: song.country,
      language: song.language,
      genre_id: song.genre_id,
      duration: song.duration,
      size: song.size,
      playlist_type: song.playlist_type,
      playlist_id: song.playlist_id,
      record_label: song.record_label,
      song: null,
      cover_image: null,
    };

    if (song.playlist_type !== "single") {
      callTypeData(song.playlist_type);
    }
    const songImage = baseUrl + song.cover_image_url;
    setEditSongImage(songImage);
    setInitialForm(initialFormState);
    setFormData(initialFormState);
    setIsEditUserDialogOpen(true);
  };

  const handleCloseDialogs = () => {
    console.log("formData", formData);
    setIsEditUserDialogOpen(false);
    setImagePreview(null);
    setEditSongImage(null);
  };

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleFileInputChange = (event) => {
    const { id } = event.target;
    const file = event.target.files[0];
    setFormData({ ...formData, [id]: file });
    if (file && id !== "song") {
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  const handleCountryChange = (event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : "",
    });
  };

  const handleAutoComplete = (fieldName, lableName, valueName) => {
    return fieldName.find((item) => item[lableName] === valueName) || null;
  };

  const handleLanguage = (event, newValue) => {
    setFormData({
      ...formData,
      language: newValue ? newValue.name : "",
    });
  };

  const handleType = (e, newValue, id) => {
    setFormData({
      ...formData,
      [id]: newValue[id] ? newValue[id] : "",
    });
  };

  const handleBan = (song) => {
    const BanSongId = {
      song_id: song.song_id,
    };
    const status = song.is_ban ? "Enable" : "Disable";
    Swal.fire({
      icon: "warning",
      title: `Do you want to ${status} this song?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/songs/songBaned", BanSongId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Done!",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(
              createApiAction(
                `/api/dev/songs/getAllSongsForAdmin?limit=${itemsPerPage}&page=${page}`,
                "POST"
              )
            ).then(() => setLoading(false));
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
            });
          });
      }
    });
  };
  const handleSong = (value) => {
    // console.log(value)
    dispatch(setSong(value));
  };
  const excludedKeys = new Set([
    "cover_image",
    "playlist_id",
    "song",
    "song_id",
    "artist_id",
    "playlist_type",
  ]);

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();
    newFormData.append("artist_id", formData.artist_id);
    newFormData.append("song_id", formData.song_id);

    Object.keys(formData).forEach((key) => {
      if (
        !excludedKeys.has(key) &&
        formData[key] !== initialForm[key] &&
        formData[key] !== null
      ) {
        if ((key === "duration" || key === "size") && formData[key] === "") {
          newFormData.append(key, 0);
        } else {
          newFormData.append(key, formData[key]);
        }
      }
    });

    if (formData.cover_image !== null) {
      newFormData.append("cover_image", formData.cover_image);
    }
    if (formData.song !== null) {
      newFormData.append("song", formData.song);
    }

    try {
      console.log([...newFormData.entries()]);
      await axiosInstance.post("/api/dev/songs/updateSong", newFormData);
      dispatch(
        createApiAction(
          `/api/dev/songs/getAllSongsForAdmin?limit=${itemsPerPage}&page=${page}`,
          "POST"
        )
      ).then(() => setLoading(false));
      Swal.fire({
        title: "Song Updated",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        icon: "error",
      });
      console.log(e.message);
    }
    setButtonLoading(false);
    handleCloseDialogs();
  };

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image ? baseUrl + image : profileDefault}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const handleSongRemove = (removeSong) => {
    const songRemoveId = {
      song_id: removeSong,
    };

    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this Song?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/songs/deleteSong", songRemoveId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Song Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            setCurrentSong((prevSong) => {
              if (prevSong.song_id === removeSong) {
                dispatch(setSong([]));
              }
              return prevSong;
            });
            dispatch(
              createApiAction(
                `/api/dev/songs/getAllSongsForAdmin?limit=${itemsPerPage}&page=${page}`,
                "POST"
              )
            ).then(() => setLoading(false));
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "There was an error removing this Song.",
            });
          });
      }
    });
  };

  // Bulk Song upload functions

  const openFolderDialog = () => {
    setIsFolderDialogOpen(true);
  };

  const getErrorMessage = (message) => {
    for (const key in errorMessages) {
      if (message.includes(key)) {
        return errorMessages[key];
      }
    }
    return "Server error occurred.";
  };

  const getAllSongs = async () => {
    try {
      await dispatch(
        createApiAction(
          `/api/dev/songs/getAllSongsForAdmin?limit=${itemsPerPage}&page=${page}`,
          "POST"
        )
      );
    } catch (error) {
      console.error("Error fetching songs:", error);
    } finally {
      setLoading(false);
    }
  };

  const closeFolderDialog = async () => {
    try {
      if (auditFiles?.success === true) {
        await getAllSongs();
      }
    } catch (error) {
      console.error("Error closing folder dialog:", error);
    } finally {
      setSelectedFiles([]);
      setBulkError();
      setButtonLoading(false);
      setIsFolderDialogOpen(false);
      setShowInfo(false);
      setAuditFiles({});
    }
    if (controller) {
      controller.abort();
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setIsImporting(false);
    }
  }, [selectedFiles]);

  const handleFolderSelection = async (event) => {
    const { files } = event.target;

    if (files.length > 0) {
      setIsImporting(true);
      const finalFiles = Array.from(files);
      console.log("all files", finalFiles);
      let cvrartCount = 0;
      let audtrkCount = 0;
      let metadataFile = null;
      let rowCount = 0;

      for (const file of finalFiles) {
        console.log("file name is", file.name);

        if (file.name.includes("songsMetadata")) {
          metadataFile = file;
          try {
            rowCount = await countRowsInXlsx(file);
          } catch (error) {
            console.error("Error reading file:", error);
            setBulkError("Error in songsMetadata file.");
            setIsImporting(false);
            return;
          }
        } else {
          const fileExtension = file.name.split(".").pop().toLowerCase();

          if (imagesExtension.includes(fileExtension)) {
            cvrartCount++;
          } else if (audioExtension.includes(fileExtension)) {
            audtrkCount++;
          }
        }
      }

      setFilesData({
        songs: audtrkCount,
        cover_img: cvrartCount,
        excel_rows: rowCount ? rowCount - 1 : 0,
      });
      const isValid =
        cvrartCount === audtrkCount &&
        metadataFile &&
        rowCount - 1 === audtrkCount
          ? 1
          : 0;

      setSelectedFiles(finalFiles);
      if (!isValid) {
        setBulkError(
          "File validation failed. Please ensure that the number of song files matches the cover image files, and that the songsMetadata file is accurate."
        );
      } else {
        setBulkError();
      }

      console.log("EXL rows:", rowCount);
      console.log("CVRART Count:", cvrartCount);
      console.log("AUDTRK Count:", audtrkCount);
      console.log("Metadata File:", metadataFile);

      setIsImporting(false);
    }
  };

  const uploadFolderFiles = async () => {
    console.log("Uploading files from folder:", selectedFiles);
    setProgress(0);
    setButtonLoading(true);
    const newController = new AbortController();
    setController(newController);
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      return formData.append("files[]", file);
    });

    try {
      const response = await axiosInstance.post(
        "/api/dev/songs/addSongsInBulk",
        formData,
        {
          timeout: 7200000,
          signal: newController.signal,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );
      if (response?.data?.success) {
        setButtonLoading(false);
        setSelectedFiles([]);
        if (response?.data?.data?.length > 0) {
          setAuditFiles(response.data);
        } else {
          closeFolderDialog();
          Swal.fire({
            icon: "success",
            title: "Songs Added",
            timer: 1500,
            showConfirmButton: false,
          });
          getAllSongs();
        }
        setController(null);
      }
    } catch (error) {
      console.error("error is ", error);
      if (error?.response?.data?.data.length > 0) {
        console.log(error.response.data.data);
        setAuditFiles(error?.response?.data);
        setButtonLoading(false);
        setSelectedFiles([]);
      } else {
        closeFolderDialog();
        Swal.fire({
          icon: "error",
          title: controller
            ? "Upload Canceled"
            : error?.response?.data?.message
            ? getErrorMessage(error.response.data.message)
            : "Error Occured",
        }).then(() => {
          setController(null);
        });
      }
    } finally {
      setButtonLoading(false);
      setProcessingLoading(false);
      setProgress(0);
      console.log("inside controller", controller);
      setController(null);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    uploadFolderFiles();
  };

  const handleRows = () => {
    const rowsAPI = songData?.map((song) => {
      return {
        Action: (
          <MDBox>
            <PlayCircleFilledIcon
              onClick={() => {
                handleSong(song);
              }}
              sx={{
                cursor: "pointer",
                color: song.is_ban ? "red" : purple[500],
              }}
              fontSize="large"
            />
            <IconButton
              aria-label="edit"
              color="secondary"
              onClick={() => {
                handleEditUserClick(song);
              }}
              sx={{ marginLeft: "3px" }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => {
                handleSongRemove(song.song_id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </MDBox>
        ),
        Image: (
          <MDBox display="flex" py={1}>
            {avatars([[song.cover_image_url, song.song_title]])}
          </MDBox>
        ),
        Title: capitalizeFirstLetter(song.song_title),
        Artist: capitalizeFirstLetter(song.artist_name),
        Language: capitalizeFirstLetter(song.language),
        Disable: (
          <ThemeProvider theme={switchTheme}>
            <MDBox>
              <Switch checked={song.is_ban} onChange={() => handleBan(song)} />
            </MDBox>
          </ThemeProvider>
        ),
        DisableValue: song.is_ban ? 1 : 0,
      };
    });

    setRows(rowsAPI);
    setLoading(false); // Stop loading after rows are set
  };
  const handleApiCall = async () => {
    if (searchText.trim() !== "") {
      try {
        const SearchData = {
          search_keyword: searchText,
        };
        await dispatch(
          createApiAction(
            `/api/dev/songs/songsSearch?limit=${itemsPerPage}&page=${searchPage}`,
            "POST",
            SearchData
          )
        ).then(() => {
          setLoading(false);
          setInitialLoading(false);
        });
      } catch (error) {
        console.log("Error fetching data:", error);
        setInitialLoading(false);
      }
    } else if (searchText.trim() === "") {
      try {
        await dispatch(
          createApiAction(
            `/api/dev/songs/getAllSongsForAdmin?limit=${itemsPerPage}&page=${page}`,
            "POST"
          )
        ).then(() => {
          setLoading(false);
          setInitialLoading(false);
          setSearchPage(1);
        });
      } catch (error) {
        console.log("Error fetching data:", error);
        setInitialLoading(false);
      }
    }
  };

  const columns = [
    {
      Header: "Image",
      accessor: "Image",
      width: "10%",
      align: "center",
      disableSortBy: true,
    },
    { Header: "Title", accessor: "Title", align: "center" },
    { Header: "Artist", accessor: "Artist", align: "center" },
    { Header: "Language", accessor: "Language", align: "center" },
    {
      Header: "Disable",
      accessor: "Disable",
      align: "center",
      sortType: (rowA, rowB) => {
        return rowA.original.DisableValue - rowB.original.DisableValue;
      },
    },
    {
      Header: "Action",
      accessor: "Action",
      align: "center",
      disableSortBy: true,
    },
  ];

  const getGenreData = async (value) => {
    try {
      const response = await axiosInstance.get(
        "/api/dev/admin/genre/getAllGenres"
      );
      setGenreData(response.data.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    handleApiCall();
    getGenreData();
  }, []);

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, songData, page, searchPage]);

  useEffect(() => {
    handleApiCall();
  }, [searchText, page, searchPage]);

  useEffect(() => {
    setCurrentSong(SongPlaying);
  }, [SongPlaying]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {!initalLoading ? (
                <MDBox>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2, // Gap between items
                      p: 3,
                    }}
                  >
                    <MDBox
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: { xs: "center", sm: "flex-start" }, // Center on small screens
                        mb: { xs: 2, sm: 0 }, // Margin bottom on small screens
                      }}
                    >
                      <MDTypography variant="h5" color="white">
                        SONGS ({totalCount})
                      </MDTypography>
                    </MDBox>

                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }} // Stack vertically on small screens
                      alignItems="center"
                      sx={{
                        gap: { xs: 2, sm: 0 }, // Gap between elements on small screens
                        justifyContent: { xs: "center", sm: "flex-end" }, // Center on small screens
                        flex: 1,
                      }}
                    >
                      <TextField
                        onChange={handleSearchChange}
                        id="standard-bare"
                        variant="outlined"
                        placeholder="Search Song"
                        sx={{
                          "& .MuiInputBase-input": {
                            color: "#ffffff",
                            width: { xs: "100%", sm: "240px" },
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <SearchOutlined color="white" />
                            </IconButton>
                          ),
                        }}
                      />
                      <MDButton
                        variant="contained"
                        size="small"
                        sx={{
                          color: "black",
                          marginLeft: { xs: 0, md: "12px" },
                          marginRight: { xs: 0, md: "12px" },
                          color: "black",
                          paddingBottom: "13px",
                          paddingTop: "13px",
                          width: { xs: "100%", md: "auto" },
                        }}
                        fullWidth
                        onClick={openFolderDialog}
                      >
                        Add Songs in Bulk
                      </MDButton>
                    </MDBox>
                  </MDBox>

                  <MDBox pt={3}>
                    {rows?.length > 0 ? (
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    ) : (
                      <MDBox
                        sx={{
                          display: "flex",
                          padding: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <MDTypography
                          variant="caption"
                          sx={{ fontSize: "20px" }}
                        >
                          {/* {!loading &&"There are currently no songs available."} */}
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>

                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                    mb={3}
                  >
                    <Pagination
                      count={Math.ceil(totalCount / itemsPerPage)}
                      page={searchText === "" ? page : searchPage}
                      onChange={(event, value) => {
                        {
                          searchText === ""
                            ? setPage(value)
                            : setSearchPage(value);
                        }
                      }}
                      color="primary"
                      shape="rounded"
                      size="large"
                      sx={{
                        "& .MuiPaginationItem-root": {
                          borderRadius: "8px",
                          border: "1px solid #ddd",
                          margin: "0 4px",
                          "&.Mui-selected": {
                            backgroundColor: "#5870FD",
                            color: "white",
                            border: "1px solid #5870FD",
                          },
                          "&:hover": {
                            backgroundColor: "#bb48bd",
                          },
                        },
                        "& .MuiPaginationItem-previousNext": {
                          borderRadius: "8px",
                          border: "1px solid #ddd",
                        },
                      }}
                    />
                  </MDBox>
                </MDBox>
              ) : !error ? (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100vw"
                  height="100vh"
                  position="fixed"
                  top={0}
                  left={0}
                >
                  <CircularProgress sx={{ color: "purple" }} size={54} />
                </MDBox>
              ) : (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100vw"
                  height="100vh"
                  position="fixed"
                  top={0}
                  left={0}
                >
                  <MDTypography>Error Occured</MDTypography>
                </MDBox>
              )}
            </Card>
            {/* Edit Song Dialog */}
            <Dialog
              open={isEditUserDialogOpen}
              onClose={handleCloseDialogs}
              sx={{ overflow: "hidden" }}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle sx={{ overflow: "hidden" }}>
                Edit Song
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    padding: "10px",
                    cursor: "pointer",
                  }}
                >
                  <CancelIcon
                    sx={{ color: purple[500] }}
                    onClick={handleCloseDialogs}
                  />
                </span>
              </DialogTitle>
              <DialogContent style={{ overflow: "auto" }}>
                {editSongImage && (
                  <MDBox
                    height="200px"
                    width="300px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      margin: "0 auto",
                    }}
                  >
                    <img
                      src={imagePreview || editSongImage}
                      alt="Song Cover Image"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                    />
                  </MDBox>
                )}

                <form onSubmit={handleEditFormSubmit}>
                  <label
                    htmlFor="cover_image"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      padding: "2px",
                      marginTop: "12px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <input
                      type="file"
                      id="cover_image"
                      accept="image/*"
                      onChange={handleFileInputChange}
                      style={{
                        position: "absolute",
                        width: "1px",
                        height: "1px",
                        opacity: 0,
                      }}
                    />
                    <Button component="span" color="primary" fullWidth>
                      Edit Cover Image
                    </Button>
                    <span
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "1px",
                      }}
                    >
                      {formData.cover_image?.name}
                    </span>
                  </label>
                  <label
                    htmlFor="song"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      padding: "2px",
                      marginTop: "2px",
                      marginBottom: "4px",
                      borderRadius: "5px",
                    }}
                  >
                    <input
                      type="file"
                      id="song"
                      accept="audio/*"
                      onChange={handleFileInputChange}
                      style={{ display: "none" }}
                    />
                    <Button component="span" color="primary" fullWidth>
                      Update Song file
                    </Button>
                    <span
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "1px",
                      }}
                    >
                      {formData.song?.name}
                    </span>
                  </label>

                  <TextField
                    autoFocus
                    margin="dense"
                    id="song_title"
                    label="Song Title"
                    type="text"
                    fullWidth
                    value={formData.song_title}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 25 }}
                    helperText={
                      formData.song_title.length === 25
                        ? "25 character limit reached"
                        : ""
                    }
                    FormHelperTextProps={{
                      sx: { color: "red" },
                    }}
                  />

                  <Autocomplete
                    sx={{ marginTop: "5px", marginBottom: "4px" }}
                    id="country-select-demo"
                    options={countries}
                    value={handleAutoComplete(
                      countries,
                      "label",
                      formData.country
                    )}
                    fullWidth
                    autoHighlight
                    clearOnBlur
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    onChange={handleCountryChange}
                    renderOption={(props, option) => {
                      const { key, ...optionProps } = props;
                      return (
                        <MDBox
                          key={key}
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...optionProps}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </MDBox>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />

                  <Autocomplete
                    sx={{ marginTop: "7px", marginBottom: "7px" }}
                    id="language-select"
                    options={languages}
                    value={handleAutoComplete(
                      languages,
                      "name",
                      formData.language
                    )}
                    fullWidth
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    onChange={handleLanguage}
                    clearOnBlur
                    disableClearable
                    renderOption={(props, option) => {
                      const { key, ...optionProps } = props;
                      return (
                        <MDBox key={key} component="li" {...optionProps}>
                          {option.name}
                        </MDBox>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Language"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    id="genre_id"
                    options={genreData}
                    fullWidth
                    autoHighlight
                    getOptionLabel={(option) => option.genre_title}
                    onChange={(e, newValue) => {
                      handleType(e, newValue, "genre_id");
                    }}
                    value={handleAutoComplete(
                      genreData,
                      "genre_id",
                      formData.genre_id
                    )}
                    clearOnBlur
                    disableClearable
                    renderOption={(props, option) => {
                      const { key, ...optionProps } = props;
                      return (
                        <MDBox key={key} component="li" {...optionProps}>
                          {" "}
                          {option.genre_title}
                        </MDBox>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Genre"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />

                  <TextField
                    margin="dense"
                    id="duration"
                    label="Duration"
                    type="number"
                    fullWidth
                    value={formData.duration}
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="dense"
                    id="size"
                    label="Size"
                    type="number"
                    fullWidth
                    value={formData.size}
                    onChange={handleInputChange}
                  />

                  <TextField
                    margin="dense"
                    id="record_label"
                    label="record label"
                    type="text"
                    fullWidth
                    value={formData.record_label}
                    onChange={handleInputChange}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      marginTop: "5px",
                    }}
                    size="small"
                  >
                    {buttonLoading ? (
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <CircularProgress sx={{ color: "#ffffff" }} size={24} />
                      </MDBox>
                    ) : (
                      <MDTypography
                        style={{ fontSize: "13.5px", fontWeight: "bold" }}
                        color="white"
                      >
                        Submit
                      </MDTypography>
                    )}
                  </Button>
                </form>
              </DialogContent>
            </Dialog>
            {/* Add Bulk Songs Dialog  */}
            <Dialog
              open={isFolderDialogOpen}
              onClose={closeFolderDialog}
              maxWidth={showInfo ? "md" : "sm"}
              fullWidth
              sx={{ overflow: "hidden" }}
            >
              <DialogTitle>
                Bulk Song Upload
                <span
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    cursor: "pointer",
                  }}
                >
                  <CancelIcon
                    sx={{ color: purple[500], fontSize: 24 }}
                    onClick={closeFolderDialog}
                  />
                </span>
              </DialogTitle>
              <form onSubmit={handleFormSubmit}>
                <DialogContent
                  mb={0}
                  sx={{
                    overflow: "hidden",
                  }}
                >
                  <MDBox
                    sx={{
                      width: "100%",
                      padding: "0px 16px 0 16px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <MDBox
                      width="100%"
                      border={1}
                      borderColor="rgba(0, 0, 0, 0.3)"
                      pt={4}
                      pb={2}
                      borderRadius="8px"
                      boxShadow={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="file"
                        required={!selectedFiles}
                        webkitdirectory=""
                        mozdirectory=""
                        msdirectory=""
                        odirectory=""
                        directory=""
                        onChange={handleFolderSelection}
                        id="file-input"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="file-input">
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          sx={{ marginBottom: "8px", color: "#ffffff" }}
                        >
                          Browse Folder
                        </Button>
                      </label>
                      <MDTypography
                        variant="body1"
                        sx={{
                          textAlign: "center",
                          color: selectedFiles.length ? "#333" : "#777",
                          fontSize: { xs: "14px", sm: "16px" },
                          fontWeight: 500,
                        }}
                      >
                        {selectedFiles.length
                          ? `Selected Folder: ${
                              selectedFiles[0]?.webkitRelativePath.split("/")[0]
                            }`
                          : "No folder selected"}
                        {selectedFiles.length > 0 && (
                          <MDBox
                            sx={{
                              textAlign: "left",
                              fontSize: "13px",
                              color: "#555",
                            }}
                          >
                            <MDBox>{`Song Files: ${FilesData.songs}`}</MDBox>
                            <MDBox>{`Cover Image Files: ${FilesData.cover_img}`}</MDBox>
                            <MDBox>{`Excel Entries: ${FilesData.excel_rows}`}</MDBox>
                          </MDBox>
                        )}
                      </MDTypography>

                      <MDBox>
                        {isImporting && selectedFiles.length > 0 && (
                          <MDBox mt={2}>
                            <LinearProgress
                              color="info"
                              sx={{ width: "100%" }}
                            />
                            <MDTypography
                              variant="body2"
                              sx={{ color: "gray" }}
                            >
                              Importing files, please wait...
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                      {bulkError && (
                        <MDBox mt={1} sx={{ width: "80%" }}>
                          <Alert sx={{ fontSize: "13px" }} severity="warning">
                            {bulkError}
                          </Alert>
                        </MDBox>
                      )}

                      <MDBox
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        mt={2}
                      >
                        <Button
                          type="submit"
                          disabled={selectedFiles.length === 0 || progress > 0}
                          variant="contained"
                          sx={{
                            backgroundImage:
                              "linear-gradient(90deg, #5870FD 0%, #F167C9 100%)",
                            "&:hover": {
                              backgroundImage:
                                "linear-gradient(90deg, #5870FD 0%, #F167C9 80%)",
                            },
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#ffffff",
                            borderRadius: "4px",
                            width: "80%",
                          }}
                          size="medium"
                        >
                          {buttonLoading ? (
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="80%"
                            >
                              {progress !== 100 ? (
                                <MDCircular
                                  value={progress}
                                  sx={{ color: "#ffffff" }}
                                />
                              ) : (
                                <MDBox display="flex" alignItems="center">
                                  <MDTypography
                                    variant="caption"
                                    sx={{
                                      color: "#ffffff",
                                      mr: 1,
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Processing...
                                  </MDTypography>
                                  <CircularProgress sx={{ color: "#ffffff" }} />
                                </MDBox>
                              )}
                            </MDBox>
                          ) : (
                            <MDTypography
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#ffffff",
                              }}
                            >
                              Upload
                            </MDTypography>
                          )}
                        </Button>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </DialogContent>
                {auditFiles?.data?.length > 0 && (
                  <MDBox>
                    <MDBox mx={3}>
                      {auditFiles?.success ? (
                        <Alert severity="success" sx={{ marginBottom: 2 }}>
                          Songs added succesfully
                        </Alert>
                      ) : (
                        <Alert
                          variant="filled"
                          severity="error"
                          sx={{ marginBottom: 2, fontWeight: "bold" }}
                        >
                          {getErrorMessage(auditFiles.message)}
                          {/* {auditFiles.message.includes(".cp")
                            ? "CP not found."
                            : auditFiles.message.includes("readStream")
                            ? "Error inside excel file"
                            : auditFiles.message.includes("InvalidFileNames")
                            ? "Invalid File Names"
                            : auditFiles.message.includes(".excel")
                            ? "Excel file name is not correct."
                            : auditFiles.message.includes(
                                "NoFileUploaded_InvalidFileNames"
                              )
                            ? "Files alredy existed."
                            : auditFiles.message.includes(".internal")
                            ? "Internal error occurred."
                            : "Server error occurred"} */}
                        </Alert>
                      )}
                    </MDBox>
                    <MDBox
                      fullWidth
                      border={1}
                      borderColor="rgba(0, 0, 0, 0.3)"
                      borderRadius="8px"
                      p={2}
                      boxShadow={3}
                      mx={3}
                      mb={4}
                      maxHeight="400px"
                      overflow="auto"
                    >
                      <Alert
                        severity="error"
                        sx={{ marginBottom: 2, fontWeight: "bold" }}
                      >
                        The following files encountered errors:
                      </Alert>
                      <MDBox
                        component="table"
                        sx={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <MDTypography
                              component="th"
                              variant="body2"
                              fontWeight="bold"
                              sx={{
                                padding: "8px",
                                borderBottom: "1px solid #ddd",
                                textAlign: "left",
                              }}
                            >
                              File Name
                            </MDTypography>
                            <MDTypography
                              component="th"
                              variant="body2"
                              fontWeight="bold"
                              sx={{
                                padding: "8px",
                                borderBottom: "1px solid #ddd",
                                textAlign: "left",
                              }}
                            >
                              Error
                            </MDTypography>
                          </tr>
                        </thead>
                        <tbody>
                          {auditFiles.data.map((audit, index) => (
                            <tr key={index}>
                              <MDTypography
                                component="td"
                                variant="body2"
                                sx={{
                                  padding: "8px",
                                  borderBottom: "1px solid #ddd",
                                  wordBreak: "break-all",
                                }}
                              >
                                {audit.fileName}
                              </MDTypography>
                              <MDTypography
                                component="td"
                                variant="body2"
                                sx={{
                                  padding: "8px",
                                  borderBottom: "1px solid #ddd",
                                  color: "#d32f2f",
                                  wordBreak: "break-all",
                                }}
                              >
                                {audit.error.includes("ENOENT")
                                  ? "Invalid file name"
                                  : audit.error.includes("already exists")
                                  ? "file already exists"
                                  : audit.error}
                              </MDTypography>
                            </tr>
                          ))}
                        </tbody>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                )}

                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="95%"
                  pr={1}
                  pl={4}
                >
                  <MDTypography
                    variant="body1"
                    color="primary"
                    sx={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={songsMetadata}
                      download="songsMetadata.xlsx"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DownloadIcon sx={{ marginRight: "4px" }} />
                      Download Excel File
                    </a>
                  </MDTypography>

                  <MDTypography
                    variant="body1"
                    color="primary"
                    onClick={() => {
                      setShowInfo((prev) => !prev);
                    }}
                    sx={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {!showInfo ? (
                      "Need Help?"
                    ) : (
                      <MDBox display="flex" alignItems="center">
                        <MDTypography
                          variant="body1"
                          color="primary"
                          sx={{
                            cursor: "pointer",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Close
                        </MDTypography>
                        <CancelIcon
                          sx={{ color: "primary.main", fontSize: 24 , marginLeft: "4px"}}
                        />
                      </MDBox>
                    )}
                  </MDTypography>
                </MDBox>

                <MDTypography
                  variant="body2"
                  sx={{
                    marginBottom: "8px",
                    color: selectedFiles.length ? "green" : "red",
                  }}
                >
                  {showInfo && <Instructions />}
                </MDTypography>
              </form>
            </Dialog>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SongsList;
