import { useState, useEffect, createContext } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Box from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Autocomplete } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { baseUrl } from "base";
// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import Swal from "sweetalert2";
// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import pianoKeys from "assets/images/piano-keys.webp";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import apiData from "store/apislice";
import { createApiAction } from "store/ApiServices";
import axiosInstance from "axiosInstance";
import { countries } from "assets/countries/Countries";
import { languages } from "assets/languages/Languages";
import { getData } from "layouts/CPprofile/cpThunk";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Cancel as CancelIcon, SearchOutlined } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import profileDefault from "assets/images/Profile-default.png";
import { CircularProgress } from "@mui/material";
import MDProgress from "components/MDProgress";
import MDCircular from "components/MDCircular";

function Header({ children, setUser }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [genreData, setGenreData] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [typeData, setTypeData] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.apiData.data.data);
  const [formErrors, setFormErrors] = useState({});
  const { id } = useParams();
  const userId = { artist_id: user.artist_id };
  const [buttonLoading, setButtonLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const getGenreData = async (value) => {
    try {
      const response = await axiosInstance.get(
        "/api/dev/admin/genre/getAllGenres"
      );
      setGenreData(response.data.data);
    } catch (e) {
      console.log(e.message);
    }
  };
  useEffect(() => {
    getGenreData();
  }, []);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    dispatch(getData());
  }, []);

  const cpData = useSelector((state) => state.cpData.Cp);
  const [activeTab, setActiveTab] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [editForm, setEditForm] = useState({
    name: "",
    mobile: "",
    image_url: "",
    email: "",
    dob: "",
    gender: "",
    country: "",
  });

  const [formData, setFormData] = useState({
    song_title: "",
    artist_id: "",
    artist_name: "",
    cp_id: "",
    country: "",
    language: "",
    genre_id: "",
    duration: "",
    size: "",
    playlist_type: "",
    playlist_id: "",
    record_label: "",
    song: "",
    cover_image: "",
    cp_name: "",
  });

  const handleArtistDelete = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you want to delete this Artist?",
      html: '<p style="color: red;">Deleting this artist will remove all related data. <strong>This action cannot be undone.</strong></p>',
      showDenyButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/artist/deleteArtist", userId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Artist Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            navigate(-1);
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "There was an error deleting this Artist.",
            });
          });
      }
    });
  };

  const callTypeData = async (value) => {
    const CategoryType = {
      category_type: value,
    };
    try {
      const response = await axiosInstance.post(
        "/api/dev/playlist/getCategoryByType",
        CategoryType
      );
      setTypeData(response.data.data.categoryList);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddUserClick = () => {
    setIsAddUserDialogOpen(true);
    setFormData({
      ...formData,
      artist_id: user?.artist_id,
      artist_name: user?.name,
      cp_id: user?.cp_id,
    });
  };

  const handleEditUserClick = () => {
    setIsEditUserDialogOpen(true);
    setButtonLoading(false);
    const initialForm = {
      name: user?.name || "",
      email: user?.email || "",
      mobile: user?.mobile || "",
      dob: user?.DOB || "",
      gender: user?.gender || "",
      country: user?.country || "",
      image_url: null,
    };
    setEditForm(initialForm);
  };
  const handleEditClose = () => {
    console.log(editForm);
    setFormErrors({});
    setIsEditUserDialogOpen(false);
    setImagePreview(null);
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    const userId = {
      artist_id: user.artist_id,
    };
    const newFormData = new FormData();

    for (const key in editForm) {
      if (key in user) {
        if (
          editForm[key] !== "" &&
          editForm[key] !== user[key] &&
          editForm[key] !== null &&
          key !== "image_url" &&
          key !== "dob"
        ) {
          newFormData.append(key, editForm[key]);
        }
      }
    }
    if (editForm.dob !== user.DOB && editForm.dob !== "") {
      newFormData.append("dob", editForm.dob);
    }
    if (editForm.image_url !== null) {
      newFormData.append("image", editForm.image_url);
    }

    newFormData.append("artist_id", user.artist_id);

    try {
      await axiosInstance.post(
        "/api/dev/Artist/updateArtistProfile",
        newFormData
      );
      Swal.fire({
        title: "Profile Updated!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });

      await dispatch(
        createApiAction("/api/dev/artist/getArtistById", "POST", userId)
      );
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        icon: "error",
      });
      console.log(e.message);
    }
    setButtonLoading(false);

    handleEditClose();
  };

  const handleCloseDialogs = () => {
    setIsAddUserDialogOpen(false);
    setImagePreview(null);
    setFormData({
      song_title: "",
      artist_id: "",
      artist_name: "",
      cp_id: "",
      country: "",
      language: "",
      genre_id: "",
      duration: "",
      size: "",
      playlist_type: "",
      playlist_id: "",
      record_label: "",
      song: "",
      cover_image: "",
      cp_name: "",
    });
  };

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;
    if (name === "type") {
      setFormData({ ...formData, playlist_type: value });
      if (value === "playlist" || value === "album") {
        callTypeData(value);
      }
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleFileInputChange = (event) => {
    const { id } = event.target;
    const file = event.target.files[0];
    setFormData({ ...formData, [id]: file });
    if (file && id !== "song") {
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  const handleEditChange = (event) => {
    const { id, value, name } = event.target;
    let error = "";
    if (name === "mobile") {
      if (!/^[1-9]\d{9}$/.test(value)) {
        error = "Contact number must be exactly 10 digits";
      }
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Enter valid email address.";
      }

      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }

    if (name === "gender") {
      setEditForm({ ...editForm, [name]: value });
    } else {
      setEditForm({ ...editForm, [id]: value });
    }
  };

  const closeDialog = () => {
    handleCloseDialogs();
  };
  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    setProgress(0);
    const newFormData = new FormData();
    newFormData.append("song_title", formData.song_title);
    newFormData.append("artist_id", user.artist_id);
    newFormData.append("artist_name", user.name);
    newFormData.append("cp_id", user.cp_id);
    newFormData.append("country", formData.country);
    newFormData.append("language", formData.language);
    newFormData.append("genre_id", formData.genre_id);
    newFormData.append("duration", formData.duration);
    newFormData.append("size", formData.size);
    newFormData.append("playlist_type", formData.playlist_type);
    // if (
    //   formData.playlist_type === "playlist" ||
    //   formData.playlist_type === "album"
    // ) {
    //   newFormData.append("playlist_id", formData.playlist_id);
    // }

    if (formData.playlist_type !== "single") {
      newFormData.append("playlist_id", formData.playlist_id);
    }

    newFormData.append("record_label", formData.record_label);
    newFormData.append("song", formData.song);
    newFormData.append("cover_image", formData.cover_image);

    // try {
    //   await axiosInstance.post("/api/dev/songs/addSong", newFormData);
    //   Swal.fire({
    //     title: "New Song Added",
    //     icon: "success",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    try {
      await axiosInstance.post("/api/dev/songs/addSong", newFormData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      Swal.fire({
        title: "New Song Added",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      dispatch(
        createApiAction("/api/dev/artist/getArtistById", "POST", userId)
      );
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        icon: "error",
      });
      console.log(e.message);
    }
    setButtonLoading(false);
    setImagePreview(null);
    closeDialog();
  };

  const handleCountryChange = (event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : "",
    });
  };
  const handleAutoComplete = (fieldName, lableName, valueName) => {
    return fieldName.find((item) => item[lableName] === valueName) || null;
  };
  const handleCountryEdit = (event, newValue) => {
    setEditForm({
      ...editForm,
      country: newValue ? newValue.label : "",
    });
  };
  const handleFileInputEdit = (event) => {
    const file = event.target.files[0];
    setEditForm({ ...editForm, image_url: file });
    if (file) {
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };
  const handleLanguage = (event, newValue) => {
    setFormData({
      ...formData,
      language: newValue ? newValue.name : "",
    });
  };

  const handleType = (e, newValue) => {
    setFormData({
      ...formData,
      playlist_id: newValue?.playlist_id ? newValue?.playlist_id : "",
    });
  };

  const handleGenre = (e, newValue) => {
    setFormData({
      ...formData,
      genre_id: newValue?.genre_id ? newValue.genre_id : "",
    });
  };

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="left"
        justifyContent="left"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.primary.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${pianoKeys})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <MDBox
          position="absolute"
          top="3%"
          left="1.5%"
          transform="translate(-50%, -50%)"
          textAlign="left"
          sx={{
            color: "rgba(255, 252, 255, 0.4)",
            fontSize: "2.5rem",
            // fontWeight: "bold",
            zIndex: 1,
          }}
        >
          Artist Profile
        </MDBox>
      </MDBox>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={baseUrl + user?.image_url}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {user?.name}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {user?.email}
              </MDTypography>
            </MDBox>
          </Grid>

          {/* Pop Starts */}
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                  gap: 1,
                  marginRight: { xs: 0, sm: "40px" },
                  flexWrap: "wrap",
                }}
              >
                <MDButton
                  onClick={handleAddUserClick}
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    width: { xs: "100%", sm: "auto" },
                  }}
                  size="small"
                >
                  <MDTypography
                    sx={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Add Song
                  </MDTypography>
                </MDButton>

                <MDButton
                  onClick={handleEditUserClick}
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    width: { xs: "100%", sm: "auto" },
                  }}
                  size="small"
                >
                  <MDTypography
                    sx={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Edit Profile
                  </MDTypography>
                </MDButton>

                <MDButton
                  onClick={handleArtistDelete}
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #e01212 0%, #e01212 100%)`,
                    width: { xs: "100%", sm: "auto" },
                  }}
                  size="small"
                >
                  <MDTypography
                    sx={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Delete Artist
                  </MDTypography>
                </MDButton>
              </MDBox>

              {/* </Tabs> */}

              {/* Add Songs Dialog */}
              <Dialog
                sx={{ overflow: "hidden" }}
                open={isAddUserDialogOpen}
                onClose={handleCloseDialogs}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>
                  Add Song
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CancelIcon
                      sx={{ color: purple[500] }}
                      onClick={handleCloseDialogs}
                    />
                  </span>
                  {imagePreview && (
                    <MDBox
                      height="200px"
                      width="300px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        margin: "0 auto",
                      }}
                    >
                      <img
                        src={imagePreview}
                        alt="Song Cover Image"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                          objectPosition: "center",
                          aspectRatio: "1 / 1",
                        }}
                      />
                    </MDBox>
                  )}
                </DialogTitle>
                <DialogContent
                  sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <form onSubmit={handleAddFormSubmit}>
                    <TextField
                      autoFocus
                      required
                      margin="dense"
                      id="song_title"
                      label="Song Title"
                      type="text"
                      fullWidth
                      value={formData.song_title}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 25 }}
                      helperText={
                        formData.song_title.length === 25
                          ? "25 character limit reached"
                          : ""
                      }
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />

                    <Autocomplete
                      sx={{ marginTop: "5px", marginBottom: "4px" }}
                      id="country-select-demo"
                      options={countries}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      onChange={handleCountryChange}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a country"
                          required
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    {/* 
<TextField
  margin="dense"
  id="language"
  label="Language"
  type="text"
  fullWidth

  value={formData.language}
  onChange={handleInputChange}
/> */}

                    <Autocomplete
                      sx={{ marginTop: "7px", marginBottom: "7px" }}
                      id="language-select"
                      options={languages}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      onChange={handleLanguage}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox key={key} component="li" {...optionProps}>
                            {option.name}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Language"
                          required
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      id="genre_id"
                      options={genreData}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.genre_title}
                      onChange={handleGenre}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox key={key} component="li" {...optionProps}>
                            {" "}
                            {option.genre_title}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Genre"
                          required
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />

                    <TextField
                      margin="dense"
                      id="duration"
                      required
                      label="Duration"
                      type="number"
                      fullWidth
                      value={formData.duration}
                      onChange={handleInputChange}
                    />
                    <TextField
                      margin="dense"
                      id="size"
                      label="Size"
                      type="number"
                      fullWidth
                      required
                      value={formData.size}
                      onChange={handleInputChange}
                    />

                    {/* <FormControl fullWidth  sx={{ marginTop:'7px', marginBottom:'4px'}}>
      <InputLabel fullWidth id="playlist_type" >Type</InputLabel>
      <Select
        labelId="gender-label"
        id="gender"
        name="type"
        value={formData.playlist_type}
        onChange={handleInputChange}
        label="Type"
        sx={{ height: '40px' }}
      >
        <MenuItem value="single">Single</MenuItem>
        <MenuItem value="playlist">Playlist</MenuItem>
        <MenuItem value="album">Album</MenuItem>
      </Select>
    </FormControl> */}

                    <FormControl
                      fullWidth
                      sx={{ marginTop: "7px", marginBottom: "4px" }}
                    >
                      <InputLabel fullWidth id="playlist_type">
                        Type
                      </InputLabel>
                      <Select
                        labelId="playlist_type"
                        id="type"
                        name="type"
                        value={formData.playlist_type}
                        onChange={handleInputChange}
                        label="Type"
                        required
                        IconComponent={ArrowDropDown}
                        sx={{ height: "40px" }}
                      >
                        <MenuItem value="single">Single</MenuItem>
                        <MenuItem value="playlist">Playlist</MenuItem>
                        <MenuItem value="album">Album</MenuItem>
                      </Select>
                    </FormControl>

                    {(formData.playlist_type === "playlist" ||
                      formData.playlist_type === "album") && (
                      <Autocomplete
                        id="playlist_id"
                        options={typeData}
                        fullWidth
                        required
                        getOptionLabel={(option) => option.title}
                        onChange={handleType}
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <MDBox key={key} component="li" {...optionProps}>
                              {option.title}
                            </MDBox>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            required={
                              formData.playlist_type === "playlist" ||
                              formData.playlist_type === "album"
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    )}

                    <TextField
                      margin="dense"
                      id="record_label"
                      label="record label"
                      type="text"
                      fullWidth
                      required
                      value={formData.record_label}
                      onChange={handleInputChange}
                    />

                    {/* <FormControl fullWidth sx={{ marginTop:'7px', marginBottom:'4px'}}>
  <InputLabel id="gender1">Gender</InputLabel>
  <Select
    labelId="gender1"
    id="gender"
    value={formData.gender}
    label="Gender"
    name="gender"
    onChange={handleInputChange}
    sx={{ height: '40px' }}
  >
    <MenuItem  value="male">Male</MenuItem>
    <MenuItem value="female">Female</MenuItem>
     <MenuItem value="other">Other</MenuItem>
  </Select>
</FormControl> */}
                    {/* 
<Autocomplete
        id="cp_id"
        options={cpData}
        getOptionLabel={(option) => option.name}
        fullWidth
        value={cpData.find(option => option.name === formData.cp_name) || null} 
        onChange={handleAutoComplete}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Content Provider"
            margin="dense"
            fullWidth
            
          />
        )}
      /> */}

                    <label
                      htmlFor="song"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "7px",
                        marginBottom: "12px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        id="song"
                        required
                        accept="audio/*"
                        onChange={handleFileInputChange}
                        style={{
                          position: "absolute",
                          width: "1px",
                          height: "1px",
                          opacity: 0,
                        }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Add Song
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "1px",
                        }}
                      >
                        {formData.song?.name}
                      </span>
                    </label>

                    <label
                      htmlFor="cover_image"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "7px",
                        marginBottom: "12px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        id="cover_image"
                        required
                        accept="image/*"
                        onChange={handleFileInputChange}
                        style={{
                          position: "absolute",
                          width: "1px",
                          height: "1px",
                          opacity: 0,
                        }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Add Cover Image
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "1px",
                        }}
                      >
                        {formData.cover_image?.name}
                      </span>
                    </label>

                    {/* Submit button */}
                    {/* <Button type="submit" variant="contained" fullWidth sx={{color:"black",bgcolor:"white"}}>
            Submit
          </Button> */}
                    <Button
                      type="submit"
                      fullWidth
                      sx={{
                        backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      }}
                      size="small"
                    >
                      {buttonLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <MDCircular value={progress} />
                        </MDBox>
                      ) : (
                        <MDTypography
                          style={{ fontSize: "13.5px", fontWeight: "bold" }}
                          color="white"
                        >
                          Submit
                        </MDTypography>
                      )}
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>

              {/* Edit User Dialog */}

              <Dialog
                open={isEditUserDialogOpen}
                onClose={handleEditClose}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>
                  Edit User
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CancelIcon
                      sx={{ color: purple[500] }}
                      onClick={handleEditClose}
                    />
                  </span>
                  <MDBox display="flex" justifyContent="center">
                    <MDAvatar
                      src={
                        imagePreview
                          ? imagePreview
                          : user?.image_url
                          ? baseUrl + user?.image_url
                          : profileDefault
                      }
                      alt="profile-image"
                      sx={{ height: "140px", width: "140px" }}
                      shadow="sm"
                    />
                  </MDBox>
                </DialogTitle>
                <DialogContent
                  sx={{
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <form onSubmit={handleEditFormSubmit}>
                    <label
                      htmlFor="image_url"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "11px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        id="image_url"
                        accept="image/*"
                        onChange={handleFileInputEdit}
                        style={{
                          position: "absolute",
                          width: "1px",
                          height: "1px",
                          opacity: 0,
                        }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Edit Profile Image
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "1px",
                          overflow: "hidden",
                        }}
                      >
                        {editForm.image_url?.name}
                      </span>
                    </label>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Full Name"
                      type="text"
                      fullWidth
                      value={editForm.name}
                      onChange={handleEditChange}
                      inputProps={{ maxLength: 25 }}
                      helperText={
                        editForm.name.length === 25
                          ? "25 character limit reached"
                          : ""
                      }
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />
                    <TextField
                      margin="dense"
                      id="email"
                      name="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      value={editForm.email}
                      onChange={handleEditChange}
                      error={Boolean(formErrors.email)}
                      helperText={formErrors.email}
                    />
                    <TextField
                      margin="dense"
                      id="mobile"
                      name="mobile"
                      label="Mobile"
                      type="text"
                      fullWidth
                      value={editForm.mobile}
                      onChange={handleEditChange}
                      inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                      error={!!formErrors.mobile}
                      helperText={formErrors.mobile}
                    />
                    <TextField
                      margin="dense"
                      id="dob"
                      label="Date of Birth"
                      type="date"
                      fullWidth
                      value={editForm.dob}
                      onChange={handleEditChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        placeholder: "Date of Birth",
                      }}
                    />
                    <FormControl
                      fullWidth
                      sx={{ marginTop: "7px", marginBottom: "4px" }}
                    >
                      <InputLabel id="gender1">Gender</InputLabel>
                      <Select
                        labelId="gender1"
                        id="gender"
                        value={editForm.gender}
                        label="Gender"
                        name="gender"
                        onChange={handleEditChange}
                        sx={{ height: "40px" }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                    <Autocomplete
                      sx={{ marginTop: "6.5px", marginBottom: "2px" }}
                      id="country-select-demo"
                      options={countries}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      value={handleAutoComplete(
                        countries,
                        "label",
                        editForm.country
                      )}
                      onChange={handleCountryEdit}
                      clearOnBlur
                      disableClearable
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a Country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      disabled={Object.values(formErrors).some(
                        (error) => error !== ""
                      )}
                      sx={{
                        backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                        marginTop: "10px",
                      }}
                      size="small"
                    >
                      {buttonLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={24}
                          />
                        </MDBox>
                      ) : (
                        <MDTypography
                          style={{ fontSize: "13.5px", fontWeight: "bold" }}
                          color="white"
                        >
                          Submit
                        </MDTypography>
                      )}
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
