/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import MDButton from "components/MDButton";
import adminImage from "assets/images/AdminImage.jpg";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Cancel as CancelIcon, SearchOutlined } from "@mui/icons-material";
import { purple } from "@mui/material/colors";
import profileDefault from "assets/images/Profile-default.png";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "assets/countries/Countries";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "base";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";

import axiosInstance from "axiosInstance";
import { createApiAction } from "store/ApiServices";
import { setAdmin } from "store/AdminSlice";
function Header({ children }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.adminData.AdminData);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [errorPwd, setErrorPwd] = useState({
    newPassword: "",
    confirmPassword: "",
  });
    const [formData, setFormData] = useState({
    admin_id: user?.user_id,
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
    setFormData({
      admin_id: user?.user_id,
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  const handleClose = () => {
    console.log("formdata", formData);
    setError(null);
    setOpen(false);
  };

  const [editForm, setEditForm] = useState({
    name: "",
    msisdn: "",
    image_url: "",
    DOB: "",
    gender: "",
    country: "",
  });
  const handleEditUserClick = () => {
    setIsEditUserDialogOpen(true);
    const initialForm = {
      name: user?.name || "",
      email: user?.email || "",
      msisdn: user?.msisdn || "",
      DOB: user?.DOB || "",
      gender: user?.gender || "",
      country: user?.country || "",
      image_url: null,
    };
    setEditForm(initialForm);
  };

  const handleAutoComplete = (fieldName, lableName, valueName) => {
    return fieldName.find((item) => item[lableName] === valueName) || null;
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();

    for (const key in editForm) {
      if (key in user) {
        if (
          editForm[key] !== "" &&
          editForm[key] !== user[key] &&
          editForm[key] !== null &&
          key !== "image_url"
        ) {
          console.log(key, editForm[key]);
          newFormData.append(key, editForm[key]);
        }
      }
    }
    if (editForm.image_url !== null) {
      newFormData.append("image", editForm.image_url);
    }
    newFormData.append("admin_id", user.user_id);

    try {
      const updateResponse = await axiosInstance.post(
        "/api/dev/admin/auth/updateAdminProfile",
        newFormData
      );
      setButtonLoading(false);
      handleEditClose();
      Swal.fire({
        title: "Profile Updated!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    
      const adminResponse = await axiosInstance.post(
        "/api/dev/admin/auth/getAdminDetails",
        {
          admin_id: user.user_id,
        }
      );
    
      dispatch(setAdmin(adminResponse.data.data));
    
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        icon: "error",
      });
      console.log(e.message);
      setButtonLoading(false);
      handleEditClose();
    }

  };
  const handleEditClose = () => {
    console.log(editForm);
    setIsEditUserDialogOpen(false);
    setImagePreview(null);
    setFormErrors({});
  };

  const handleEditChange = (event) => {
    const { id, value, name } = event.target;

    let error = "";

    if (id === "msisdn") {
      if (!/^[1-9]\d{9}$/.test(value)) {
        error = "Contact number must be exactly 10 digits";
      }
      setFormErrors({
        ...formErrors,
        [id]: error,
      });
    } else if (id === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Enter valid email address.";
      }
      setFormErrors({
        ...formErrors,
        [id]: error,
      });
    }

    if (name === "gender") {
      setEditForm({ ...editForm, [name]: value });
    } else {
      setEditForm({ ...editForm, [id]: value });
    }
  };

  const handleCountryEdit = (event, newValue) => {
    setEditForm({
      ...editForm,
      country: newValue ? newValue.label : "",
    });
  };

  const handleFileInputEdit = (event) => {
    const file = event.target.files[0];
    setEditForm({ ...editForm, image_url: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "newPassword" || name === "confirmPassword") {
      const isValidPassword = (password) =>
        /[a-zA-Z]/.test(password) && /\d/.test(password) && password.length >= 8;
  
      setErrorPwd((prevErrors) => ({
        ...prevErrors,
        [name]: isValidPassword(value) ? "" : "Password must be at least 8 characters long, contain a letter, and a number.",
      }));
    }

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }
    setButtonLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.post(
        "/api/dev/admin/auth/adminResetPassword",
        {
          admin_id: formData.admin_id,
          old_password: formData.password,
          new_password: formData.newPassword,
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Password Changed!",
          timer: 1500,
          showConfirmButton: false,
        });
        //   await axiosInstance.post("/api/dev/admin/auth/getAdminDetails",{
        //     "admin_id": user.user_id
        // });
        handleClose();
      } else {
        setError(error.response.data.message);
      }
    } catch (error) {
      console.error("API call failed:", error);
      setError(error.response.data.message);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    const cursorPosition = inputRef.current.selectionStart;
    setShowPassword(!showPassword);

    // Wait for the state to be updated
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        inputRef.current.focus();
      }
    }, 0);
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleApiCall = async () => {
    await axiosInstance.post("/api/dev/admin/auth/getAdminDetails", {
      admin_id: user.user_id,
    });
  };

  // useEffect(()=>{
  //   handleApiCall()
  // },[user])
  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${adminImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={user?.image_url ? baseUrl + user?.image_url : profileDefault}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {user?.name}
              </MDTypography>
              {/* <MDTypography variant="button" color="text" fontWeight="regular">
                CEO / Co-Founder
              </MDTypography> */}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "40px",
                }}
              >
                <MDButton
                  onClick={handleEditUserClick}
                  sx={{
                    backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                  }}
                  size="small"
                >
                  <MDTypography
                    style={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Edit Profile
                  </MDTypography>
                </MDButton>
                <MDBox ml={2}>
                  <MDButton
                    sx={{
                      backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                    }}
                    size="small"
                    onClick={handleClickOpen}
                  >
                    <MDTypography
                      style={{ fontSize: "13.5px", fontWeight: "bold" }}
                      color="white"
                    >
                      Change Password
                    </MDTypography>
                  </MDButton>
                </MDBox>
              </div>
              {/* Change Password */}
              <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                  Change Password
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CancelIcon
                      sx={{ color: purple[500] }}
                      onClick={handleClose}
                    />
                  </span>
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    {error && (
                      <div
                        className="alert alert-danger"
                        role="alert"
                        style={{
                          fontSize: "0.8em",
                          padding: "0.5em",
                          borderRadius: "0.25em",
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </div>
                    )}
                    <TextField
                      margin="dense"
                      label="Current Password"
                      name="password"
                      type="password"
                      required
                      fullWidth
                      variant="outlined"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <TextField
                      margin="dense"
                      label="New Password"
                      name="newPassword"
                      type="password"
                      required
                      fullWidth
                      variant="outlined"
                      value={formData.newPassword}
                      onChange={handleChange}
                      error={!!errorPwd.newPassword}
                      helperText={errorPwd.newPassword}
                    />
                    <TextField
                      margin="dense"
                      label="Confirm New Password"
                      name="confirmPassword"
                      required
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      variant="outlined"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      inputRef={inputRef}
                      error={!!errorPwd.confirmPassword}
                      helperText={errorPwd.confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <MDButton
                      type="submit"
                      color="primary"
                      disabled={buttonLoading ||errorPwd.confirmPassword || errorPwd.newPassword}
                    >
                      {buttonLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={24}
                          />
                        </MDBox>
                      ) : (
                        "Submit"
                      )}
                    </MDButton>
                  </DialogActions>
                </form>
              </Dialog>
              {/* Edit Profile */}
              <Dialog open={isEditUserDialogOpen} onClose={handleEditClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                  Edit Profile
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CancelIcon
                      sx={{ color: purple[500] }}
                      onClick={handleEditClose}
                    />
                  </span>
                  <MDBox display="flex" justifyContent="center">
                    <MDAvatar
                      src={
                        imagePreview
                          ? imagePreview
                          : user?.image_url
                          ? baseUrl + user?.image_url
                          : profileDefault
                      }
                      alt="profile-image"
                      sx={{ height: "140px", width: "140px" }}
                      shadow="sm"
                    />
                  </MDBox>
                </DialogTitle>
                <DialogContent sx={{ overflowX: "hidden" }}>
                  <form onSubmit={handleEditFormSubmit}>
                  <label
                      htmlFor="image_url"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "11px",
                        marginBottom:"4px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        id="image_url"
                        accept="image/*"
                        onChange={handleFileInputEdit}
                        style={{ display: "none" }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Edit Profile Image
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "1px",
                        }}
                      >
                        {editForm.image_url?.name}
                      </span>
                    </label>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Full Name"
                      type="text"
                      fullWidth
                      value={editForm.name}
                      onChange={handleEditChange}
                      inputProps={{ maxLength: 25 }}
                      helperText={editForm.name.length === 25 ? "25 character limit reached" : ""}
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />
                    {/* <TextField
                      margin="dense"
                      id="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      value={editForm.email}
                      onChange={handleEditChange}
                      error={Boolean(formErrors.email)}
                      helperText={formErrors.email}
                    /> */}
                    <TextField
                      margin="dense"
                      id="msisdn"
                      label="Mobile"
                      type="text"
                      fullWidth
                      value={editForm.msisdn}
                      onChange={handleEditChange}
                      inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                      error={!!formErrors.msisdn}
                      helperText={formErrors.msisdn}
                    />
                    <TextField
                      margin="dense"
                      id="DOB"
                      label="Date of Birth"
                      type="date"
                      fullWidth
                      value={editForm.DOB}
                      onChange={handleEditChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        placeholder: "Date of Birth",
                      }}
                    />
                    <FormControl
                      fullWidth
                      sx={{ marginTop: "7px", marginBottom: "4px" }}
                    >
                      <InputLabel id="gender1">Gender</InputLabel>
                      <Select
                        labelId="gender1"
                        id="gender"
                        value={editForm.gender}
                        label="Gender"
                        name="gender"
                        onChange={handleEditChange}
                        sx={{ height: "40px" }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                    <Autocomplete
                      sx={{ marginTop: "6.5px", marginBottom: "2px" }}
                      id="country-select-demo"
                      options={countries}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      onChange={handleCountryEdit}
                      value={handleAutoComplete(
                        countries,
                        "label",
                        editForm.country
                      )}
                      clearOnBlur
                      disableClearable
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a Country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                 
                    <Button
                      type="submit"
                      fullWidth
                      disabled={Object.values(formErrors).some(
                        (error) => error !== ""
                      )}
                      sx={{
                        backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                        marginTop: "11px",
                      }}
                      size="small"
                    >
                      {buttonLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={24}
                          />
                        </MDBox>
                      ) : (
                        <MDTypography
                          style={{ fontSize: "13.5px", fontWeight: "bold" }}
                          color="white"
                        >
                          Submit
                        </MDTypography>
                      )}
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
