import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import MDAvatar from "components/MDAvatar";
import { baseUrl } from "base";
import axiosInstance from "axiosInstance";
import Checkbox from "@mui/material/Checkbox";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Button } from "@mui/material";
// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { pink } from "@mui/material/colors";
import { purple } from "@mui/material/colors";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { createApiAction } from "store/ApiServices";
import { SearchOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import { countries } from "assets/countries/Countries";
import { languages } from "assets/languages/Languages";
import { getData } from "layouts/CPprofile/cpThunk";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import CancelIcon from "@mui/icons-material/Cancel";
import { setSong } from "store/SongSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import Pagination from "@mui/material/Pagination";
import { CircularProgress } from "@mui/material";
import { capitalizeFirstLetter } from "examples/CommonFunctions";
import { ThemeProvider } from "@mui/material/styles";
import { switchTheme } from "examples/CommonFunctions";

function ArtistSongs() {
  const [genreData, setGenreData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [artistData, setArtistData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const songData = useSelector((state) =>
    state.apiData.data.data.songs
      ? state.apiData.data.data.songs
      : state.apiData.data.data.songsSearchResult
  );
  // const { totalCount } = useSelector((state) => state.apiData.data.data);
  const { artist_id: ArtistId, name: artistName } = useSelector(
    (state) => state.apiData.data.data
  );
   
  const timeoutRef = useRef(null);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [typeData, setTypeData] = useState([]);
  const [albumData, setAlbumData] = useState([]);
  const [playlisData, setPlaylistData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);

  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase().trim();
    setSearchQuery(query);

    const filtered = songData?.filter((song) =>
      song.song_title.toLowerCase().includes(query)||
    song.language.toLowerCase().includes(query)
    );

    setFilteredItems(filtered);
    setLoading(false);
  };

  // const callTypeData = async (value) => {
  //   const CategoryType = {
  //     category_type: value,
  //   };
  //   try {
  //     const response = await axiosInstance.post(
  //       "/api/dev/playlist/getCategoryByType",
  //       CategoryType
  //     );
  //     setTypeData(response.data.data.categoryList);
  //   } catch (e) {
  //     console.log(e.message);
  //   }
  // };

  const [initialForm, setInitialForm] = useState({
    song_title: "",
    artist_id: "",
    artist_name: "",
    cp_id: "",
    country: "",
    language: "",
    genre_id: "",
    duration: "",
    size: "",
    playlist_type: "",
    playlist_id: "",
    record_label: "",
    song: "",
    cover_image: "",
  });
  const [formData, setFormData] = useState({
    song_id: "",
    song_title: "",
    artist_id: "",
    artist_name: "",
    cp_id: "",
    country: "",
    language: "",
    genre_id: "",
    duration: "",
    size: "",
    playlist_type: "",
    playlist_id: "",
    record_label: "",
    song: "",
    cover_image: "",
  });

  const handleAddUserClick = (song) => {
    const initialFormState = {
      song_id: song.song_id,
      song_title: song.song_title,
      artist_id: song.artist_id,
      artist_name: song.artist_name,
      cp_id: song.cp_id,
      country: song.country,
      language: song.language,
      genre_id: song.genre_id,
      duration: song.duration,
      size: song.size,
      playlist_type: song.playlist_type,
      playlist_id: song.playlist_id,
      record_label: song.record_label,
      song: null,
      cover_image: null,
    };

    setInitialForm(initialFormState);
    setFormData(initialFormState);
    setIsAddUserDialogOpen(true);
  };

  const handleCloseDialogs = () => {
    setImagePreview(null);
    setIsAddUserDialogOpen(false);
  };

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;
    // if (name === "type") {
    //   setFormData({ ...formData, playlist_type: value });
    //   if (value === "playlist" || value === "album") {
    //     callTypeData(value);
    //   }
    // } else {
      setFormData({ ...formData, [id]: value });
    // }
  };

  const handleFileInputChange = (event) => {
    const { id } = event.target;
    const file = event.target.files[0];
    setFormData({ ...formData, [id]: file });
    if (file && id !== "song") {
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  const handleCountryChange = (event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : "",
    });
  };

  const handleAutoComplete = (fieldName, lableName, valueName) => {
    return fieldName.find((item) => item[lableName] === valueName) || null;
  };

  const handleLanguage = (event, newValue) => {
    setFormData({
      ...formData,
      language: newValue ? newValue.name : "",
    });
  };

  const handleType = (e, newValue, id) => {
    setFormData({
      ...formData,
      [id]: newValue[id] ? newValue[id] : "",
    });
  };


  const handleBan = (song) => {
    const BanSongId = {
      song_id: song.song_id,
    };
    const status = song.is_ban ? "Enable" : "Disable";
    Swal.fire({
      icon: "warning",
      title: `Do you want to ${status} this song?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/songs/songBaned", BanSongId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Done!",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(
              createApiAction("/api/dev/artist/getArtistById", "POST", {
                artist_id: ArtistId,
              })
            );
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
            });
          });
      }
    });
  };
  const handleSong = (value) => {
   
    dispatch(setSong(value));
  };
  const excludedKeys = new Set([
    "cover_image",
    "playlist_id",
    "song",
    "song_id",
    "artist_id",
    "playlist_type",
  ]);
  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();
    newFormData.append("artist_id", formData.artist_id);
    newFormData.append("song_id", formData.song_id);
    Object.keys(formData).forEach((key) => {
      if (
        !excludedKeys.has(key) &&
        formData[key] !== initialForm[key] &&
        formData[key] !== null
      ) {
        if ((key === "duration" || key === "size") && formData[key] === "") {
          newFormData.append(key, 0);
        } else {
          newFormData.append(key, formData[key]);
        }
      }
    });

    if (formData.cover_image !== null) {
      newFormData.append("cover_image", formData.cover_image);
    }
    if (formData.song !== null) {
      newFormData.append("song", formData.song);
    }

    try {
      const response = axiosInstance
        .post("/api/dev/songs/updateSong", newFormData)
        .then(() => {
          dispatch(
            createApiAction("/api/dev/artist/getArtistById", "POST", {
              artist_id: ArtistId,
            })
          );
          Swal.fire({
            title: "Song Updated",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          setButtonLoading(false);
          handleCloseDialogs();
        });
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        icon: "error",
      });
      console.log(e.message);
      setButtonLoading(false);
      handleCloseDialogs();
    }
  };

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={baseUrl + image}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const handleSongRemove = (removeSong) => {
    const songRemoveId = {
      song_id: removeSong,
    };
    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this Song?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/songs/deleteSong", songRemoveId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Song Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(
              createApiAction("/api/dev/artist/getArtistById", "POST", {
                artist_id: ArtistId,
              })
            );
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "There was an error removing this Song.",
            });
          });
      }
    });
  };
  const handleRows = () => {
    const Data = searchQuery.trim() !== "" ? filteredItems : songData;
    const rowsAPI = Data?.map((song) => ({
      Action: (
        <MDBox>
          <PlayCircleFilledIcon
            onClick={() => {
              handleSong(song);
            }}
            sx={{ cursor: "pointer",color: song.is_ban ? "red" :  purple[500] }}
            fontSize="large"
          />

          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => {
              handleAddUserClick(song);
            }}
            sx={{ marginLeft: "3px" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => {
              handleSongRemove(song.song_id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </MDBox>
      ),
      Image: (
        <MDBox display="flex" py={1}>
          {avatars([[song.cover_image_url, song.song_title]])}
        </MDBox>
      ),
      Title: capitalizeFirstLetter(song.song_title),
      Language: (
        capitalizeFirstLetter(song.language)
      ),
      Disable: (
        <ThemeProvider theme={switchTheme}>
          <MDBox>
            <Switch
              checked={song.is_ban}
              onChange={() => handleBan(song)}  
            />
          </MDBox>
        </ThemeProvider>
      ),
      DisableValue: song.is_ban ? 1 : 0,
    }));

    setRows(rowsAPI);
    setLoading(true);
  };
 

  const columns = [
    {
      Header: "Image",
      accessor: "Image",
      width: "10%",
      align: "center",
      disableSortBy: true,
    },
    { Header: "Title", accessor: "Title", align: "center" },
    // { Header: "Artist", accessor: "Artist", align: "center" },
    { Header: "Language", accessor: "Language", align: "center" },
    {
      Header: "Disable",
      accessor: "Disable",
      align: "center",
      sortType: (rowA, rowB) => {
        return rowA.original.DisableValue - rowB.original.DisableValue; 
      },
    },
    {
      Header: "Action",
      accessor: "Action",
      align: "center",
      disableSortBy: true,
    },
  ];

  const getGenreData = async (value) => {
    try {
      const response = await axiosInstance.get(
        "/api/dev/admin/genre/getAllGenres"
      );
      setGenreData(response.data.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getGenreData();
  }, []);

  useEffect(() => {
    handleRows();
  }, [songData, searchQuery]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  p: 3,
                }}
              >
                <MDBox
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-start" },
                    mb: { xs: 2, sm: 0 },
                  }}
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    textTransform="capitalize"
                  >
                    {`${artistName}'s Songs (${rows?.length})`}
                  </MDTypography>
                </MDBox>

                <MDBox
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-end" },
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <TextField
                    onChange={handleSearchChange}
                    id="standard-bare"
                    variant="outlined"
                    placeholder="Search Song"
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "#ffffff",
                        width: { xs: "100%", sm: "240px" },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <SearchOutlined color="white" />
                        </IconButton>
                      ),
                    }}
                  />
                </MDBox>
              </MDBox>

              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    isPagination={true}
                  />
                ) : (
                  <MDBox
                    sx={{
                      display: "flex",
                      padding: "20px",
                      justifyContent: "center",
                    }}
                  >
                    <MDTypography variant="caption" sx={{ fontSize: "20px" }}>
                      {"No Song Found."}
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
              {/* {loading &&
                (<MDBox ml={4}>
                  <MDTypography style={{ fontSize: '23.5px', fontWeight: 'bold' }} color="dark">
                    Loading...
                  </MDTypography>
                </MDBox>)} */}
              <Dialog open={isAddUserDialogOpen} onClose={handleCloseDialogs}>
                <DialogTitle>
                  Edit Song
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <CancelIcon
                      sx={{ color: purple[500] }}
                      onClick={handleCloseDialogs}
                    />
                  </span>
                </DialogTitle>
                <DialogContent sx={{ width: "500px", overflow: "auto" }}>
                  {imagePreview && (
                    <MDBox
                      height="200px"
                      width="300px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        margin: "0 auto",
                      }}
                    >
                      <img
                        src={imagePreview}
                        alt="Song Cover Image"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                      />
                    </MDBox>
                  )}
                  <form onSubmit={handleAddFormSubmit}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="song_title"
                      label="Song Title"
                      type="text"
                      fullWidth
                      value={formData.song_title}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 25 }}
                      helperText={
                        formData.song_title.length === 25
                          ? "25 character limit reached"
                          : ""
                      }
                      FormHelperTextProps={{
                        sx: { color: "red" },
                      }}
                    />

                    <Autocomplete
                      sx={{ marginTop: "5px", marginBottom: "4px" }}
                      id="country-select-demo"
                      options={countries}
                      // value={getInitialCountry(formData.country)}
                      value={handleAutoComplete(
                        countries,
                        "label",
                        formData.country
                      )}
                      fullWidth
                      autoHighlight
                      clearOnBlur
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      onChange={handleCountryChange}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a country"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      sx={{ marginTop: "7px", marginBottom: "7px" }}
                      id="language-select"
                      options={languages}
                      value={handleAutoComplete(
                        languages,
                        "name",
                        formData.language
                      )}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      onChange={handleLanguage}
                      clearOnBlur
                      disableClearable
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox key={key} component="li" {...optionProps}>
                            {option.name}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Language"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    {/* <Autocomplete
                      sx={{ marginTop: '5px', marginBottom: '4px' }}
                      id="artist_id"
                      options={artistData}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      onChange={handleArtist}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >

                            {option.name}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Artist"
                          inputProps={{
                            ...params.inputProps,

                          }}

                        />
                      )}
                    /> */}
                    {/* <Autocomplete
                      sx={{ marginTop: '5px', marginBottom: '4px' }}
                      id="cp_id"
                      options={cpData}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      onChange={handleCp}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox
                            key={key}
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...optionProps}
                          >

                            {option.name}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select CP"
                          inputProps={{
                            ...params.inputProps,

                          }}

                        />
                      )}
                    /> */}

                    <Autocomplete
                      id="genre_id"
                      options={genreData}
                      fullWidth
                      autoHighlight
                      getOptionLabel={(option) => option.genre_title}
                      onChange={(e, newValue) => {
                        handleType(e, newValue, "genre_id");
                      }}
                      value={handleAutoComplete(
                        genreData,
                        "genre_id",
                        formData.genre_id
                      )}
                      clearOnBlur
                      disableClearable
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <MDBox key={key} component="li" {...optionProps}>
                            {" "}
                            {option.genre_title}
                          </MDBox>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Genre"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />

                    <TextField
                      margin="dense"
                      id="duration"
                      label="Duration"
                      type="text"
                      fullWidth
                      value={formData.duration}
                      onChange={handleInputChange}
                    />
                    <TextField
                      margin="dense"
                      id="size"
                      label="Size"
                      type="text"
                      fullWidth
                      value={formData.size}
                      onChange={handleInputChange}
                    />

                    {/* <FormControl
                      fullWidth
                      sx={{ marginTop: "7px", marginBottom: "4px" }}
                    >
                      <InputLabel fullWidth id="playlist_type">
                        Type
                      </InputLabel>
                      <Select
                        labelId="playlist_type"
                        id="type"
                        name="type"
                        value={formData.playlist_type}
                        onChange={handleInputChange}
                        label="Type"
                        IconComponent={ArrowDropDown}
                        sx={{ height: "40px" }}
                      >
                        <MenuItem value="single">Single</MenuItem>
                        <MenuItem value="playlist">Playlist</MenuItem>
                        <MenuItem value="album">Album</MenuItem>
                      </Select>
                    </FormControl>

                    {(formData.playlist_type === "playlist" ||
                      formData.playlist_type === "album") && (
                      <Autocomplete
                        id="playlist_id"
                        options={typeData}
                        fullWidth
                        getOptionLabel={(option) => option.title}
                        onChange={(e, newValue) => {
                          handleType(e, newValue, "playlist_id");
                        }}
                        value={handleAutoComplete(
                          typeData,
                          "playlist_id",
                          formData.playlist_id
                        )}
                        clearOnBlur
                        disableClearable
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <MDBox key={key} component="li" {...optionProps}>
                              {option.title}
                            </MDBox>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            required={
                              formData.playlist_type === "playlist" ||
                              formData.playlist_type === "album"
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    )} */}

                    <TextField
                      margin="dense"
                      id="record_label"
                      label="record label"
                      type="text"
                      fullWidth
                      value={formData.record_label}
                      onChange={handleInputChange}
                    />

                    <label
                      htmlFor="song"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "7px",
                        marginBottom: "12px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        id="song"
                        accept="audio/*"
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Update Song
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "1px",
                        }}
                      >
                        {formData.song?.name}
                      </span>
                    </label>

                    <label
                      htmlFor="cover_image"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "2px",
                        marginTop: "7px",
                        marginBottom: "12px",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="file"
                        id="cover_image"
                        accept="image/*"
                        onChange={handleFileInputChange}
                        style={{
                          position: "absolute",
                          width: "1px",
                          height: "1px",
                          opacity: 0,
                        }}
                      />
                      <Button component="span" color="primary" fullWidth>
                        Update Cover Image
                      </Button>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "1px",
                        }}
                      >
                        {formData.cover_image?.name}
                      </span>
                    </label>

                    <Button
                      type="submit"
                      fullWidth
                      sx={{
                        backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                      }}
                      size="small"
                    >
                      {buttonLoading ? (
                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                        >
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={24}
                          />
                        </MDBox>
                      ) : (
                        <MDTypography
                          style={{ fontSize: "13.5px", fontWeight: "bold" }}
                          color="white"
                        >
                          Submit
                        </MDTypography>
                      )}
                    </Button>
                  </form>
                </DialogContent>
              </Dialog>

              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
                mb={3}
              >
                 
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ArtistSongs;
