import { useState, useEffect, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { CircularProgress } from "@mui/material";
import { capitalizeFirstLetter } from "examples/CommonFunctions";
// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Add } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { createApiAction } from "store/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axiosInstance from "axiosInstance";
import { baseUrl } from "base";
import "./tableStyle.css";
import profileDefault from "assets/images/Profile-default.png";
import { IconButton } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { countries } from "assets/countries/Countries";
import { Autocomplete } from "@mui/material";
import { formatDate } from "examples/Date";
import { changeDate } from "examples/Date";
//dialog
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import { purple } from "@mui/material/colors"; // Material Dashboard 2 React components
import CancelIcon from "@mui/icons-material/Cancel";
import Pagination from "@mui/material/Pagination";
function ContentProvider() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  // const cpData = useSelector((state) => state.apiData.data.data)
  const cpData = useSelector((state) =>
    state.apiData.data.data.AllCPs
      ? state.apiData.data.data.AllCPs
      : state.apiData.data.data.cpSearchResult
  );
  const { totalCount } = useSelector((state) => state.apiData.data.data);
  const {error}=useSelector((state)=>state.apiData)
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const itemsPerPage = 10;
  const timeoutRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [initalLoading, setInitialLoading]=useState(true)

  const handleSearchChange = (event) => {
    const value = event.target.value;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchText(value);
    }, 300);
  };



  const [loading, setLoading] = useState(true);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Add Content Provider</MenuItem>
    </Menu>
  );

  const [rows, setRows] = useState([]);

  const handleDelete = () => {
    Swal.fire({
      title: "Do you want to delete this CP?",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Cancle`,
      cancelButtonColor: "#DD6B55",
      confirmButtonColor: "#DD6B55",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    });
  };

  const handleNavigate = (user_id) => {
    navigate(`/CP/${user_id}`);
  };

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image ? baseUrl + image : profileDefault}
          alt="name"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    msisdn: "",
    password: "",
    role_id: "2",
    image: "",
    gender: "",
    DOB: "",
    country: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log("formData", formData);
    setImagePreview(null);
    setFormErrors({});
    setOpen(false);
    setFormData({
      name: "",
      email: "",
      msisdn: "",
      password: "",
      role_id: "2",
      image: "",
      gender: "",
      DOB: "",
      country: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    if (name === "msisdn") {
      if (!/^[1-9]\d{9}$/.test(value)) {
        error = "Contact number must be exactly 10 digits";
      }
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Enter valid email address.";
      }
      setFormErrors({
        ...formErrors,
        [name]: error,
      });
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountryChange = (event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : "",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    const newFormData = new FormData();

    Object.keys(formData).forEach((key) => {
      newFormData.append(key, formData[key]);
    });
    console.log(formData);

    try {
      await axiosInstance.post("/api/dev/admin/CP/createCP", newFormData);

      Swal.fire({
        icon: "success",
        title: "CP Created!",
        timer: 1500,
        showConfirmButton: false,
      });

      await dispatch(
        createApiAction(
          `/api/dev/admin/CP/getAllCps?page=1&limit=${itemsPerPage}`,
          "GET"
        )
      );

      setLoading(false);
    } catch (error) {
      console.error("There was an error creating this CP!", error);

      Swal.fire({
        icon: "error",
        title: "Error Occurred!",
        html: `<span style="color: red;">${error?.response?.data?.message}</span>`,
      });

      setLoading(false);
    } finally {
      setImagePreview(null);
      setButtonLoading(false);
      handleClose();
    }
  };

  const handleFileInputChange = (event) => {
    const { id } = event.target;
    const file = event.target.files[0];
    setFormData({ ...formData, [id]: file });
    if(file){
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }

  };

   
  const handleRows = () => {
    if (cpData?.length > 0) {
      const rowsAPI = cpData.map((cp) => ({
        Image: (
          <MDBox display="flex" py={1}>
            {avatars([[cp.image_url, cp.name]])}
          </MDBox>
        ),
        ContentProviders: capitalizeFirstLetter(cp.name),
        Email: (
          capitalizeFirstLetter(cp.email)
          // <MDTypography variant="caption" color="text" fontWeight="medium">
          //   {cp.email}
          // </MDTypography>
        ),
        Mobile: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {cp.msisdn}
          </MDTypography>
        ),
        date: (
          formatDate(cp.createdAt)
 
        ),
        Action: (
          <MDBox>
            <MDButton
              onClick={() => handleNavigate(cp.user_id)}
              sx={{
                backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                marginRight: "12px",
              }}
              size="small"
            >
              <MDTypography
                style={{ fontSize: "13.5px", fontWeight: "bold" }}
                color="white"
              >
                Visit Profile
              </MDTypography>
            </MDButton>
          </MDBox>
        ),
      }));

      setRows(rowsAPI);
      setLoading(true);
    }
    else{
      setRows([]);
      setLoading(true);
    }
  };

  const handleCpApiCall = async () => {
    if (searchText.trim() !== "") {
      try {
        const SearchData = {
          search_keyword: searchText,
        };
        await dispatch(
          createApiAction(
            `/api/dev/admin/CP/CPsSearch?page=${searchPage}&limit=${itemsPerPage}`,
            "POST",
            SearchData
          )
        ).then(() => {setLoading(false);
          setInitialLoading(false)
        });
      } catch (error) {
        console.log("Error fetching data:", error); 
          setInitialLoading(false)
      }
    } else if (searchText.trim() === "") {
      try {
        await dispatch(
          createApiAction(
            `/api/dev/admin/CP/getAllCps?page=${page}&limit=${itemsPerPage}`,
            "GET"
          )
        ).then(() => {
          setLoading(false);
          setInitialLoading(false)
          setSearchPage(1);
        });
      } catch (error) {
        console.log("Error fetching data:", error); 
        setInitialLoading(false)
      }
    }
  };

  const columns = [
    { Header: "Image", accessor: "Image", width: "10%", align: "center" , disableSortBy: true},
    { Header: "Name", accessor: "ContentProviders", align: "center" },
    { Header: "Email", accessor: "Email", align: "center" },
    { Header: "Contact Number", accessor: "Mobile", align: "center" , disableSortBy: true},
    { Header: "Joined On", accessor: "date", align: "center",  sortType: (rowA, rowB) => {
      const dateA = new Date(rowA.original.date);
      const dateB = new Date(rowB.original.date);
      return dateA - dateB;
    },},
    { Header: "Action", accessor: "Action", align: "center" , disableSortBy: true},
  ];

  useEffect(() => {
    handleCpApiCall();
  }, []);

  useEffect(() => {
    if (!loading) {
      handleRows();
    }
  }, [loading, cpData, page, searchPage]);

  useEffect(() => {
    handleCpApiCall();
  }, [searchText, page, searchPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {!initalLoading?(
                 <MDBox>
                
                 <MDBox
                   mx={2}
                   mt={-3}
                   py={3}
                   px={2}
                   variant="gradient"
                   bgColor="info"
                   borderRadius="lg"
                   coloredShadow="info"
                   sx={{
                     backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                     display: "flex",
                     flexDirection: { xs: "column", sm: "row" }, 
                     justifyContent: "space-between",
                     alignItems: "center",
                     gap: 2, 
                     p: 3,
                   }}
                 >
                   <MDBox
                     sx={{
                       flex: 1,
                       display: "flex",
                       justifyContent: { xs: "center", sm: "flex-start" },
                       mb: { xs: 2, sm: 0 }, 
                     }}
                   >
                     <MDTypography variant="h5" color="white">
                       {`CONTENT PROVIDERS (${totalCount?totalCount:"0"})`}
                     </MDTypography>
                   </MDBox>
   
                   <MDBox
                     sx={{
                       flex: 1,
                       display: "flex",
                       flexDirection: { xs: "column", sm: "row" }, 
                       alignItems: "center",
                       gap: { xs: 2, sm: 0 }, 
                       justifyContent: { xs: "center", sm: "flex-end" }, 
                     }}
                   >
                     <MDBox
                       sx={{
                         display: "flex",
                         justifyContent: "center",
                         width: { xs: "100%", sm: "auto" }, 
                         mb: { xs: 2, sm: 0 },
                       }}
                     >
                       <TextField
                         onChange={handleSearchChange}
                         id="standard-bare"
                         variant="outlined"
                         placeholder="Search CP"
                         sx={{
                           "& .MuiInputBase-input": {
                             color: "#ffffff",
                             width: { xs: "100%", sm: "240px" }, 
                           },
                         }}
                         InputProps={{
                           endAdornment: (
                             <IconButton>
                               <SearchOutlined color="white" />
                             </IconButton>
                           ),
                         }}
                       />
                     </MDBox>
                     <MDButton
                       variant="contained"
                       size="small"
                       sx={{ color: "black",
                         marginLeft: { xs: 0, md: "12px" },
                         marginRight: { xs: 0, md: "12px" },
                         color: "black",
                         paddingBottom: "13px",
                         paddingTop: "13px",
                         width: { xs: "100%", md: "auto" },}}
                         fullWidth
                       onClick={handleOpen}
                     >
                       Add Content Provider
                     </MDButton>
                   </MDBox>
                 </MDBox>
   
                 <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                   <DialogTitle style={{ position: "relative" }}>
                     Add Content Provider
                     <span
                       style={{
                         position: "absolute",
                         top: 0,
                         right: 0,
                         padding: "10px",
                         cursor: "pointer",
                       }}
                     >
                       <CancelIcon
                         sx={{ color: purple[500] }}
                         onClick={handleClose}
                       />
                     </span>
                     <MDBox display="flex" justifyContent="center">
                       {imagePreview && (
                         <MDAvatar
                           src={imagePreview}
                           alt="profile-image"
                           sx={{ height: "140px", width: "140px" }}
                           shadow="sm"
                         />
                       )}
                     </MDBox>
                   </DialogTitle>
                   <DialogContent style={{ overflow: "hidden" }}>
                     <form onSubmit={handleSubmit}>
                       <TextField
                         autoFocus
                         margin="dense"
                         id="name"
                         name="name"
                         label="Name "
                         type="text"
                         fullWidth
                         required
                         value={formData.name}
                         onChange={handleChange}
                         inputProps={{ maxLength: 25 }}
                         helperText={formData.name.length === 25 ? "25 character limit reached" : ""}
                         FormHelperTextProps={{
                           sx: { color: "red" },
                         }}
                       />
                       <TextField
                         margin="dense"
                         id="email"
                         name="email"
                         label="Email "
                         type="email"
                         fullWidth
                         required
                         value={formData.email}
                         onChange={handleChange}
                         error={Boolean(formErrors.email)}
                         helperText={formErrors.email}
                       />
                       <TextField
                         margin="dense"
                         id="msisdn"
                         name="msisdn"
                         label="Contact Number"
                         required
                         type="text"
                         fullWidth
                         value={formData.msisdn}
                         onChange={handleChange}
                         inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                         error={!!formErrors.msisdn}
                         helperText={formErrors.msisdn}
                       />
                       <TextField
                         required
                         margin="dense"
                         id="DOB"
                         name="DOB"
                         // label="Date of Birth"
                         type="date"
                         fullWidth
                         value={formData.DOB}
                         onChange={handleChange}
                       />
                       <FormControl
                         fullWidth
                         sx={{ marginTop: "7px", marginBottom: "4px" }}
                       >
                         <InputLabel id="gender1">Gender</InputLabel>
                         <Select
                           labelId="gender1"
                           id="gender"
                           value={formData.gender}
                           label="Gender"
                           name="gender"
                           required
                           onChange={handleChange}
                           sx={{ height: "40px" }}
                         >
                           <MenuItem value="male">Male</MenuItem>
                           <MenuItem value="female">Female</MenuItem>
                           <MenuItem value="other">Other</MenuItem>
                         </Select>
                       </FormControl>
                       <Autocomplete
                         sx={{ marginTop: "6.5px", marginBottom: "2px" }}
                         id="country-select-demo"
                         options={countries}
                         fullWidth
                         autoHighlight
                         getOptionLabel={(option) => option.label}
                         onChange={handleCountryChange}
                         renderOption={(props, option) => {
                           const { key, ...optionProps } = props;
                           return (
                             <MDBox
                               key={key}
                               component="li"
                               sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                               {...optionProps}
                             >
                               <img
                                 loading="lazy"
                                 width="20"
                                 srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                 src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                 alt=""
                               />
                               {option.label} ({option.code}) +{option.phone}
                             </MDBox>
                           );
                         }}
                         renderInput={(params) => (
                           <TextField
                             {...params}
                             label="Select a Country"
                             required
                             inputProps={{
                               ...params.inputProps,
                               autoComplete: "new-password",
                             }}
                           />
                         )}
                       />
                       <TextField
                         margin="dense"
                         id="password"
                         name="password"
                         label="Password "
                         type="password"
                         fullWidth
                         required
                         value={formData.password}
                         onChange={handleChange}
                       />
   
                       <label
                         htmlFor="image"
                         style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "center",
                           border: "1px solid #ccc",
                           padding: "2px",
                           marginTop: "11px",
   
                           borderRadius: "5px",
                         }}
                       >
                         <input
                           type="file"
                           id="image"
                           accept="image/*"
                           required={!formData.image}
                           onChange={handleFileInputChange}
                           style={{
                             position: "absolute",
                             width: "1px",
                             height: "1px",
                             opacity: 0,
                           }}
                         />
   
                         <Button component="span" color="primary" fullWidth>
                           Add Image
                         </Button>
                         <span
                           style={{
                             textAlign: "center",
                             width: "100%",
                             marginTop: "1px",
                           }}
                         >
                           {formData.image?.name}
                         </span>
                       </label>
                       <MDButton
                         type="submit"
                         sx={{
                           backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                           marginTop: "11px",
                         }}
                         fullWidth
                         disabled={Object.values(formErrors).some(
                           (error) => error !== ""
                         )}
                       >
                         {buttonLoading ? (
                           <MDBox
                             display="flex"
                             justifyContent="center"
                             alignItems="center"
                             width="100%"
                           >
                             <CircularProgress
                               sx={{ color: "#ffffff" }}
                               size={24}
                             />
                           </MDBox>
                         ) : (
                           <MDTypography
                             style={{ fontSize: "13.5px", fontWeight: "bold" }}
                             color="white"
                           >
                             Submit
                           </MDTypography>
                         )}
                       </MDButton>
                     </form>
                   </DialogContent>
                 </Dialog>
                 <MDBox pt={3}>
                 {rows?.length>0 ? <DataTable
                     table={{ columns, rows }}
                     isSorted={true}
                     entriesPerPage={false}
                     showTotalEntries={false}
                     noEndBorder
                   /> :(<MDBox sx={{display:'flex',padding:'20px',justifyContent:'center'}}>
                     <MDTypography variant="caption" sx={{fontSize:"20px"}}>
                    {/* { !loading &&" No content providers are present at this time." }  */}
                     </MDTypography>
                  </MDBox>)}
                 </MDBox>
   
                 <MDBox
                   display="flex"
                   justifyContent="center"
                   alignItems="center"
                   mt={2}
                   mb={3}
                 >
                   <Pagination
                     count={Math.ceil(totalCount / itemsPerPage)}
                     page={searchText === "" ? page : searchPage}
                     onChange={(event, value) => {
                       {
                         searchText === "" ? setPage(value) : setSearchPage(value);
                       }
                     }}
                     color="primary"
                     shape="rounded"
                     size="large"
                     sx={{
                       "& .MuiPaginationItem-root": {
                         borderRadius: "8px",
                         border: "1px solid #ddd",
                         margin: "0 4px",
                         "&.Mui-selected": {
                           backgroundColor: "#5870FD",
                           color: "white",
                           border: "1px solid #5870FD",
                         },
                         "&:hover": {
                           backgroundColor: "#bb48bd",
                         },
                       },
                       "& .MuiPaginationItem-previousNext": {
                         borderRadius: "8px",
                         border: "1px solid #ddd",
                       },
                     }}
                   />
                 </MDBox>
   
                 </MDBox>
              )
              :
              !error?
              (
                <MDBox
                display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
              >
                <CircularProgress
                  sx={{ color: "purple" }}
                  size={54}
                />
              </MDBox>
              ):
              (<MDBox
                display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
              >
               <MDTypography>
                Error Occured
               </MDTypography>
              </MDBox>)
            }
             
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ContentProvider;
