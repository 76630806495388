export const languages=[
  {"code":"ab","name":"Abkhaz","nativeName":"аҧсуа"},
  {"code":"aa","name":"Afar","nativeName":"Afaraf"},
  {"code":"af","name":"Afrikaans","nativeName":"Afrikaans"},
  {"code":"ak","name":"Akan","nativeName":"Akan"},
  {"code":"sq","name":"Albanian","nativeName":"Shqip"},
  {"code":"am","name":"Amharic","nativeName":"አማርኛ"},
  {"code":"ar","name":"Arabic","nativeName":"العربية"},
  {"code":"an","name":"Aragonese","nativeName":"Aragonés"},
  {"code":"hy","name":"Armenian","nativeName":"Հայերեն"},
  {"code":"as","name":"Assamese","nativeName":"অসমীয়া"},
  {"code":"av","name":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
  {"code":"ae","name":"Avestan","nativeName":"avesta"},
  {"code":"ay","name":"Aymara","nativeName":"aymar aru"},
  {"code":"az","name":"Azerbaijani","nativeName":"azərbaycan dili"},
  {"code":"bm","name":"Bambara","nativeName":"bamanankan"},
  {"code":"ba","name":"Bashkir","nativeName":"башҡорт теле"},
  {"code":"eu","name":"Basque","nativeName":"euskara"},
  {"code":"be","name":"Belarusian","nativeName":"Беларуская"},
  {"code":"bn","name":"Bengali","nativeName":"বাংলা"},
  {"code":"bh","name":"Bihari","nativeName":"भोजपुरी"},
  {"code":"bi","name":"Bislama","nativeName":"Bislama"},
  {"code":"bs","name":"Bosnian","nativeName":"bosanski jezik"},
  {"code":"br","name":"Breton","nativeName":"brezhoneg"},
  {"code":"bg","name":"Bulgarian","nativeName":"български език"},
  {"code":"my","name":"Burmese","nativeName":"ဗမာစာ"},
  {"code":"ca","name":"Catalan","nativeName":"Català"},
  {"code":"ch","name":"Chamorro","nativeName":"Chamoru"},
  {"code":"ce","name":"Chechen","nativeName":"нохчийн мотт"},
  {"code":"ny","name":"Chichewa","nativeName":"chiCheŵa"},
  {"code":"zh","name":"Chinese","nativeName":"中文 (Zhōngwén)"},
  {"code":"cv","name":"Chuvash","nativeName":"чӑваш чӗлхи"},
  {"code":"kw","name":"Cornish","nativeName":"Kernewek"},
  {"code":"co","name":"Corsican","nativeName":"corsu"},
  {"code":"cr","name":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
  {"code":"hr","name":"Croatian","nativeName":"hrvatski"},
  {"code":"cs","name":"Czech","nativeName":"česky"},
  {"code":"da","name":"Danish","nativeName":"dansk"},
  {"code":"dv","name":"Divehi","nativeName":"ދިވެހި"},
  {"code":"nl","name":"Dutch","nativeName":"Nederlands"},
  {"code":"en","name":"English","nativeName":"English"},
  {"code":"eo","name":"Esperanto","nativeName":"Esperanto"},
  {"code":"et","name":"Estonian","nativeName":"eesti"},
  {"code":"ee","name":"Ewe","nativeName":"Eʋegbe"},
  {"code":"fo","name":"Faroese","nativeName":"føroyskt"},
  {"code":"fj","name":"Fijian","nativeName":"vosa Vakaviti"},
  {"code":"fi","name":"Finnish","nativeName":"suomi"},
  {"code":"fr","name":"French","nativeName":"français"},
  {"code":"ff","name":"Fula","nativeName":"Fulfulde"},
  {"code":"gl","name":"Galician","nativeName":"Galego"},
  {"code":"ka","name":"Georgian","nativeName":"ქართული"},
  {"code":"de","name":"German","nativeName":"Deutsch"},
  {"code":"el","name":"Greek","nativeName":"Ελληνικά"},
  {"code":"gn","name":"Guaraní","nativeName":"Avañeẽ"},
  {"code":"gu","name":"Gujarati","nativeName":"ગુજરાતી"},
  {"code":"ht","name":"Haitian","nativeName":"Kreyòl ayisyen"},
  {"code":"ha","name":"Hausa","nativeName":"Hausa"},
  {"code":"he","name":"Hebrew","nativeName":"עברית"},
  {"code":"hz","name":"Herero","nativeName":"Otjiherero"},
  {"code":"hi","name":"Hindi","nativeName":"हिन्दी"},
  {"code":"ho","name":"Hiri Motu","nativeName":"Hiri Motu"},
  {"code":"hu","name":"Hungarian","nativeName":"Magyar"},
  {"code":"ia","name":"Interlingua","nativeName":"Interlingua"},
  {"code":"id","name":"Indonesian","nativeName":"Bahasa Indonesia"},
  {"code":"ie","name":"Interlingue","nativeName":"Originally called Occidental"},
  {"code":"ga","name":"Irish","nativeName":"Gaeilge"},
  {"code":"ig","name":"Igbo","nativeName":"Asụsụ Igbo"},
  {"code":"ik","name":"Inupiaq","nativeName":"Iñupiaq"},
  {"code":"io","name":"Ido","nativeName":"Ido"},
  {"code":"is","name":"Icelandic","nativeName":"Íslenska"},
  {"code":"it","name":"Italian","nativeName":"Italiano"},
  {"code":"iu","name":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
  {"code":"ja","name":"Japanese","nativeName":"日本語"},
  {"code":"jv","name":"Javanese","nativeName":"basa Jawa"},
  {"code":"kl","name":"Kalaallisut","nativeName":"kalaallisut"},
  {"code":"kn","name":"Kannada","nativeName":"ಕನ್ನಡ"},
  {"code":"kr","name":"Kanuri","nativeName":"Kanuri"},
  {"code":"ks","name":"Kashmiri","nativeName":"कश्मीरी"},
  {"code":"kk","name":"Kazakh","nativeName":"Қазақ тілі"},
  {"code":"km","name":"Khmer","nativeName":"ភាសាខ្មែរ"},
  {"code":"ki","name":"Kikuyu","nativeName":"Gĩkũyũ"},
  {"code":"rw","name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
  {"code":"ky","name":"Kirghiz","nativeName":"кыргыз тили"},
  {"code":"kv","name":"Komi","nativeName":"коми кыв"},
  {"code":"kg","name":"Kongo","nativeName":"KiKongo"},
  {"code":"ko","name":"Korean","nativeName":"한국어"},
  {"code":"ku","name":"Kurdish","nativeName":"Kurdî"},
  {"code":"kj","name":"Kwanyama","nativeName":"Kuanyama"},
  {"code":"la","name":"Latin","nativeName":"latine"},
  {"code":"lb","name":"Luxembourgish","nativeName":"Lëtzebuergesch"},
  {"code":"lg","name":"Luganda","nativeName":"Luganda"},
  {"code":"li","name":"Limburgish","nativeName":"Limburgs"},
  {"code":"ln","name":"Lingala","nativeName":"Lingála"},
  {"code":"lo","name":"Lao","nativeName":"ພາສາລາວ"},
  {"code":"lt","name":"Lithuanian","nativeName":"lietuvių kalba"},
  {"code":"lu","name":"Luba Katanga","nativeName":""},
  {"code":"lv","name":"Latvian","nativeName":"latviešu valoda"},
  {"code":"gv","name":"Manx","nativeName":"Gaelg"},
  {"code":"mk","name":"Macedonian","nativeName":"македонски јазик"},
  {"code":"mg","name":"Malagasy","nativeName":"Malagasy fiteny"},
  {"code":"ms","name":"Malay","nativeName":"bahasa Melayu"},
  {"code":"ml","name":"Malayalam","nativeName":"മലയാളം"},
  {"code":"mt","name":"Maltese","nativeName":"Malti"},
  {"code":"mi","name":"Māori","nativeName":"te reo Māori"},
  {"code":"mr","name":"Marathi","nativeName":"मराठी"},
  {"code":"mh","name":"Marshallese","nativeName":"Kajin M̧ajeļ"},
  {"code":"mn","name":"Mongolian","nativeName":"монгол"},
  {"code":"na","name":"Nauru","nativeName":"Ekakairũ Naoero"},
  {"code":"nb","name":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
  {"code":"nd","name":"Northern Ndebele","nativeName":"Sindebele"},
  {"code":"ne","name":"Nepali","nativeName":"नेपाली"},
  {"code":"ng","name":"Ndonga","nativeName":"Owambo"},
  {"code":"nl","name":"Dutch","nativeName":"Nederlands"},
  {"code":"nn","name":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
  {"code":"no","name":"Norwegian","nativeName":"Norsk"},
  {"code":"nr","name":"Southern Ndebele","nativeName":"Sindebele"},
  {"code":"nv","name":"Navajo","nativeName":"Diné bizaad"},
  {"code":"ny","name":"Chichewa","nativeName":"chiCheŵa"},
  {"code":"oc","name":"Occitan","nativeName":"Occitan"},
  {"code":"oj","name":"Ojibwe","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
  {"code":"om","name":"Oromo","nativeName":"Afaan Oromo"},
  {"code":"or","name":"Odia","nativeName":"ଓଡ଼ିଆ"},
  {"code":"os","name":"Ossetian","nativeName":"ирон"},
  {"code":"pa","name":"Punjabi","nativeName":"ਪੰਜਾਬੀ"},
  {"code":"pi","name":"Pali","nativeName":"पाऴि"},
  {"code":"pl","name":"Polish","nativeName":"polski"},
  {"code":"ps","name":"Pashto","nativeName":"پښتو"},
  {"code":"pt","name":"Portuguese","nativeName":"Português"},
  {"code":"qu","name":"Quechua","nativeName":"Runa Simi"},
  {"code":"rm","name":"Romansh","nativeName":"Rumantsch"},
  {"code":"rn","name":"Kirundi","nativeName":"Ikirundi"},
  {"code":"ro","name":"Romanian","nativeName":"română"},
  {"code":"ru","name":"Russian","nativeName":"Русский"},
  {"code":"rw","name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
  {"code":"sa","name":"Sanskrit","nativeName":"संस्कृतम्"},
  {"code":"sc","name":"Sardinian","nativeName":"Sardu"},
  {"code":"sd","name":"Sindhi","nativeName":"سنڌي"},
  {"code":"se","name":"Northern Sami","nativeName":"Davvisámegiella"},
  {"code":"sg","name":"Sango","nativeName":"Sängö"},
  {"code":"si","name":"Sinhala","nativeName":"සිංහල"},
  {"code":"sk","name":"Slovak","nativeName":"slovenčina"},
  {"code":"sl","name":"Slovenian","nativeName":"slovenščina"},
  {"code":"sm","name":"Samoan","nativeName":"Gagana fa'a Samoa"},
  {"code":"sn","name":"Shona","nativeName":"ChiShona"},
  {"code":"so","name":"Somali","nativeName":"Soomaaliga"},
  {"code":"sq","name":"Albanian","nativeName":"Shqip"},
  {"code":"sr","name":"Serbian","nativeName":"српски"},
  {"code":"ss","name":"Swati","nativeName":"SiSwati"},
  {"code":"st","name":"Sesotho","nativeName":"Sesotho"},
  {"code":"su","name":"Sundanese","nativeName":"Basa Sunda"},
  {"code":"sv","name":"Swedish","nativeName":"Svenska"},
  {"code":"sw","name":"Swahili","nativeName":"Kiswahili"},
  {"code":"ta","name":"Tamil","nativeName":"தமிழ்"},
  {"code":"te","name":"Telugu","nativeName":"తెలుగు"},
  {"code":"tg","name":"Tajik","nativeName":"тоҷикӣ"},
  {"code":"th","name":"Thai","nativeName":"ไทย"},
  {"code":"ti","name":"Tigrinya","nativeName":"ትግርኛ"},
  {"code":"tk","name":"Turkmen","nativeName":"Türkmen"},
  {"code":"tl","name":"Tagalog","nativeName":"Wikang Tagalog"},
  {"code":"tn","name":"Tswana","nativeName":"Setswana"},
  {"code":"to","name":"Tongan","nativeName":"Faka Tonga"},
  {"code":"tr","name":"Turkish","nativeName":"Türkçe"},
  {"code":"ts","name":"Tsonga","nativeName":"Xitsonga"},
  {"code":"tt","name":"Tatar","nativeName":"татар теле"},
  {"code":"tw","name":"Twi","nativeName":"Twi"},
  {"code":"ty","name":"Tahitian","nativeName":"Reo Tahiti"},
  {"code":"ug","name":"Uighur","nativeName":"ئۇيغۇرچە"},
  {"code":"uk","name":"Ukrainian","nativeName":"Українська"},
  {"code":"ur","name":"Urdu","nativeName":"اردو"},
  {"code":"uz","name":"Uzbek","nativeName":"O‘zbek"},
  {"code":"ve","name":"Venda","nativeName":"TshiVenda"},
  {"code":"vi","name":"Vietnamese","nativeName":"Tiếng Việt"},
  {"code":"vo","name":"Volapük","nativeName":"Volapük"},
  {"code":"wa","name":"Walloon","nativeName":"Walon"},
  {"code":"cy","name":"Welsh","nativeName":"Cymraeg"},
  {"code":"wo","name":"Wolof","nativeName":"Wollof"},
  {"code":"xh","name":"Xhosa","nativeName":"isiXhosa"},
  {"code":"yi","name":"Yiddish","nativeName":"ייִדיש"},
  {"code":"yo","name":"Yoruba","nativeName":"Yorùbá"},
  {"code":"za","name":"Zhuang","nativeName":"Saɯ cueŋƅ"},
  {"code":"zu","name":"Zulu","nativeName":"isiZulu"},
]
