import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const Instructions = () => {
  const ListItem = ({ children }) => (
    <MDBox component="li" variant="body2" sx={{ mb: 1 }}>
      {children}
    </MDBox>
  );

  const Section = ({ title, children }) => (
    <MDBox sx={{ mb: 3 }}>
      <MDTypography
        variant="h6"
        sx={{ mb: 2, fontWeight: "bold", color: "#333" }}
      >
        {title}
      </MDTypography>
      {children}
    </MDBox>
  );
  const Code = ({ children }) => (
    <code
      style={{
        backgroundColor: "#f0f0f0",
        padding: "2px 4px",
        borderRadius: "4px",
        fontSize: "0.9em",
      }}
    >
      {children}
    </code>
  );
  return (
    <>
      <MDBox
        fullWidth
        border={1}
        borderColor="rgba(0, 0, 0, 0.3)"
        borderRadius="8px"
        p={2}
        boxShadow={3}
        mr={3}
        ml={3}
      >
        <Section title="INSTRUCTIONS">
          <MDBox component="ul" sx={{ pl: 4 }}>
            <ListItem>
              <strong>Step 1:</strong> Create a Folder
              <MDBox component="ul" sx={{ pl: 4 }}>
                <ListItem>
                  Add song files and their corresponding cover art images to the
                  folder.
                </ListItem> 
               
              </MDBox>
            </ListItem>

            <ListItem>
              <strong>Step 2:</strong> Create an Excel File
              <MDBox component="ul" sx={{ pl: 4 }}>
                <ListItem>
                  <strong>Naming Convention:</strong>{" "}
                  <Code>songsMetadata.xlsx</Code> (This exact name is required.)
                </ListItem>
                <ListItem>
                  Include the following fields: <Code>song_title</Code>,{" "}
                  <Code>artist_name</Code>, <Code>cp_name</Code>,{" "}
                  <Code>country</Code>, <Code>language</Code>,{" "}
                  <Code>genre</Code>, <Code>duration</Code>, <Code>size</Code>,{" "}
                  <Code>category_type</Code>, <Code>category_name</Code>,{" "}
                  <Code>record_label</Code>, <Code>cover_art_file_name</Code>.
                </ListItem>
                <ListItem>
                  Add details for each song in a separate row, ensuring the
                  corresponding cover art image name is included.
                </ListItem>
                <ListItem>
                  <strong>Important Notes:</strong>
                  <MDBox component="ul" sx={{ pl: 4 }}> 
                    <ListItem>
                      All rows must use the same content provider name.
                    </ListItem> 
                    <ListItem>
                      The <Code>category_type</Code> should be in lowercase and
                      one of the following: <Code>playlist</Code>,{" "}
                      <Code>album</Code>, <Code>single</Code>.
                    </ListItem>
                    <ListItem>
                      If category_type is single, there is no need to specify
                      category_name.
                    </ListItem> 
                    <ListItem>
                      Duration must be specified in seconds (e.g.,{" "}
                      <Code>123000</Code>).
                    </ListItem>
                    <ListItem>
                      Size should be specified in bytes (e.g.,{" "}
                      <Code>324234</Code>).
                    </ListItem>
                    <ListItem>
                      Language and country should be in lowercase.
                    </ListItem>
                  </MDBox>
                </ListItem>
              </MDBox>
            </ListItem>

            <ListItem>
              <strong>Step 3:</strong> Place the Excel File
              <MDBox component="ul" sx={{ pl: 4 }}>
                <ListItem>
                  Save the Excel file in the same folder as the song and cover
                  Image files.
                </ListItem>
              </MDBox>
            </ListItem>

            <ListItem>
              <strong>Step 4:</strong> Upload the Folder
              <MDBox component="ul" sx={{ pl: 4 }}>
                <ListItem>
                  Click the <Code>Browse folder</Code> button, select the folder
                  on your computer, and upload it.
                </ListItem>
              </MDBox>
            </ListItem>

            <ListItem>
              <strong>Step 5:</strong> Finalize Upload
              <MDBox component="ul" sx={{ pl: 4 }}>
                <ListItem> 
                  Click the <Code>Upload</Code> button and wait for the process to complete. Once the progress reaches 100%, data processing will begin. Please remain patient until it is fully completed.
                </ListItem>
              </MDBox>
            </ListItem>
          </MDBox>
        </Section>
      </MDBox>
    </>
  );
};

export default Instructions;
