import { useState } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
 
import Box from "@mui/material/Box";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpg";
 
 import styledCompo from "layouts/authentication/style.module.css";
function ErrorPage() {
 
  return (
    <div className={styledCompo.bg_style}>
      <BasicLayout image={""}>
  <Box
      sx={{
        display: "flex",
        flexDirection: "column", 
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box>
        <MDTypography  sx={{ color: "#ffffff", fontSize:"80px"}}>
          404
        </MDTypography>
      </Box>
      <Box>
        <MDTypography variant="h4" sx={{ color: "#ffffff" }}>
          Page not found
        </MDTypography>
      </Box>
    </Box>
      </BasicLayout>
    </div>
  );
}

export default ErrorPage;
