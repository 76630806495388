import React from "react";

import { Grid, Typography, Box, IconButton } from "@mui/material";
import AppStoreLogo from "../../assets/images/logos/AppStoreLogo.svg";
import GooglePlay from "../../assets/images/logos/GooglePlay.svg";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

const Footer = () => {
  const navigate = useNavigate();
  const handleClick=()=>{
    window.location.href = "https://yearly-fast-goshawk.ngrok-free.app/api/dev/admin/dashboard/downloadAPK?fileName=beatzmusic.apk";
  }
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Box mt={2} >
          <MDButton
            variant="outlined" 
            onClick={ handleClick}
            sx={{
              display: "flex", 
              alignItems: "center",
              padding: "8px 16px",
              borderColor: "#ffffff",
            }}
          >
             
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              fill="currentColor"
              className="bi bi-android"
              viewBox="0 0 16 16"
            >
              <path d="M2.76 3.061a.5.5 0 0 1 .679.2l1.283 2.352A8.9 8.9 0 0 1 8 5a8.9 8.9 0 0 1 3.278.613l1.283-2.352a.5.5 0 1 1 .878.478l-1.252 2.295C14.475 7.266 16 9.477 16 12H0c0-2.523 1.525-4.734 3.813-5.966L2.56 3.74a.5.5 0 0 1 .2-.678ZM5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2m6 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
            </svg>
            <div>
              <Typography
                variant="button"
                ml={2}
                sx={{ fontWeight: "bold", fontSize: "20px" }} 
              >
                Download
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.75rem", fontWeight: "bold" }}
              >
                for Android
              </Typography>
            </div>
          </MDButton>
        </Box>
      </Box>
      <Box>
        <Typography
          onClick={()=>{navigate("/BeatzMusicGhana/PrivacyPolicy");}}
          variant="p"
          sx={{
            color: "#ffffff",
            fontSize: "12px",
            fontWeight: "500",
            cursor: "pointer",
            "&:hover": {
              color: "#90caf9",
              textDecoration: "underline",
            },
          }}
        >
          Terms & Conditions
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
