import MDBox from 'components/MDBox'
import PageLayout from 'examples/LayoutContainers/PageLayout'
import React from 'react'
import "./PrivacyPage.css"
const PrivacyPage = () => {
  return (
    <PageLayout>
      <MDBox  px={1} width="100%" height="100vh" mx="auto" className="privacy-container">
        <h1>Privacy Policy</h1>
        <p>Effective as of 14th November, 2024</p>

        <p>
          Your privacy is important to us. None of us at Beatz Music would want your own personal information used in ways we do not agree to or know about.
        </p>

        <p>
          Please take a moment to read our Privacy Policy below to make sure you understand and agree with it.
        </p>

        <h2>Why does Beatz Music collect personal information?</h2>
        <p>
          We need your personal information (i.e., information by which you are individually identifiable) in order to provide you our services, pay you, distribute your music on Beatz app, answer questions you may have, keep track of all the things happening with your account, provide customer support, collect fees, provide new opportunities, contact you in case of incomplete information, and deliver new products, updates and a high-quality customer experience.
        </p>

        <h2>What information does Beatz Music app collect?</h2>
        <p>
          To offer you our services and products, Beatz will need basic personal information from you that might consist of all or part of the following: the name of the account holder; physical address; email address; phone numbers; bank account information for deposits of money owed to you from the sale of your music; and tax identification number for tax purposes. In addition, we might ask for personal information that will help remind you of your Beatz account user name and/or password in the event you forget or lose such information.
        </p>
        <p>
          Beatz may also collect non-personal information from you, including the names of your songs and albums and the associated artists; the year that your recordings were made/released; and the name(s) of the person or entity that owns and controls the rights to the songs, albums and/or artwork that you deliver to Beatz. Such information may, without limitation, be used to keep track of how certain albums and/or songs are selling for ranking charts. Please note that by using the Beatz service, you agree that Beatz shall have the right to provide information relative to the sales of your recordings to third parties, to aggregate such information in charts and other comparative informational materials, and to disseminate the same in any manner.
        </p>
        <p>
          Please note that some of your information may remain in our records even in the event that your account is closed.
        </p>

        <h2>Does Beatz Music app disclose your personal information to anyone?</h2>
        <p>
          We take your privacy very seriously. In order to serve you it is possible that people working for Beatz might view your personal information to make sure we are providing you with the best service possible, but such access will be on a need-to-know basis only and they will be required to keep your personal information confidential and use it only as provided by the Beatz privacy policy.
        </p>
        <p>
          It is also possible that with improvements to existing services or with the addition of new services it may be to your advantage for Beatz to make specific personal information about you available to companies that Beatz has a strategic relationship with or that perform work for Beatz so that they can provide products and services to you on our behalf. We will provide these companies with only the personal information that they require to perform the task for which they have been hired to do. These companies must also protect your personal information in accordance with Beatz's privacy policy.
        </p>
        <p>
          Your personal information may also be disclosed to law enforcement officials or third parties if Beatz is required to do so by law. Furthermore, in the event that Beatz receives notification that any content you have posted online using the Beatz service infringes on the rights of a third party, Beatz may, in its good faith discretion, disclose your contact information to such third party.
        </p>

        <h2>How does Beatz Music app protect your personal information?</h2>
        <p>
          Beatz will take appropriate administrative, technical, physical measures and more to safeguard your personal information against loss, theft or misuse, as well as unauthorized access, disclosure, alteration or destruction.
        </p>
        <p>
          Although there will always be people attempting to steal information, we follow the industry accepted security standards designed to safeguard and prevent unauthorized access to, disclosure of and use of your personal information.
        </p>
        <p>
          In addition, Beatz will restrict employee access to databases containing personal information and impose confidentiality requirements upon employees who need to know such information. Furthermore, any Beatz employee with partial or total access to personal information must abide by the terms of this privacy policy.
        </p>
        <p>
          Finally, you will be given complete discretion to create a user name and password that only you know. To further protect your information, you should consider changing your password often.
        </p>

        <h2>Accuracy of your personal information:</h2>
        <p>
          Beatz works hard to keep your personal information safe, accurate, complete and as current as possible. You can help us keep your personal information accurate by updating such information whenever appropriate.
        </p>

        <h2>Internet Protocol ("IP") Addresses, cookies and other variables</h2>
        <p>
          As is the case with many websites, the website logs your IP address and uses standard technology called cookies, which are small data files that are downloaded onto your computer/ mobile when you visit the website. Cookies automatically identify your browser to the website server whenever you interact with the website. We use cookies and the information they provide to improve our services and the quality of your experience while visiting the website.
        </p>
        <p>
          You can set your browser to notify you when a cookie is enabled, allowing you the opportunity to reject or disable the cookie; however, it is possible that some portion or functions of the website or its participating partners websites may not work as well or at all if you disable your cookies.
        </p>
        <p>
          We may use third party service providers for the limited purpose to help us analyze certain online activities. For example, these service providers may help us measure the performance of our online campaigns or analyze visitor activity on the website. We may permit these service providers to use cookies and other technologies to perform these services for.
        </p>
        <p>
          Many websites gather certain non-personal information automatically and store it in log files, including things like your IP addresses, browser type, Internet service provider, referring/exit pages, operating system, date/time stamp, and clickstream data. None of this information identifies you directly. We may also enable this technology for use in ways that are commonly accepted by industry practice.
        </p>

        <h2>Message boards, chat rooms and instant messaging:</h2>
        <p>
          In the event that Beatz launches community-based services such as message boards, blogs, instant messaging and other similar services, you should be aware that any information you share is visible to other users. Please note that information that you submit to one of these forums can be read, collected or used by other individuals to send you unsolicited messages. Beatz is not responsible for the personally identifiable information you choose to submit in these forums - you are solely responsible for any content that you post there, including, but not limited to, any personal information that you choose to reveal in these public forums. In using any of these services, you agree that you will not use them for any purpose that is unlawful or in violation of Beatz's Terms and Conditions. Please note that Beatz may, in its sole discretion, monitor postings in these forums to assure that users are complying with the Beatz Terms and Conditions.
        </p>

        <h2>Links to other websites:</h2>
        <p>
          It is possible that at some time Beatz might link to other websites. If we do, and you choose to go to these other websites, please be aware that such websites may not be affiliated with Beatz and are not subject to Beatz's Privacy Policy. We encourage you to carefully review such other websites respective privacy policies and ask questions directly of them in this regard.
        </p>

        <h2>Changes to Beatz's Privacy Policy:</h2>
        <p>
          As Beatz grows, changes and expands to suit your needs, we may need to modify, alter or otherwise update this privacy policy. When we make changes to this Privacy Policy we will notify you here, so we encourage you to review this Privacy Policy from time to time. We may also attempt to notify you of any such changes to our Privacy Policy via email or by placing a notice on our home page, but are not required to do so.
        </p>

        <h2>Acceptance of Beatz's Privacy Policy:</h2>
        <p>
          By using the Beatz website, you consent to the collection, use and disclosure of your personal information by Beatz in accordance with this Privacy Policy.
        </p>

        <h2>Questions about Beatz's Privacy Policy:</h2>
        <p>
          If you have any questions, concerns or thoughts about the privacy of your personal information while using the Beatz website or this Privacy Policy, please feel free to contact us at our Contact Page.
        </p>
      </MDBox>
    </PageLayout>
  )
}

export default PrivacyPage
