import { useState, useEffect } from "react";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router";
import axiosInstance from "axiosInstance";
import { baseUrl } from "base";
import Tooltip from "@mui/material/Tooltip";
import { Autocomplete } from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import { capitalizeFirstLetter } from "examples/CommonFunctions";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { createApiAction } from "store/ApiServices";
import { useDispatch, useSelector } from "react-redux";
// Data
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { purple } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import profileDefault from "assets/images/Profile-default.png";
import noCoverImage from "assets/images/noCoverImage.png"

function Projects() {
  const [menu, setMenu] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [artist, setArtist] = useState([]);
  const [song, setSong] = useState([]);
  const dispatch = useDispatch();
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const AllBanner = useSelector((state) => state.apiData.data);
  const [rows, setRows] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [editBannerImage, setEditBannerImage] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [initialForm, setInitialForm] = useState({
    title: "",
    artist_id: "",
    song_id: "",
    image: null,
    banner_id: "",
  });
  const [editForm, setEditForm] = useState({
    title: "",
    artist_id: "",
    song_id: "",
    image: "",
    artist_name: "",
    banner_id: "",
  });

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    artist_id: "",
    song_id: "",
    image: "",
    artist_name: "",
  });
  const navigate = useNavigate();
  const handleNavigate = (artist_id) => {
    navigate(`/profile/${artist_id}`);
  };

   const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom" >
  <MDBox
    component="img"
    src={image? baseUrl + image : noCoverImage}
    alt={name}
    sx={{
      width: "80px",  
      height: "40px",  
      objectFit: "cover",  
      borderRadius:"5px",
      border: ({ borders: { borderWidth }, palette: { white } }) =>
        `${borderWidth[1]} solid ${white.main}`,
      position: "relative",

      "&:not(:first-of-type)": {
        ml: -1.25,
      },

      "&:hover, &:focus": {
        zIndex: "10",
      },
    }}
  />
</Tooltip>
      // <Tooltip key={name} title={name} placeholder="bottom">
      //   <MDAvatar
      //     src={image ? baseUrl + image : profileDefault}
      //     alt="name"
      //     sx={{
      //       border: ({ borders: { borderWidth }, palette: { white } }) =>
      //         `${borderWidth[2]} solid ${white.main}`,
      //       cursor: "pointer",
      //       position: "relative",

      //       "&:not(:first-of-type)": {
      //         ml: -1.25,
      //       },

      //       "&:hover, &:focus": {
      //         zIndex: "10",
      //       },
      //     }}
      //   />
      // </Tooltip>
    ));

  const handleBannerRemove = (bannerId) => {
    const BannerRemoveId = {
      banner_id: bannerId,
    };
    // console.log("bannerid", BannerRemoveId)
    Swal.fire({
      icon: "warning",
      title: "Do you want to delete this banner?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post("/api/dev/banner/removeBanner", BannerRemoveId)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Deleted",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch(createApiAction("/api/dev/banner/getAllBanners", "GET"));
          })
          .catch((error) => {
            console.error("error!", error.message);
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "There was an error removing this banner.",
            });
          });
      }
    });
  };

  const handleStatus = async (banner) => {
    const BannerId = {
      banner_id: banner.banner_id,
    };

    try {
      await axiosInstance.post("/api/dev/banner/changeStatus", BannerId);
      await dispatch(createApiAction("/api/dev/banner/getAllBanners", "GET"));
    } catch (error) {
      console.error("Error changing banner status:", error);
    }
  };

  const handleEditOpen = (banner) => {
    const initialFormState = {
      title: banner.title,
      artist_id: banner.artist_id,
      song_id: banner.song_id,
      image: null,
      artist_name: banner.artist_name,
      banner_id: banner.banner_id,
    };
    const bannerImage = baseUrl + banner.image_url;

    setEditBannerImage(bannerImage);
    setEditForm(initialFormState);
    setInitialForm(initialFormState);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setImagePreview(null);
    setEditBannerImage(null);
    console.log(editForm);
    setEditOpen(false);
  };

  const appendChangedFields = (formData, initialData, currentData) => {
    Object.keys(currentData).forEach((key) => {
      if (
        currentData[key] !== initialData[key] &&
        currentData[key] !== null &&
        key !== "image" &&
        key !== "artist_id"
      ) {
        formData.append(key, currentData[key]);
      }
    });
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();
    appendChangedFields(newFormData, initialForm, editForm);

    newFormData.append("banner_id", editForm.banner_id);

    if (editForm.image !== null) {
      newFormData.append("image", editForm.image);
    }

    try {
      await axiosInstance.post("/api/dev/Banner/updateBanner", newFormData);
      Swal.fire({
        title: "Banner Updated!",
        icon: "success",
        timer: 2500,
        showConfirmButton: false,
      });

      await dispatch(createApiAction("/api/dev/banner/getAllBanners", "GET"));
    } catch (e) {
      Swal.fire({
        title: "Error Occurred!",
        body: `${e.message}`,
        icon: "error",
      });
      console.log(e.message);
    }
    setButtonLoading(false);
    handleEditClose();
  };

  const handleSetTable = () => {
    const rowsAPI = AllBanner?.data?.map((banner) => ({
      Image: (
        <MDBox display="flex" py={1}>
          {avatars([[banner.image_url, banner.name]])}
        </MDBox>
      ),
      Title: capitalizeFirstLetter(banner.title),

      Action: (
        <MDBox width="8rem" textAlign="left">
          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
            <MDBox mt={0.5}>
              <Switch
                checked={banner.status}
                onChange={() => {
                  handleStatus(banner);
                }}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      ),
      Delete: (
        <MDBox>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => {
              handleBannerRemove(banner.banner_id);
            }}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="delete" color="secondary"  onClick={() => handleEditOpen(banner)}>
            <EditIcon />
          </IconButton>
        </MDBox>
      ),
      statusValue:( banner.status?1:0)
    }));

    setRows(rowsAPI);
  };
  const columns = [
    {
      Header: "Image",
      accessor: "Image",
      width: "10%",
      align: "center",
      disableSortBy: true,
    },
    { Header: "Title", accessor: "Title", align: "center" },
   
    {
      Header: " Add to Current Banners",
      accessor: "Action",
      align: "center",
    sortType:(rowA,rowB)=>{
      const item1=rowA.original.statusValue;
      const item2= rowB.original.statusValue;
      return item1-item2;
    }
    },
    {
      Header: "Action",
      accessor: "Delete",
      align: "center",
      disableSortBy: true,
    },
  ];

  const handleArtist = async () => {
    try {
      const response = await axiosInstance.get("/api/dev/artist/getAllArtistAdmin");
      
      if (response.status >= 200 && response.status < 300) {
        setArtist(response.data.data);
      } else {
        console.error("Error: Unexpected response status code", response.status);
      }
    } catch (error) {
      console.error("Error fetching artist data:", error.message);
    }
  };
  
  const handleSong = async () => {
    try {
      const responseSong = await axiosInstance.post("/api/dev/songs/getAllSongs");
      const filteredSongs = responseSong.data.data.filter(
        (song) => !song.is_ban
      );
      setSong(filteredSongs);
    } catch (error) {
      console.error("Error fetching songs:", error.message);
      setSong([]); 
    }
  };
  useEffect(() => {
    // dispatch(BannerData("/api/dev/banner/getBanners"))
    // handleApiCall();
    handleSetTable();
    // handleArtist();
    handleSong();
  }, [AllBanner]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImagePreview(null);
    setFormData({
      title: "",
      artist_id: "",
      song_id: "",
      image: "",
      song_title: "",
    });
  };

  const handleEditChange = (e) => {
    setEditForm({
      ...editForm,
      [e.target.id]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSongDropdown = (event, newValue) => {
    setFormData({
      ...formData,
      song_title: newValue ? newValue.song_title : "",
      song_id: newValue ? newValue.song_id : "",
    });
  };

  const EditSongDropdown = (event, newValue) => {
    setEditForm({
      ...editForm,
      song_title: newValue ? newValue.song_title : "",
      song_id: newValue ? newValue.song_id : "",
    });
  };
  const handleArtistDropdown = (event, newValue) => {
    setFormData({
      ...formData,
      artist_name: newValue ? newValue.name : "",
      artist_id: newValue ? newValue.artist_id : "",
    });
  };
  const EditArtistDropdown = (event, newValue) => {
    setEditForm({
      ...editForm,
      artist_name: newValue ? newValue.name : "",
      artist_id: newValue ? newValue.artist_id : "",
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoading(true);
    const newFormData = new FormData();
    newFormData.append("title", formData.title);
    newFormData.append("artist_id", formData.artist_id);
    newFormData.append("song_id", formData.song_id);
    newFormData.append("image", formData.image);

    console.log(formData);
    try {
      axiosInstance
        .post("/api/dev/banner/addBanners", newFormData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Banner Added!",
            timer: 1500,
            showConfirmButton: false,
          });
          dispatch(createApiAction("/api/dev/banner/getAllBanners", "GET"));
        })
        .catch((error) => {
          console.error("There was an error adding the banner!", error);
          Swal.fire({
            icon: "error",
            title: "Error Occurred!",
          });
        });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error Occurred!",
      });
    }
    setButtonLoading(false);
    handleClose();
  };
  const handleFileInputChange = (event) => {
    const { id, name } = event.target;
    const file = event.target.files[0];
    if(file){
      if (name === "editImage") {
        setEditForm({ ...editForm, [id]: file });
      }
      setFormData({ ...formData, [id]: file });
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
   
  };
  const handleAutoComplete = (fieldName, lableName, valueName) => {
    return fieldName.find((item) => item[lableName] === valueName) || null;
  };

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox>
          <MDTypography variant="h5" gutterBottom>
            {` All Banners (${rows?.length ? rows.length : "0"})`}
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}></MDBox>
        </MDBox>

        <MDButton
          onClick={() => {}}
          sx={{
            backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
          }}
          size="small"
        >
          <MDTypography
            style={{ fontSize: "13.5px", fontWeight: "bold" }}
            color="white"
            onClick={handleOpen}
          >
            Add Banner
          </MDTypography>
        </MDButton>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle style={{ position: "relative" }}>
            Add Banner
            <span
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <CancelIcon sx={{ color: purple[500] }} onClick={handleClose} />
            </span>
            {imagePreview && (
              <MDBox position="relative" width="100%" paddingTop="50%">
                <img
                  src={imagePreview}
                  alt="Banner Cover Image"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MDBox>
            )}
          </DialogTitle>
          <DialogContent style={{ overflowY: "auto" }}>
            {/* Form inputs */}
            <form onSubmit={handleSubmit}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="Banner Title"
                label="Title "
                type="text"
                fullWidth
                required
                value={formData.title}
                onChange={handleChange}
                inputProps={{ maxLength: 25 }}
                helperText={
                  formData.title.length === 25
                    ? "25 character limit reached"
                    : ""
                }
                FormHelperTextProps={{
                  sx: { color: "red" },
                }}
              />

              {/* <Autocomplete
                sx={{ marginTop: '7px', marginBottom: '7px' }}
                id="artist_name"
                options={artist}
                fullWidth
                required
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={handleArtistDropdown}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <MDBox
                      key={option.artist_id}
                      component="li"
                      {...optionProps}
                    >
                      {option.name}
                    </MDBox>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Artist"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}

                  />
                )}
              /> */}

              <Autocomplete
                sx={{ marginTop: "7px", marginBottom: "7px" }}
                id="song_id"
                options={song}
                fullWidth
                autoHighlight
                getOptionLabel={(option) => option.song_title}
                onChange={handleSongDropdown}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <MDBox key={option.song_id} component="li" {...optionProps}>
                      {option.song_title}
                    </MDBox>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Song"
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />

              <label
                htmlFor="image"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px solid #ccc",
                  padding: "2px",
                  marginTop: "7px",
                  marginBottom: "12px",
                  borderRadius: "5px",
                }}
              >
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  required={!formData.image}
                  onChange={handleFileInputChange}
                  style={{
                    position: "absolute",
                    width: "1px",
                    height: "1px",
                    opacity: 0,
                  }}
                />
                <Button component="span" color="primary" fullWidth>
                  Add Banner Image
                </Button>
                <span
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "1px",
                    color: "red",
                    fontSize: "11px",
                  }}
                >
                  {
                    "Choose a high quality image with the ratio 16:9 to best fit the app screen."
                  }
                </span>
              </label>
              <MDButton
                type="submit"
                sx={{
                  backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                  marginRight: "12px",
                }}
                fullWidth
              >
                {buttonLoading ? (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <CircularProgress sx={{ color: "#ffffff" }} size={24} />
                  </MDBox>
                ) : (
                  <MDTypography
                    style={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Add Banner
                  </MDTypography>
                )}
              </MDButton>
            </form>
          </DialogContent>
        </Dialog>

        {/* Edit Form */}
        <Dialog
          open={editOpen}
          onClose={handleEditClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Edit Banner
            <span
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <CancelIcon
                sx={{ color: purple[500] }}
                onClick={handleEditClose}
              />
            </span>
            {editBannerImage && (
              <MDBox position="relative" width="100%" paddingTop="50%">
                <img
                  src={imagePreview || editBannerImage}
                  alt="Banner Cover Image"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MDBox>
            )}
          </DialogTitle>

          <DialogContent style={{ overflowX: "hidden" }}>
            {/* Form inputs */}
            <form onSubmit={handleEditFormSubmit}>
            <label
                htmlFor="image"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px solid #ccc",
                  padding: "2px",
                  marginTop: "3px",
                  marginBottom: "4px",
                  borderRadius: "5px",
                }}
              >
                <input
                  type="file"
                  id="image"
                  name="editImage"
                  accept="image/*"
                  onChange={handleFileInputChange}
                  style={{
                    position: "absolute",
                    width: "1px",
                    height: "1px",
                    opacity: 0,
                  }}
                />
                <Button component="span" color="primary" fullWidth>
                  Edit Banner Image
                </Button>
                <span
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "1px",
                    color: "red",
                    fontSize: "11px",
                  }}
                >
                  {
                    "Choose a high quality image with the ratio 16:9 to best fit the app screen."
                  }
                </span>
              </label>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                fullWidth
                required
                value={editForm.title}
                onChange={handleEditChange}
                inputProps={{ maxLength: 25 }}
                helperText={
                  editForm.title.length === 25
                    ? "25 character limit reached"
                    : ""
                }
                FormHelperTextProps={{
                  sx: { color: "red" },
                }}
              />

              {/* <Autocomplete
                sx={{ marginTop: '7px', marginBottom: '7px' }}
                id="artist_name"
                name="editArtist"
                options={artist}
                fullWidth
                required
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={EditArtistDropdown}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <MDBox
                      key={option.artist_id}
                      component="li"
                      {...optionProps}
                    >
                      {option.name}
                    </MDBox>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Artist"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}

                  />
                )}
              /> */}

              <Autocomplete
                sx={{ marginTop: "7px", marginBottom: "11px" }}
                id="song_id"
                name="editSong"
                options={song}
                fullWidth
                value={handleAutoComplete(song, "song_id", editForm.song_id)}
                clearOnBlur
                disableClearable
                autoHighlight
                getOptionLabel={(option) => option.song_title}
                onChange={EditSongDropdown}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <MDBox key={option.song_id} component="li" {...optionProps}>
                      {option.song_title}
                    </MDBox>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Song"
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />

             
              <MDButton
                type="submit"
                sx={{
                  backgroundImage: `linear-gradient(90deg, #5870FD 0%, #F167C9 100%)`,
                  marginRight: "12px",
                }}
                fullWidth
              >
                {buttonLoading ? (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <CircularProgress sx={{ color: "#ffffff" }} size={24} />
                  </MDBox>
                ) : (
                  <MDTypography
                    style={{ fontSize: "13.5px", fontWeight: "bold" }}
                    color="white"
                  >
                    Submit
                  </MDTypography>
                )}
              </MDButton>
            </form>
          </DialogContent>
        </Dialog>
      </MDBox>
      <MDBox>
        {rows?.length > 0 ? (
          <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={true}
            noEndBorder
            entriesPerPage={false}
            isPagination={true}
          />
        ) : (
          <MDBox
            sx={{ display: "flex", padding: "20px", justifyContent: "center" }}
          >
            <MDTypography variant="caption" sx={{ fontSize: "20px" }}>
              No Banners are available. Please add some.
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

export default Projects;
